import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { CREATE_BOOKING_NOTIFICATION_COMMAND } from "../../gql/bookingapi/mutations";
import { withApollo } from "react-apollo";
import { useCallback, useContext, useState } from "react";
import Notify from "../notifications/Notify";
import { SelectItemContext } from "../../contexts";
import NotificationSendModal from "./NotificationSendModal";
import BookingNotificationStatus from "../booking-calendars/booking-notification-status/BookingNotificationStatus";
import { ON_UPDATE_BOOKING_NOTIFICATION_COMMNAD } from "../../gql/bookingapi/subscriptions";
import { LIST_BOOKING_NOTIFICATION_COMMANDS } from "../../gql/bookingapi/queries";
import { timeoutCallbackFactory } from "../booking-calendars/booking-notification-status/timeoutCallbackFactory";
import { DURATION } from "../../constants";
import { snackbarService } from "@one/web-components";

const NotificationErrorMessage = {
  type: "error",
  message: "There were problems with sending notifications.",
  duration: DURATION,
};

const getTextForNotify = (emails) => {
  if (emails?.length) {
    return `Notifications have been sent to ${emails?.length} recipient${
      emails?.length === 1 ? "" : "s"
    }!`;
  } else {
    return `No notifications have been sent!`;
  }
};

const Dialog = ({ client }) => {
  const { item: instrument, setItem } = useContext(SelectItemContext);
  const [status, setStatus] = useState(null);
  const { email, site } = useSelector((state) => state.user);
  const handleClose = () => {
    if (!status) setItem(null);
  };
  const notificationCallback = useCallback((data) => {
    let result = "";
    try {
      const {
        notification: { emails },
      } = JSON.parse(data?.data?.onUpdateBookingNotificationCommand?.status);
      result = {
        type: "success",
        message: getTextForNotify(emails),
        duration: DURATION,
      };
      snackbarService.show(result);
    } catch (err) {
      snackbarService.show(NotificationErrorMessage);
    }
    handleClose();
    setStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (searchObj) => {
    try {
      const { username: id } = await Auth.currentAuthenticatedUser();
      setStatus("loading");
      await client.mutate({
        mutation: CREATE_BOOKING_NOTIFICATION_COMMAND,
        variables: {
          site,
          createdBy: id,
          createdByEmail: email,
          dateFrom: searchObj?.dateFrom,
          dateTo: searchObj?.dateTo,
          inventoryId: instrument?.inventoryId,
          secondaryID: instrument?.secondaryID,
          secondaryIDMapping: instrument?.secondaryIDMapping,
          body: JSON.stringify({
            message: searchObj?.message,
            shortDescription: searchObj?.shortDescription,
            link: encodeURI(
              `${window?.location?.origin}/instrumentDetailedInfo/${instrument?.materialNumber}/${instrument?.serialNumber}`
            ),
          }),
        },
      });
    } catch (e) {
      Notify(NotificationErrorMessage);
      setStatus(null);
    }
  };

  return (
    <>
      <NotificationSendModal
        open={!!instrument}
        loading={status === "loading"}
        onSave={onSave}
        close={handleClose}
        item={instrument}
      />
      {(instrument || status === "loading") && (
        <BookingNotificationStatus
          onTimeoutCallback={timeoutCallbackFactory(
            NotificationErrorMessage.text
          )}
          tryTimes={1}
          timeout={30 * 1000}
          onBookingCallback={notificationCallback}
          query={LIST_BOOKING_NOTIFICATION_COMMANDS}
          subscription={ON_UPDATE_BOOKING_NOTIFICATION_COMMNAD}
        />
      )}
    </>
  );
};

export default withApollo(Dialog);
