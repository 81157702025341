import { useState, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "./DlabGrid.css";
import DLabGridPagination from "./DLabGridPagination";

const DLabGrid = ({
  height = 300,
  width = "100%",
  setRowPerPage,
  rowPerPage,
  totalRows,
  options,
  gridRef = null,
  pagination = true,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState("1");
  const [totalPages, setTotalPages] = useState("0");
  const gridStyle = useMemo(
    () => ({
      height,
      width,
      display: "flex",
      flexDirection: "column",
    }),
    [height, width]
  );
  const onCustomPaginationChange = ({ type, value }) => {
    switch (type) {
      case "PreviousPage":
        gridRef.current.api.paginationGoToPreviousPage();
        break;
      case "NextPage":
        gridRef.current.api.paginationGoToNextPage();
        break;
      case "LastPage":
        gridRef.current.api.paginationGoToLastPage();
        break;
      case "Limit":
        setRowPerPage(value);
        break;
      default:
        gridRef.current.api.paginationGoToFirstPage();
        break;
    }
  };

  const onGridPaginationChanged = useCallback(
    (params) => {
      // Workaround for bug in events order
      if (gridRef && gridRef?.current?.api) {
        // setPageSize(gridRef.current.api.paginationGetPageSize());
        // we +1 to current page, as pages are zero based
        setCurrentPage(gridRef?.current?.api.paginationGetCurrentPage() + 1);
        setTotalPages(gridRef?.current?.api.paginationGetTotalPages());
        // setLastPageFound(gridRef.current.api.paginationIsLastPageFound());
      }
    },
    [gridRef]
  );

  return (
    <div className="ag-theme-material ag-theme-DLab" style={gridStyle}>
      <AgGridReact
        suppressPaginationPanel
        onPaginationChanged={onGridPaginationChanged}
        ref={gridRef}
        {...props}
      />
      {pagination && (
        <DLabGridPagination
          onPaginationChange={onCustomPaginationChange}
          limit={rowPerPage}
          totalPages={totalPages}
          currentPage={currentPage}
          totalRows={totalRows}
          options={options}
        />
      )}
    </div>
  );
};
export default DLabGrid;
