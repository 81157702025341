import React, { useState } from "react";
import { DLabAppSwitcher } from "@digitallab/grid-common-components";
import { resolveSSOLink } from "../utils/ssoLinkStrategyResolver";
import { selectFavourites } from "../selectors";
import {
  getLPUserDetails,
  getLPAllApp,
  updateLPState,
} from "../features/booking-instruments/LoadBookingInstrumentsInfo";
import get from "lodash/get";
import { OwcIconButton } from "@one/react";

const onOpenAppFactory = () => {
  return async (launchUrl, application) => {
    if (launchUrl === "#" || !Array.isArray(application.entryPoints)) {
      return;
    }
    window.open(launchUrl);
  };
};

const AppSwitcher = ({ client }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [lpUserDetails, setLpUserDetails] = useState({});
  const onOpenApp = onOpenAppFactory({});

  const handleSwitch = (application) => {
    const entryPointSelected =
      application?.entryPointSelected || application?.entryPoints[0]?.id;
    const entryPoint = application?.entryPoints;
    const appEntryPointID =
      get(
        entryPoint.find((x) => x.id === entryPointSelected),
        "url"
      ) || entryPoint[0]?.url;
    onOpenApp(
      resolveSSOLink(application?.type, appEntryPointID || "#"),
      application
    );
  };

  const onAppSwitchIconClick = async () => {
    setLoading(true);
    setShowPopup(true);
    const { items: lpAllApps } = await getLPAllApp({ client });
    const lpUserDetail = await getLPUserDetails({ client });
    setLpUserDetails(lpUserDetail);
    const updatedFavList = selectFavourites(
      lpAllApps,
      lpUserDetail?.applicationSets
    ).sort((a, b) => {
      if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
        return 1;
      } else {
        if (b.name?.toLowerCase() > a.name?.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    const updatedList = updatedFavList?.map((fav) => {
      return {
        ...fav,
        iconPath: fav?.iconPath
          ? `${process.env.REACT_APP_LP_GRAPHQL_ENDPOINT_CLOUDFRONT}${fav?.iconPath}`
          : null,
      };
    });
    setList([...updatedList]);
    setLoading(false);
  };

  const onALLAppsHandler = async () => {
    setShowPopup(false);
    await updateLPState({
      client,
      user: lpUserDetails,
    });
    window.open(`${process.env.REACT_APP_LP_GRAPHQL_ENDPOINT_CLOUDFRONT}`);
  };

  return (
    <>
      <OwcIconButton
        flat
        type="legacy"
        icon="app_launcher"
        id="popup-anchor"
        onClick={onAppSwitchIconClick}
      />
      <DLabAppSwitcher
        applicationList={list}
        anchor="popup-anchor"
        showPopup={showPopup}
        loading={loading}
        handleAppSwitch={(application) => {
          setShowPopup(false);
          handleSwitch(application);
        }}
        handleAllAppsClick={onALLAppsHandler}
        handleClickOutSide={() => {
          setShowPopup(false);
        }}
      />
    </>
  );
};

export default AppSwitcher;
