import SkeletonForTestAndDocs from "../SkeletonForTestAndDocs";
import LinkedDocumentsTable from "./LinkedDocumentsTable";
import DATA_LABEL_TABLE from "../../../utils/constants/dataLabelTable";

const LinkedDocumentsMainPage = ({ instrument }) => {
  return (
    <SkeletonForTestAndDocs
      shortIdentifier={instrument?.shortIdentifier}
      titleForTable={
        DATA_LABEL_TABLE.EQUIPMENT_LIST_PAGE.linked_documents_label
      }
      nameForDataTestid={"linked-documents"}
    >
      <LinkedDocumentsTable
        linkedDocuments={instrument?.qualificationDocuments?.value || []}
      />
    </SkeletonForTestAndDocs>
  );
};

export default LinkedDocumentsMainPage;
