import gql from "graphql-tag";

export const LIST_DIGITAL_LAB_BOOKING_INSTRUMENTS = gql`
  query listDigitalLabBookingInstruments(
    $inventoryId: String
    $filter: ModelDigitalLabBookingInstrumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDigitalLabBookingInstruments(
      inventoryId: $inventoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        inventoryId
        entryType
        modelName
        secondaryID
        secondaryIDMapping
        controlledEquipmentStatus
        linkedInstance
        siteName
        serialNumber
        materialNumber
        inventoryNumber
        equipmentId
        instrumentRUDI
        instrumentGTIN
        isBookable
        equipmentCategory
        manufacturer
        equipmentModel
        entryType
        variant
        cluster
        clusterId
        equipmentNickName
        comment
        belongingToGroup
        buildingLocation {
          key
          value
        }
        floor {
          key
          value
        }
        room {
          key
          value
        }
        responsiblePerson
        equipmentAdministrator
        systemOwner
        status
        responsibleProxy
        functionalLocation
        costCenter
        sop {
          key
          value
        }
        csv
        electronicRecord
        electronicSignatures
        dateOfNextPeriodicReview
        maintenancePlan
        gxpRelevant
        qualificationStatus
        dateOfLastMaintanance
        dateOfNextMaintanance
        displayQualificationStatus
        qualificationDocuments {
          isSynchronized
          value {
            name
            documentId
          }
        }
        softwareVersion
        configurationBaseline
        installedTests {
          name
          version
        }
        isBookable
        isVisualized
        systemStatus
        siteId
        siteTimezone
        location
        displayImage
        createdBy
        updatedBy
        status
        datamodelVersion
        shortIdentifier
        addInfoId
        createdAt
        updatedAt
        addInfo {
          id
          inventoryId
          instrumentStatus
          instrumentAvailability
          bookedBy
          bookedByEmail
          isBookingControlled
          bookableForUsers
        }
      }
      nextToken
    }
  }
`;

export const LIST_BOOKING_COMMAND = gql`
  query listBookingCommands {
    listBookingCommands(limit: 1) {
      items {
        id
        entries {
          dateFrom
          dateTo
          inventoryId
          processingResult
        }
      }
    }
  }
`;

export const LIST_BACKEND_HEALTH_CHECKS = gql`
  query listBackendHealthChecks {
    listBackendHealthChecks {
      items {
        id
        moduleName
        moduleVersion
        ciJobId
        commitHash
        enableAnalyticsTracking
        createdAt
        updatedAt
      }
    }
  }
`;

export const LIST_DIGITAL_LAB_BOOKING_USERS = gql`
  query listDigitalLabBookingUsers($limit: Int, $nextToken: String) {
    listDigitalLabBookingUsers(limit: $limit, nextToken: $nextToken) {
      items {
        userId
        email
        familyName
        givenName
        name
      }
      nextToken
    }
  }
`;

export const BOOKING_BY_SITE_AND_DATE = gql`
  query BookingBySiteAndDate(
    $site: String
    $dateRange: [String]
    $limit: Int
    $nextToken: String
    $filter: ModelBookingFilterInput
    $sortDirection: ModelSortDirection
  ) {
    bookingBySiteAndDate(
      site: $site
      date: { between: $dateRange }
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        createdBy
        createdByEmail
        date
        dateFrom
        dateTo
        description
        remark
        project
        type
        active
        bookingEntries {
          items {
            active
            allDay
            bookingId
            color
            createdAt
            createdBy
            createdByEmail
            date
            description
            end
            equipment {
              belongingToGroup
              addInfoId
              cluster
              clusterId
              comment
              equipmentId
              entryType
              equipmentModel
              equipmentNickName
              equipmentCategory
              equipmentAdministrator
              inventoryId
              isVisualized
              location
              responsibleProxy
              responsiblePerson
              siteName
              serialNumber
              shortIdentifier
              softwareVersion
            }
            reservedForEmails
            reservedForUsers
            recurringExceptionRule
            recurringException
            site
            start
            timezone
          }
        }
      }
      nextToken
    }
  }
`;
export const GET_BOOKING_DATA_BY_SITE = gql`
  query getBookingDataBySite(
    $site: String
    $limit: Int
    $nextToken: String
    $filter: String
    $dateFrom: String
  ) {
    getBookingDataBySite(
      site: $site
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      dateFrom: $dateFrom
    ) {
      items {
        bookedEquipments
        bookedUsers {
          email
          familyName
          givenName
          name
          user
        }
        bookingEntries {
          active
          allDay
          bookedSlots
          bookingId
          color
          start
          end
          fcolor
          id
          type
          equipment {
            softwareVersion
            shortIdentifier
            serialNumber
            location
            equipmentNickName
            equipmentModel
            equipmentId
            belongingToGroup
            equipmentModel
            softwareVersion
            siteName
            manufacturer
            variant
            materialNumber
            responsiblePerson
            systemStatus
            configurationBaseline
            equipmentCategory
            entryType
            qualificationStatus
          }
          inventoryId
          reservedForUsers
        }
        id
        project
        description
        type
        date
        dateFrom
        dateTo
        active
        createdBy
        reservedForEmails
        remark
      }
      nextToken
    }
  }
`;
export const FIND_BOOKABLE_INSTRUMENTS = gql`
  query findBookableInstruments(
    $startDate: String!
    $endDate: String!
    $inventoryIds: [String]!
    $siteTimezone: String!
  ) {
    findBookableInstruments(
      startDate: $startDate
      endDate: $endDate
      inventoryIds: $inventoryIds
      siteTimezone: $siteTimezone
    ) {
      items {
        availability
        date
      }
    }
  }
`;

export const LIST_BOOKING_NOTIFICATION_COMMANDS = gql`
  query listBookingNotificationCommands {
    listBookingNotificationCommands(limit: 1) {
      items {
        id
        materialNumber
        serialNumber
        secondaryID
        secondaryIDMapping
        inventoryId
        site
        body
        dateFrom
        dateTo
        createdBy
        createdByEmail
        status
      }
    }
  }
`;

export const LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE_DROPDOWN = gql`
  query configurationByModelAndText {
    configurationByModelAndText(
      modelName: "BOOKINGCANCELREASON"
      sortDirection: ASC
    ) {
      nextToken
      items {
        id
        configText
      }
    }
  }
`;

export const LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE = gql`
  query configurationByModelAndCreatedDate(
    $limit: Int
    $nextToken: String
    $filter: ModelBookingConfigurableMasterFilterInput
  ) {
    configurationByModelAndCreatedDate(
      sortDirection: DESC
      modelName: "BOOKINGCANCELREASON"
      limit: $limit
      filter: $filter
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        configText
      }
    }
  }
`;

export const LIST_FILTERBOOKING_DATA_BY_SITE_NAME_AND_FILTER_ATTRIBUTES = gql`
  query listFilterBookingDataBySiteNameAndFilterAttributes(
    $limit: Int
    $nextToken: String
    $siteName: String
    $filterAttribute: ModelStringKeyConditionInput
  ) {
    listFilterBookingDataBySiteNameAndFilterAttributes(
      limit: $limit
      nextToken: $nextToken
      siteName: $siteName
      filterAttribute: $filterAttribute
    ) {
      items {
        filterAttribute
        filterValue
      }
      nextToken
    }
  }
`;

export const LIST_BOOKING_ACCESS_CONFIGURATION = gql`
  query listBookingAccessConfigurations($limit: Int, $nextToken: String) {
    listBookingAccessConfigurations(limit: $limit, nextToken: $nextToken) {
      items {
        modelName
        groupName
        id
      }
      nextToken
    }
  }
`;
export const LIST_BOOKING_GROUP_CONFIGURATION_BY_CREATE_DATE = gql`
  query listBookingGroupConfigurationByCreatedDate(
    $limit: Int
    $nextToken: String
    $filter: ModelBookingGroupConfigurationFilterInput
  ) {
    listBookingGroupConfigurationByCreatedDate(
      limit: $limit
      nextToken: $nextToken
      sortDirection: DESC
      filter: $filter
      modelName: "BOOKINGGROUPCONFIG"
    ) {
      items {
        modelName
        groupName
        users {
          email
          familyName
          givenName
          name
        }
        id
      }
      nextToken
    }
  }
`;
export const LIST_BOOKING_ACCESS_CONFIGURATION_BY_EQUIPMENT = gql`
  query listBookingAccessConfigurationByEquipment(
    $limit: Int
    $nextToken: String
    $modelName: String!
    $filter: ModelBookingAccessConfigurationFilterInput
    $inventoryId: ModelStringKeyConditionInput
  ) {
    listBookingAccessConfigurationByEquipment(
      limit: $limit
      nextToken: $nextToken
      modelName: $modelName
      filter: $filter
      inventoryId: $inventoryId
    ) {
      items {
        groupName
        id
        inventoryId
        groups {
          items {
            users {
              email
              name
              familyName
              givenName
            }
          }
        }
        equipment {
          inventoryId
          modelName
          secondaryID
          secondaryIDMapping
          linkedInstance
          siteName
          serialNumber
          materialNumber
          inventoryNumber
          equipmentId
          instrumentRUDI
          instrumentGTIN
          equipmentCategory
          manufacturer
          equipmentModel
          variant
          cluster
          equipmentNickName
          comment
          belongingToGroup
          responsiblePerson
          equipmentAdministrator
          systemOwner
          responsibleProxy
          functionalLocation
          costCenter
          csv
          electronicRecord
          electronicSignatures
          dateOfNextPeriodicReview
          maintenancePlan
          gxpRelevant
          displayQualificationStatus
          qualificationStatus
          dateOfLastMaintanance
          dateOfNextMaintanance
          softwareVersion
          configurationBaseline
          isBookable
          isVisualized
          systemStatus
          siteId
          siteTimezone
          location
          displayImage
          createdBy
          updatedBy
          status
          datamodelVersion
          shortIdentifier
          addInfoId
          addInfo {
            id
            inventoryId
            instrumentStatus
            instrumentAvailability
            bookedBy
            bookedByEmail
            isBookingControlled
            bookableForUsers
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const LIST_BOOKING_GROUP_CONFIGURATION = gql`
  query listBookingGroupConfigurations($limit: Int, $nextToken: String) {
    listBookingGroupConfigurations(
      limit: $limit
      nextToken: $nextToken
      sortDirection: ASC
      modelName: "BOOKINGGROUPCONFIG"
    ) {
      items {
        modelName
        groupName
        users {
          email
          familyName
          givenName
          name
        }
        id
      }
      nextToken
    }
  }
`;

export const LIST_BOOKING_GROUP_CONFIGURATION_UNICITY_CHECK = gql`
  query listBookingGroupConfigurationByCreatedDate(
    $filter: String
    $nextToken: String
  ) {
    listBookingGroupConfigurationByCreatedDate(
      modelName: "BOOKINGGROUPCONFIG"
      filter: { groupName: { eq: $filter } }
      nextToken: $nextToken
    ) {
      items {
        groupName
        id
      }
      nextToken
    }
  }
`;

//listDigitalLabLandingPageApplications
export const LP_LIST_DIGITAL_LAB_LANDING_PAGE_APPLICATIONS = gql`
  query listDigitalLabLandingPageApplications {
    listDigitalLabLandingPageApplications {
      items {
        icon
        iconPath
        id
        name
        entryPoints {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        email
        lastFilter
        familyName
        givenName
        name
        phone
        status
        site
        user
        searchPreference
      }
    }
  }
`;

export const LP_GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        name
        email
        familyName
        givenName
        user
        lastFilter
        phone
        site
        applicationSets {
          id
          name
          icon
          applicationReferences {
            applicationID
            applicationEntryPointID
          }
        }
        recentApplicationUsage {
          id
          lastUsed
          applicationRef {
            applicationID
            applicationEntryPointID
          }
        }
      }
    }
  }
`;

export const REPO_GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  query GetDigitalLabInstrumentRepositoryEntry($id: ID!) {
    getDigitalLabInstrumentRepositoryEntry(id: $id) {
      subEquipment {
        items {
          id
          cluster
          clusterId
          equipmentModel
          entryType
          equipmentNickName
          positionInCluster
          qualificationStatus
          softwareVersion
          variant
        }
      }
    }
  }
`;

export const LIST_USER_DISPLAY_COLUMNS = gql`
  query listUserDisplays($userID: ID!) {
    listUserDisplays(userID: $userID) {
      items {
        displayDefinition
        displayName
        id
        userID
      }
    }
  }
`;

export const LIST_SYSTEM_DISPLAY_COLUMNS = gql`
  query listSystemDisplays {
    listSystemDisplays {
      items {
        displayDefinition
        displayName
        id
      }
    }
  }
`;

export const LIST_BOOKING_ACCESS_CONFIG_BY_EQUIP = gql`
  query listBookingAccessConfigurationByEquipment(
    $inventoryId: String!
    $limit: Int
    $nextToken: String
  ) {
    listBookingAccessConfigurationByEquipment(
      inventoryId: { eq: $inventoryId }
      limit: $limit
      modelName: "BOOKINGACCESSCONFIG"
      nextToken: $nextToken
    ) {
      items {
        id
        inventoryId
        groupName
      }
      nextToken
    }
  }
`;

///////////// Repo Graphql /////////////////////////////

export const LIST_IR_CATEGORY_MAPPINGS_MASTER = gql`
  query listIRCategoryMappings($limit: Int, $nextToken: String) {
    listIRCategoryMappings(
      limit: $limit
      nextToken: $nextToken
      filter: { isActive: { eq: true } }
    ) {
      nextToken
      items {
        displayImage
        equipmentCategory
        equipmentModel
        variant
        isActive
        manufacturer
        modelName
      }
    }
  }
`;
export const LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MASTER = gql`
  query listIRClusterCategoryMappings($limit: Int, $nextToken: String) {
    listIRClusterCategoryMappings(
      limit: $limit
      nextToken: $nextToken
      filter: { isActive: { eq: true } }
    ) {
      nextToken
      items {
        equipmentCategory: clusterCategory
        equipmentModel: clusterModel
        variant
        isActive
        variant
        createdAt
        modifiedById
        modelName
      }
    }
  }
`;
export const LIST_IR_MODEL_MAPPINGS_MASTER = gql`
  query listIRModelMappings($limit: Int, $nextToken: String) {
    listIRModelMappings(
      limit: $limit
      nextToken: $nextToken
      filter: { isActive: { eq: true } }
    ) {
      nextToken
      items {
        equipmentModel
        modelName
        variant
      }
    }
  }
`;
export const LIST_IR_LINKED_INSTANCE_MASTER = gql`
  query listSupportedLinkedInstances($limit: Int, $nextToken: String) {
    listSupportedLinkedInstances(
      limit: $limit
      nextToken: $nextToken
      filter: { isActive: { eq: true } }
    ) {
      nextToken
      items {
        linkedInstance
        linkedInstanceDisplay
        secondaryIdMapping
      }
    }
  }
`;
export const LIST_IR_GROUP_MASTER = gql`
  query listIRGroupMasters($limit: Int, $nextToken: String) {
    listIRGroupMasters(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        value
      }
    }
  }
`;
export const LIST_IR_FILTER_LOOKUPS_MASTER = gql`
  query listIRFilterLookups($limit: Int, $nextToken: String, $field: String) {
    listIRFilterLookups(
      limit: $limit
      nextToken: $nextToken
      filterAttribute: $field
      sortDirection: ASC
    ) {
      nextToken
      items {
        filterValue
      }
    }
  }
`;
export const LIST_IR_SOP_MASTER = gql`
  query listIRSOPMasters($limit: Int, $nextToken: String) {
    listIRSOPMasters(
      limit: $limit
      nextToken: $nextToken
      filter: { isActive: { eq: true } }
    ) {
      nextToken
      items {
        isActive
        key
        value
      }
    }
  }
`;
export const LIST_SITES = gql`
  query listSites($limit: Int, $nextToken: String) {
    listSites(limit: $limit, nextToken: $nextToken) {
      items {
        siteName
        siteId
        timeZone
      }
      nextToken
    }
  }
`;

///////////// Repo Graphql /////////////////////////////

///////////// LP Graphql /////////////////////////////
export const LP_GET_LOGGED_USER_DETAILS_USER_FILTERS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        name
        email
        userFilters {
          items {
            filterName
            filterDefinition
          }
        }
      }
    }
  }
`;
///////////// LP Graphql /////////////////////////////
export const BOOKING_GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        email
        lastFilter
        familyName
        givenName
        name
        phone
        status
        site
        user
        lastPageSize
        lastDisplayColumns
        lastFilter
        userFilters {
          items {
            filterName
            filterDefinition
          }
        }
      }
    }
  }
`;

export const GET_DIGITAL_LAB_BOOKING_INSTRUMENTS = gql`
  query getDigitalLabBookingInstrument($inventoryId: String!) {
    getDigitalLabBookingInstrument(inventoryId: $inventoryId) {
      inventoryId
      entryType
      modelName
      secondaryID
      secondaryIDMapping
      controlledEquipmentStatus
      linkedInstance
      siteName
      serialNumber
      materialNumber
      inventoryNumber
      equipmentId
      instrumentRUDI
      instrumentGTIN
      isBookable
      equipmentCategory
      manufacturer
      equipmentModel
      entryType
      variant
      cluster
      clusterId
      equipmentNickName
      comment
      belongingToGroup
      buildingLocation {
        key
        value
      }
      floor {
        key
        value
      }
      room {
        key
        value
      }
      responsiblePerson
      equipmentAdministrator
      systemOwner
      status
      responsibleProxy
      functionalLocation
      costCenter
      sop {
        key
        value
      }
      csv
      electronicRecord
      electronicSignatures
      dateOfNextPeriodicReview
      maintenancePlan
      gxpRelevant
      qualificationStatus
      dateOfLastMaintanance
      dateOfNextMaintanance
      displayQualificationStatus
      qualificationDocuments {
        isSynchronized
        value {
          name
          documentId
        }
      }
      softwareVersion
      configurationBaseline
      installedTests {
        name
        version
      }
      isBookable
      isVisualized
      systemStatus
      siteId
      siteTimezone
      location
      displayImage
      createdBy
      updatedBy
      status
      datamodelVersion
      shortIdentifier
      addInfoId
      createdAt
      updatedAt
      addInfo {
        id
        inventoryId
        instrumentStatus
        instrumentAvailability
        bookedBy
        bookedByEmail
        isBookingControlled
        bookableForUsers
      }
    }
  }
`;
export const GET_BOOKING_ENTRIES_BY_SITE_AND_INVENTORY_ID = gql`
  query bookingEntriesBySiteAndInventoryId(
    $site: String
    $inventoryId: ModelStringKeyConditionInput
    $filter: ModelBookingEntriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingEntriesBySiteAndInventoryId(
      inventoryId: $inventoryId
      site: $site
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        active
        allDay
        bookingId
        color
        createdAt
        createdBy
        createdByEmail
        date
        description
        end
        equipment {
          addInfo {
            bookableForUsers
            bookedBy
            bookedByEmail
            id
            instrumentAvailability
            instrumentStatus
            inventoryId
            isBookingControlled
          }
          addInfoId
          belongingToGroup
          cluster
          configurationBaseline
          costCenter
          controlledEquipmentStatus
          csv
          createdAt
          createdBy
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          displayQualificationStatus
          electronicSignatures
          electronicRecord
          entryType
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          instrumentRUDI
          instrumentGTIN
          inventoryNumber
          inventoryId
          isBookable
          isVisualized
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          siteName
          siteId
          shortIdentifier
          secondaryIDMapping
          secondaryID
          serialNumber
          location
          responsiblePerson
          softwareVersion
          systemStatus
          variant
        }
        expiresAt
        fcolor
        id
        project
        recurring
        recurringException
        recurringExceptionRule
        reservedForEmails
        resource
        site
        start
        timezone
        type
      }
      nextToken
    }
  }
`;
export const GET_BOOKING_ENTRIES_BY_SITE_AND_DATE = gql`
  query bookingEntriesBySiteAndDate(
    $site: String
    $limit: Int
    $nextToken: String
    $filter: ModelBookingEntriesFilterInput
    $sortDirection: ModelSortDirection
    $date: ModelStringKeyConditionInput
  ) {
    bookingEntriesBySiteAndDate(
      site: $site
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      date: $date
    ) {
      items {
        active
        allDay
        bookingId
        color
        createdAt
        createdBy
        createdByEmail
        date
        description
        end
        equipment {
          addInfo {
            bookableForUsers
            bookedBy
            bookedByEmail
            id
            instrumentAvailability
            instrumentStatus
            inventoryId
            isBookingControlled
          }
          addInfoId
          belongingToGroup
          cluster
          configurationBaseline
          costCenter
          controlledEquipmentStatus
          csv
          createdAt
          createdBy
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          displayQualificationStatus
          electronicSignatures
          electronicRecord
          entryType
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          instrumentRUDI
          instrumentGTIN
          inventoryNumber
          inventoryId
          isBookable
          isVisualized
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          siteName
          siteId
          shortIdentifier
          secondaryIDMapping
          secondaryID
          serialNumber
          location
          responsiblePerson
          softwareVersion
          systemStatus
          variant
        }
        expiresAt
        fcolor
        id
        project
        recurring
        recurringException
        recurringExceptionRule
        reservedForEmails
        resource
        site
        start
        timezone
        type
      }
      nextToken
    }
  }
`;
export const GET_BOOKING_ENTRIES_BY_BOOKING_ID = gql`
  query bookingEntriesByBookingId(
    $bookingId: ID
    $nextToken: String
    $filter: ModelBookingEntriesFilterInput
  ) {
    bookingEntriesByBookingId(
      bookingId: $bookingId
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        active
        allDay
        bookingId
        color
        createdAt
        createdBy
        createdByEmail
        date
        description
        end
        equipment {
          addInfo {
            bookableForUsers
            bookedBy
            bookedByEmail
            id
            instrumentAvailability
            instrumentStatus
            inventoryId
            isBookingControlled
          }
          addInfoId
          belongingToGroup
          cluster
          configurationBaseline
          costCenter
          controlledEquipmentStatus
          csv
          createdAt
          createdBy
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          displayQualificationStatus
          electronicSignatures
          electronicRecord
          entryType
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          instrumentRUDI
          instrumentGTIN
          inventoryNumber
          inventoryId
          isBookable
          isVisualized
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          siteName
          siteId
          shortIdentifier
          secondaryIDMapping
          secondaryID
          serialNumber
          location
          responsiblePerson
          softwareVersion
          systemStatus
          variant
        }
        expiresAt
        fcolor
        id
        project
        recurring
        recurringException
        recurringExceptionRule
        reservedForEmails
        resource
        site
        start
        timezone
        type
      }
      nextToken
    }
  }
`;
export const GET_SITE_TIMEZONE = gql`
  query getSite($siteName: String!) {
    getSite(siteName: $siteName) {
      siteName
      timezone
    }
  }
`;
