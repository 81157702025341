import ReasonDialog from "./ReasonDialog";
import DeleteReasonDialog from "./DeleteReasonDialog";
import React, { useContext, useState } from "react";
import { ReasonContext } from "./context";
import { PaginationContextReason } from "./PaginationContextReason";
import { reasonValidationSchema } from "./validationSchema";
import {
  UPDATE_BOOKING_REASON_MASTER,
  CREATE_BOOKING_REASON_MASTER,
  DELETE_BOOKING_REASON_MASTER,
} from "../../../gql/bookingapi/mutations";
import { LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE } from "../../../gql/bookingapi/queries";
import { loadAddReasonList as loadAddReasonListAction } from "../redux/actions";
import { getBookingAddReason } from "../../booking-instruments/LoadBookingInstrumentsInfo";
import { getAllData } from "../../../utils/helpers/fetching";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Formik } from "formik";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { snackbarService } from "@one/web-components";
import { DURATION } from "../../../constants";

export const compareBooking = (x, y) => x?.id === y?.id;

export const ReasonMainView = ({
  client,
  reasonList,
  setReasonList,
  loadAddReasonList,
}) => {
  const {
    dialogOpen,
    isEditMode,
    setDialogOpen,
    isDeleteMode,
    setIsDeleteMode,
    currentReason,
    setIsEditMode,
  } = useContext(ReasonContext);
  const { dispatchAction } = useContext(PaginationContextReason);
  const [disableReason, setDisableReason] = useState(true);

  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Admin Panel/Reson management",
      href: `${window.location?.origin}/AdminPanel/ResonManagement`,
    });
    // eslint-disable-next-line
  }, []);

  const checkUnicityForReason = async (configText) => {
    let variables = {
      limit: 1000,
      filter: { configText: { eq: configText } },
    };
    const { items } = await getAllData({
      client,
      query: LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "configurationByModelAndCreatedDate"],
      drillData: true,
    });
    return !(items?.length > 0);
  };

  const submit = async (configText) => {
    setDisableReason(true);
    let checkUnicityStatus = await checkUnicityForReason(configText);
    if (checkUnicityStatus) {
      let row = {
        configText,
      };
      if (isEditMode) {
        row.id = currentReason.id;
      }
      try {
        await client.mutate({
          mutation: isEditMode
            ? UPDATE_BOOKING_REASON_MASTER
            : CREATE_BOOKING_REASON_MASTER,
          variables: row,
          fetchPolicy: "no-cache",
        });

        setDialogOpen(false);
        dispatchAction({
          type: "clearAll",
        });
        snackbarService.show({
          message: isEditMode
            ? `Reason updated successfully! `
            : `Reason created successfully! `,
          type: "success",
          duration: DURATION,
        });
      } catch (errors) {
        setDialogOpen(true);
        snackbarService.show({
          message: isEditMode
            ? `Failed to update reason!`
            : `Failed to create reason! `,
          type: "error",
          duration: DURATION,
        });
      } finally {
        setDisableReason(false);
      }
    } else {
      setDisableReason(false);
      setDialogOpen(true);
      snackbarService.show({
        message: `Reason already exist`,
        type: "error",
        duration: DURATION,
      });
    }
  };

  const deleteReason = async () => {
    setDisableReason(true);
    let row = {
      id: currentReason.id,
    };
    try {
      await client.mutate({
        mutation: DELETE_BOOKING_REASON_MASTER,
        variables: row,
        fetchPolicy: "no-cache",
      });
      dispatchAction({
        type: "clearAll",
      });
      setIsDeleteMode(false);
      setDialogOpen(false);
      updateReasonsList();
      snackbarService.show({
        message: `Reason deleted successfully!`,
        type: "success",
        duration: DURATION,
      });
    } catch (error) {
      setIsDeleteMode(false);
      setDialogOpen(false);
      snackbarService.show({
        message: `Failed to delete reason!`,
        type: "error",
        duration: DURATION,
      });
    } finally {
      setDisableReason(false);
    }
  };

  const closeDialog = () => {
    setIsEditMode(false);
    setIsDeleteMode(false);
    setDialogOpen(false);
  };

  const updateReasonsList = async () => {
    const { bookingAddReason } = await getBookingAddReason({
      client,
    });
    loadAddReasonList(bookingAddReason);
  };

  return (
    <>
      <Formik
        validationSchema={reasonValidationSchema}
        initialValues={{ configText: "" }}
        isInitialValid={false}
        validateOnMount={false}
      >
        <>
          {!isDeleteMode && (
            <ReasonDialog
              open={dialogOpen}
              onCancel={closeDialog}
              isEditMode={isEditMode}
              submit={submit}
              setDisableReason={setDisableReason}
              disableReason={disableReason}
            />
          )}
          {isDeleteMode && (
            <DeleteReasonDialog
              open={dialogOpen}
              onCancel={closeDialog}
              deleteReason={deleteReason}
              disableReason={disableReason}
              setDisableReason={setDisableReason}
            />
          )}
        </>
      </Formik>
    </>
  );
};
export default compose(
  connect(null, {
    loadAddReasonList: loadAddReasonListAction,
  }),
  withApollo
)(ReasonMainView);
