import React, { useState, useEffect, useContext } from "react";
import GroupDialog from "./GroupDialog";
import { getAllData } from "../../../../utils/helpers/fetching";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import {
  LIST_BOOKING_GROUP_CONFIGURATION_BY_CREATE_DATE,
  LIST_DIGITAL_LAB_BOOKING_USERS,
  LIST_BOOKING_GROUP_CONFIGURATION_UNICITY_CHECK,
} from "../../../../gql/bookingapi/queries";
import {
  CREATE_BOOKING_GROUP_CONFIGURATION,
  UPDATE_BOOKING_GROUP_CONFIGURATION,
} from "../../../../gql/bookingapi/mutations";
import { PaginationContext } from "../../../../components/shared/pagination/PaginationContext";
import { OwcPagination, OwcProgressSpinner } from "@one/react";
import { GroupContext } from "./context";
import { GroupTableMeta } from "./GroupMeta";
import TopBar from "./TopBar";
import { Formik } from "formik";
import { groupValidationSchema } from "./validationSchema";
// import PaginationActions from "../../../../components/shared/pagination/PaginationActions";
import { sortBy } from "lodash";
import CustomList from "../../../../components/shared/List";
import { GroupPaginationStyled } from "../assign-group/GroupMainPageStyle";
import { DURATION } from "../../../../constants";
import { snackbarService } from "@one/web-components";

const GroupContent = ({ client }) => {
  const {
    dispatchAction,
    fetching,
    limit,
    orderBy,
    sortDirection,
    nextData,
    refreshData,
  } = useContext(PaginationContext);
  const {
    dialogOpen,
    setDialogOpen,
    isEditMode,
    setIsEditMode,
    userList,
    setUserList,
  } = useContext(GroupContext);
  const [groupList, setGroupList] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [showPerPage, setShowPerPage] = useState(5);
  const checkUnicityForReason = async (groupName) => {
    let variables = { filter: groupName };
    const { items } = await getAllData({
      client,
      query: LIST_BOOKING_GROUP_CONFIGURATION_UNICITY_CHECK,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listBookingGroupConfigurationByCreatedDate"],
      drillData: true,
    });
    return items.length > 0 ? false : true;
  };
  const getGroupList = async () => {
    let variables = {};
    if (searchGroup !== "") {
      variables = {
        ...variables,
        filter: { groupName: { contains: searchGroup } },
      };
    }
    const { items } = await getAllData({
      client,
      query: LIST_BOOKING_GROUP_CONFIGURATION_BY_CREATE_DATE,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listBookingGroupConfigurationByCreatedDate"],
      drillData: false,
    });
    setTotalCount(items.length);
    const lowIndex = pageNo === 1 ? 0 : (pageNo - 1) * showPerPage;
    const highIndex = pageNo === 1 ? showPerPage : lowIndex + showPerPage;
    const paginationData = items.slice(lowIndex, highIndex);
    setGroupList(paginationData);
  };

  const getUserList = async () => {
    const { items } = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_BOOKING_USERS,
      fetchPolicy: "network-only",
      dataPath: ["data", "listDigitalLabBookingUsers"],
      drillData: true,
      variables: {
        limit: 1000,
      },
    });
    let list = items.map((item) => ({
      ...item,
      key: item.email,
      value:
        item?.givenName && item?.familyName && item?.name
          ? `${item?.givenName} ${item?.familyName} - ${item?.name}`
          : item?.givenName && item?.familyName
          ? `${item?.givenName} ${item?.familyName}`
          : item.email,
    }));
    list = sortBy(list, "value");
    setUserList(list);
  };

  const submit = async (data) => {
    setLoading(true);
    let checkUnicityStatus = await checkUnicityForReason(
      data?.groupName?.trim()
    );
    if (checkUnicityStatus || isEditMode) {
      const list = data?.users.map(
        ({ email, familyName, givenName, name, userId }) => ({
          email,
          familyName,
          givenName,
          name,
          userId,
        })
      );
      const row = {
        groupName: data.groupName.trim(),
        users: list,
      };
      try {
        await client.mutate({
          mutation: isEditMode
            ? UPDATE_BOOKING_GROUP_CONFIGURATION
            : CREATE_BOOKING_GROUP_CONFIGURATION,
          variables: row,
          fetchPolicy: "no-cache",
        });
        dispatchAction({
          type: "clearAll",
        });
        setDialogOpen(false);
        snackbarService.show({
          message: isEditMode
            ? `Group updated successfully! `
            : `Group created successfully! `,
          type: "success",
          duration: DURATION,
        });
      } catch (error) {
        setDialogOpen(false);
        snackbarService.show({
          message: isEditMode
            ? `Group updated failed!`
            : `Group created failed! `,
          type: "error",
          duration: DURATION,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setDialogOpen(true);
      setLoading(false);
      snackbarService.show({
        message: `Group already exist`,
        type: "error",
        duration: DURATION,
      });
    }
  };

  useEffect(() => {
    getGroupList();
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGroupList();
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchGroup,
    orderBy,
    sortDirection,
    limit,
    nextData,
    refreshData,
    pageNo,
    showPerPage,
  ]);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Formik
        validationSchema={groupValidationSchema}
        initialValues={{ groupName: "", users: null }}
        isInitialValid={false}
        validateOnMount={false}
      >
        <>
          <GroupDialog
            open={dialogOpen}
            onCancel={closeDialog}
            isEditMode={isEditMode}
            submit={submit}
            userList={userList}
            loading={loading}
          />

          <TopBar
            setDialogOpen={setDialogOpen}
            setIsEditMode={setIsEditMode}
            setSearchGroup={setSearchGroup}
          />

          <CustomList
            meta={GroupTableMeta}
            data={groupList}
            fieldArray={[]}
            onRequestSort={false}
            isReverseOrder={true}
            orderBy={"name"}
            loading={fetching}
          />
        </>
      </Formik>
      <GroupPaginationStyled>
        {loading && <OwcProgressSpinner />}
        <OwcPagination
          onPageChange={(e) => {
            setShowPerPage(e.detail.rowsPerPage);
            setPageNo(e.detail.page);
          }}
          options={[5, 10, 20, 50]}
          rowsPerPage={showPerPage}
          total={Math.ceil(totalCount / showPerPage)}
        >
          <span slot="rows-per-page">Rows per page</span>
        </OwcPagination>
      </GroupPaginationStyled>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(connect(mapStateToProps), withApollo)(GroupContent);
