import React from "react";
import { useSelector } from "react-redux";
import { changeDateFormat } from "../../../utils/helpers/text";
import { Tooltip } from "@mui/material";
import { OwcIconButton } from "@one/react";

const User = ({ item, field, lookupUserField = "userId" }) => {
  const users = useSelector((state) => state.users.users);
  const user = users?.filter(
    (u) => u[lookupUserField]?.toLowerCase() === item?.[field]?.toLowerCase()
  )?.[0];
  return (
    <Tooltip title={user?.name || user?.email || item?.[field] || "-"}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          display: "inline",
        }}
      >
        {user?.name || user?.email || item?.[field] || "-"}
      </div>
    </Tooltip>
  );
};

export const Status = ({ item }) => {
  return item?.active ? (
    <Tooltip title="Active">
      <OwcIconButton icon="circle_confirm" type="outlined" flat />
    </Tooltip>
  ) : (
    <Tooltip title="Cancelled">
      <OwcIconButton icon="circle_clear" type="outlined" flat />
    </Tooltip>
  );
};

export const Reason = ({ item }) => {
  return (
    <Tooltip title={item.remark || "-"}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {item.remark || "-"}
      </div>
    </Tooltip>
  );
};

export const GroupList = ({ item }) => {
  const sortedUserList =
    item?.users
      ?.map((user) =>
        user?.givenName && user?.familyName
          ? `${user?.givenName?.trim()} ${user?.familyName?.trim()}`
          : user?.email
      )
      ?.sort() || [];
  const userString = sortedUserList?.join(", ") || "-";
  return (
    <Tooltip title={userString}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {userString}
      </div>
    </Tooltip>
  );
};

export const GroupAssign = ({ item }) => {
  const sortedUserList = item?.users
    ?.split(",")
    ?.map((user) => user?.trim())
    ?.sort();
  const userString = sortedUserList?.join(", ") || "-";
  return (
    <Tooltip title={userString}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {userString}
      </div>
    </Tooltip>
  );
};
export const Equipment = ({ item }) => {
  const equipment = item?.equipment?.shortIdentifier;
  return (
    <Tooltip title={equipment}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {equipment}
      </div>
    </Tooltip>
  );
};

export const EquipmentModel = ({ item }) => {
  const equipmentModel = item?.equipment?.equipmentModel || "-";
  return (
    <Tooltip title={equipmentModel}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {equipmentModel}
      </div>
    </Tooltip>
  );
};

export const EquipmentField = ({ item, field }) => {
  const equipmentField =
    !item?.equipment?.[field] || item?.equipment?.[field] === "null"
      ? "-"
      : item?.equipment?.[field];
  return (
    <Tooltip title={equipmentField}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {equipmentField}
      </div>
    </Tooltip>
  );
};

export const EquipmentNickName = ({ item }) => {
  const equipmentNickName =
    !item?.equipment?.equipmentNickName ||
    item?.equipment?.equipmentNickName === "null"
      ? "-"
      : item?.equipment?.equipmentNickName;
  return (
    <Tooltip title={equipmentNickName}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {equipmentNickName}
      </div>
    </Tooltip>
  );
};

export const SerialNumber = ({ item }) => {
  const serialNumber =
    !item?.equipment?.serialNumber || item?.equipment?.serialNumber === "null"
      ? "-"
      : item?.equipment?.serialNumber;
  return (
    <Tooltip title={serialNumber}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {serialNumber}
      </div>
    </Tooltip>
  );
};

export const EquipmentId = ({ item }) => {
  const equipmentId =
    !item?.equipment?.equipmentId || item?.equipment?.equipmentId === "null"
      ? "-"
      : item?.equipment?.equipmentId;
  return (
    <Tooltip title={equipmentId}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {equipmentId}
      </div>
    </Tooltip>
  );
};

export const Date = ({ item }) => {
  return (
    <Tooltip title={changeDateFormat(item?.date, "DD-MMM-YYYY") || "-"}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {changeDateFormat(item?.date, "DD-MMM-YYYY") || "-"}
      </div>
    </Tooltip>
  );
};

export const Hour = ({ item }) => {
  return (
    <Tooltip
      title={`${changeDateFormat(
        item.dateFrom,
        "h:mm"
      )}${" "}-${" "}${changeDateFormat(item.dateTo, "h:mm")}`}
    >
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        {changeDateFormat(item.dateFrom, "h:mm")} -
        {changeDateFormat(item.dateTo, "h:mm")}
      </div>
    </Tooltip>
  );
};

export default User;
