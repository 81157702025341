const NotFoundIcon = (props) => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.8427 75.3681H33V82.511H28.8427V91H19.3764V82.511H3.5618L3 76.8516L19.3764 51.0824V51H28.8427V75.3681ZM12.0169 75.3681H19.3764V63.0604L18.7865 63.9945L12.0169 75.3681Z"
      fill="white"
    />
    <path
      d="M30.9813 74.1886H36V82.142H30.9813V91H21.8305V81.2788H6V75.6612L21.8305 50.0818V50H30.9813V74.1886ZM14.7163 74.1886H20.8305V61.9716L20.2603 62.8988L14.7163 74.1886Z"
      fill="#BFD9F2"
    />
    <path
      d="M3.56177 83.511H18.3764V91V92H19.3764H28.8427H29.8427V91V83.511H33H34V82.511V75.3681V74.3681H33H29.8427V51V49.9858L28.8286 50.0001L22.9929 50.0824H19.3764H18.8342L18.5384 50.5367L2.72381 74.8224L2.5618 75.0712L2.5618 75.3681L2.56177 82.511L2.56176 83.511H3.56177ZM18.3764 66.6387V74.3681H13.7758L18.3764 66.6387Z"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <path
      d="M124.5 75.3681V74.8681H124H120.481V51V50.5H119.981H110.831H110.331V50.9411L94.574 76.5899L94.4863 76.7327L94.5023 76.8994L95.0454 82.5588L95.0888 83.011H95.5431H110.331V91V91.5H110.831H119.981H120.481V91V83.011H124H124.5V82.511V75.3681ZM110.331 64.875V74.8681H104.581L110.331 64.875Z"
      fill="white"
      stroke="#80B3E6"
    />
    <path
      d="M122.843 74.1886H127V82.142H122.843V91H113.376V81.2788H97.5618L97.5618 75.5L113.376 50.0818V50H122.843V74.1886ZM106.017 74.1886H112.376V61.9716L111.786 62.8988L106.017 74.1886Z"
      fill="#BFD9F2"
    />
    <path
      d="M95.543 83.511H109.831V91V92H110.831H119.981H120.981V91V83.511H124H125V82.511V75.3681V74.3681H124H120.981V51V49.9849L119.966 50.0001L114.492 50.0824H110.831H110.278L109.984 50.5497L94.6968 74.8354L94.5431 75.0796L94.5431 75.3681L94.543 82.511L94.543 83.511H95.543ZM109.831 66.7466V74.3681H105.445L109.831 66.7466Z"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <rect
      x="77.4393"
      y="99.8503"
      width="8.88085"
      height="21.7669"
      rx="2"
      transform="rotate(-30 77.4393 99.8503)"
      fill="#BFD9F2"
    />
    <rect
      x="76.8805"
      y="100.173"
      width="6.70721"
      height="19.218"
      rx="1"
      transform="rotate(-30 76.8805 100.173)"
      fill="white"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <circle
      cx="63.7846"
      cy="70.7846"
      r="24"
      transform="rotate(-30 63.7846 70.7846)"
      fill="white"
    />
    <circle
      cx="66.7846"
      cy="70.7846"
      r="22.9256"
      transform="rotate(-30 66.7846 70.7846)"
      fill="#BFD9F2"
    />
    <circle
      cx="63.7846"
      cy="70.7846"
      r="23"
      transform="rotate(-30 63.7846 70.7846)"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <circle
      cx="63.7838"
      cy="70.7838"
      r="18"
      transform="rotate(-30 63.7838 70.7838)"
      fill="#F2F7FC"
    />
    <circle
      cx="63.7838"
      cy="70.7838"
      r="17"
      transform="rotate(-30 63.7838 70.7838)"
      fill="white"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <path
      d="M79.7852 98.4974L75.2852 90.7031"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <rect
      x="34.9055"
      y="20.3899"
      width="8"
      height="8"
      transform="rotate(34.3642 34.9055 20.3899)"
      fill="white"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <path
      d="M92.0674 38.2937L98.6772 29.9985L100.786 35.2952L107.396 27"
      stroke="#80B3E6"
      strokeWidth="2"
    />
    <path d="M2 108H72.5" stroke="#80B3E6" strokeWidth="2" />
    <path d="M98 108L124 108" stroke="#80B3E6" strokeWidth="2" />
    <circle
      cx="68.5"
      cy="14.5"
      r="3.5"
      fill="white"
      stroke="#80B3E6"
      strokeWidth="2"
    />
  </svg>
);
export default NotFoundIcon;
