import LoadEquipmentInfo from "../equipment-list/LoadEquipmentInfo";
import BookingEquipmentListTab from "../equipment-list/BookingEquipmentListTab";
import CalendarMainPage from "./calendar-timeline/CalendarMainPage";
import ForWhomMainPage from "./whom/ForWhomMainPage";
import { useContext, useEffect, useState } from "react";
import {
  formatUserList,
  isSameDayBooking,
} from "../../utils/helpers/text/index";
import {
  equipmentTabGroup,
  emptyBookingDetails,
  TIMELINE_TYPE,
  DEFINE_EXPIREMENT_TEXT,
  CHANGE_EXPIREMENT_TEXT,
  WEEK_CONFIG,
  DAY_CONFIG,
} from "../../constants";
import { Formik } from "formik";
import { ForWhomMainPageSchema } from "./validations/ForWhomMainPageSchema";
import BookEquipmentAction from "./BookEquipmentAction";
import styles from "./BookingTabContainer.module.scss";
import moment from "moment/moment";
import { connect, useSelector } from "react-redux";
import { BookingContext } from "../booking/context";
import { CustomFilterContext } from "../booking-instruments/booking-instruments-custom-filter/context";
import { loadBookingInstruments as loadBookingInstrumentsAction } from "../booking-instruments/redux/actions";

const BookingTabContainer = ({
  setSubscriptionStatus,
  setPopupEnableStatus,
  loadBookingInstruments,
}) => {
  const user = useSelector((store) => store.user);
  const { selectedList } = useContext(CustomFilterContext);
  const { isEditMode, editBookingData } = useContext(BookingContext);

  const [isDateSame] = useState(() =>
    isEditMode
      ? isSameDayBooking(
          editBookingData?.dateFrom,
          editBookingData?.dateTo,
          user.timezone
        )
      : false
  );
  const [activeTab, setActiveTab] = useState(isEditMode ? 2 : 1);
  const [tabList, setTabList] = useState(() => {
    const tempTabList = [...equipmentTabGroup];
    if (isEditMode) {
      tempTabList[1].content = CHANGE_EXPIREMENT_TEXT;
    }
    return tempTabList;
  });
  const [selectedStartDate, setSelectedStartDate] = useState(
    isEditMode ? editBookingData?.dateFrom : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    isEditMode ? editBookingData?.dateTo : null
  );
  const [disabledSelection, setDisableSelection] = useState(true);
  const [equipmentDetailList, setEquipmentDetailList] = useState(
    isEditMode
      ? editBookingData?.bookingEntries.map((x) => {
          x.equipment["id"] = x.inventoryId;
          return x.equipment;
        })
      : []
  );
  const [view, setView] = useState(
    isEditMode
      ? isDateSame
        ? TIMELINE_TYPE.DAY
        : TIMELINE_TYPE.WEEK
      : TIMELINE_TYPE.DAY
  );
  const [isStepEnable, setIsStepEnable] = useState(false);
  const [newEquipmentIds, setNewEquipmentIds] = useState([]);
  const [calView, setCalView] = useState(
    isEditMode ? (isDateSame ? DAY_CONFIG : WEEK_CONFIG) : DAY_CONFIG
  );
  const [cancelStatus, setCancelStatus] = useState(false);
  const [deleteResourceId, setDeleteResourceId] = useState(null);
  const [limitExceedStatus, setLimitExceedStatus] = useState(false);

  useEffect(() => {
    if (selectedEndDate && !isStepEnable) {
      setIsStepEnable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEndDate]);
  useEffect(() => {
    updateEquipmentDetailsInRedux();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);
  const handleTabChange = (id) => {
    const tempData = tabList.map((subItem) => {
      subItem.isActive = false;
      if (subItem.id === id) {
        subItem.isActive = true;
      }
      return subItem;
    });
    if (id === 3) {
      tempData[1].content = `${moment(selectedStartDate)
        .tz(user.timezone)
        .format("DD-MMM-YYYY")} to ${moment(selectedEndDate)
        .tz(user.timezone)
        .format("DD-MMM-YYYY")}`;
    } else {
      tabList[1].content = selectedEndDate
        ? CHANGE_EXPIREMENT_TEXT
        : DEFINE_EXPIREMENT_TEXT;
    }
    setPopupEnableStatus(id === 2 || id === 3);
    setActiveTab(id);
    setTabList(tempData);
  };
  const getEquipmentDetailsList = (inventoryIdList, detailList) => {
    const bookingInstruments = [];

    inventoryIdList.forEach((x) => {
      const findRow = detailList.find((y) => y.id === x);
      if (findRow !== undefined) {
        bookingInstruments.push({
          ...findRow,
          inventoryId: findRow.id,
          shortIdentifier: findRow?.shortIdentifier ?? findRow.equipmentModel,
        });
      }
    });

    return bookingInstruments;
  };
  let bookedUser = [];
  if (isEditMode) {
    bookedUser = editBookingData?.bookedUsers.map((item) => ({
      ...item,
      key: item.email,
      userId: item.user,
      value: formatUserList(item),
    }));
  }

  const getActiveStep = () => {
    switch (activeTab) {
      case 1:
        return (
          <LoadEquipmentInfo
            isStepEnable={isStepEnable}
            setNewEquipmentIds={setNewEquipmentIds}
            setEquipmentDetailList={setEquipmentDetailList}
            setLimitExceedStatus={setLimitExceedStatus}
            limitExceedStatus={limitExceedStatus}
          />
        );
      case 2:
        return (
          <CalendarMainPage
            equipmentDetailList={equipmentDetailList}
            getEquipmentDetailsList={getEquipmentDetailsList}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            tabList={tabList}
            setTabList={setTabList}
            view={view}
            setView={setView}
            calView={calView}
            setCalView={setCalView}
            newEquipmentIds={newEquipmentIds}
            setIsStepEnable={setIsStepEnable}
            setNewEquipmentIds={setNewEquipmentIds}
            isEditMode={isEditMode}
            cancelStatus={cancelStatus}
            setCancelStatus={setCancelStatus}
            deleteResourceId={deleteResourceId}
            setDeleteResourceId={setDeleteResourceId}
          />
        );
      case 3:
        return (
          <ForWhomMainPage
            cancelStatus={cancelStatus}
            setCancelStatus={setCancelStatus}
            deleteResourceId={deleteResourceId}
            setDeleteResourceId={setDeleteResourceId}
          />
        );
      default:
        return (
          <LoadEquipmentInfo setEquipmentDetailList={setEquipmentDetailList} />
        );
    }
  };
  const updateEquipmentDetailsInRedux = () => {
    const bookingInstruments = getEquipmentDetailsList(
      selectedList,
      equipmentDetailList
    );
    loadBookingInstruments({
      bookingInstruments: bookingInstruments,
    });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: activeTab === 2 ? "16px 16px 0px" : "16px 16px 16px 16px",
          width: "100%",
        }}
      >
        <BookingEquipmentListTab
          tabList={tabList}
          handleTabChange={handleTabChange}
          setTabList={setTabList}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setDisableSelection={setDisableSelection}
          activeTab={activeTab}
          setSelectedStartDate={setSelectedStartDate}
          setSelectedEndDate={setSelectedEndDate}
          equipmentDetailList={equipmentDetailList}
          setLimitExceedStatus={setLimitExceedStatus}
        />
      </div>
      <Formik
        validationSchema={ForWhomMainPageSchema}
        initialValues={
          isEditMode
            ? {
                bookingType: editBookingData?.type,
                bookingFor: bookedUser,
                project: editBookingData?.project,
                description: editBookingData?.description,
              }
            : { ...emptyBookingDetails }
        }
        validateOnMount={true}
      >
        <div>
          {getActiveStep()}
          <div className={styles.confrimButtonStyle}>
            <BookEquipmentAction
              disabledSelection={disabledSelection}
              handleTabChange={handleTabChange}
              tabList={tabList}
              setTabList={setTabList}
              setSelectedStartDate={setSelectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              setSubscriptionStatus={setSubscriptionStatus}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              setPopupEnableStatus={setPopupEnableStatus}
            />
          </div>
        </div>
      </Formik>
    </>
  );
};

export default connect(null, {
  loadBookingInstruments: loadBookingInstrumentsAction,
})(BookingTabContainer);
