import PowerOffIcon from "@mui/icons-material/PowerOff";
import { OwcTypography } from "@one/react";
import { getText } from "../../utils/helpers/text";
import {
  ErrorStatusesDetailed,
  NormalStatusesDetailed,
  ServiceStatusesDetailed,
  UnknownStatusesDetailed,
} from "../booking-instruments/InstrumentStatuses";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import WarningIcon from "@mui/icons-material/Warning";
import HelpIcon from "@mui/icons-material/Help";
import ServiceIcon from "../../icons/Service";
import { IconButton } from "@mui/material";

const MonitoringStatusRender = (params) => {
  const getStatusIcon = (status) => {
    const statusText = getText(status);
    if (Object.values(NormalStatusesDetailed).includes(statusText)) {
      return (
        <PlayCircleFilledWhiteIcon data-testid="normal-icon" color="primary" />
      );
    } else if (Object.values(ErrorStatusesDetailed).includes(statusText)) {
      return <WarningIcon data-testid="error-icon" color="error" />;
    } else if (Object.values(ServiceStatusesDetailed).includes(statusText)) {
      return <ServiceIcon data-testid="service-icon" className="orange" />;
    } else if (Object.values(UnknownStatusesDetailed).includes(statusText)) {
      return <PowerOffIcon data-testid="unknown-icon" />;
    } else {
      return <HelpIcon data-testid="help-icon" />;
    }
  };
  if (params?.data?.instrument_status) {
    const statusIcon = getStatusIcon(params?.data?.instrument_status[0]);
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton>{statusIcon}</IconButton>
          <OwcTypography>{params?.data?.instrument_status[0]}</OwcTypography>
        </div>
      </>
    );
  } else {
    return "";
  }
};

export default MonitoringStatusRender;
