import DialogContentText from "@material-ui/core/DialogContentText";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ReservationTypes } from "../redux/reservationTypes";
import { OwcEditableDropdown, OwcInput, OwcListItem } from "@one/react";

export const DateStyled = styled.h6`
  font-family: Roboto, "sans-serif";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  margin: 24px 0 16px;
`;

const EditReservationField = ({
  state,
  handleChange,
  classes,
  shortName,
  ...props
}) => {
  return (
    <OwcInput
      variant="filled"
      id={shortName}
      name={shortName}
      value={state[shortName]}
      onInputChange={handleChange(shortName)}
      {...props}
    />
  );
};

const onChangeReservedForEmailFactory = (users, handleChange) => {
  return (event) => {
    let selected = null;
    if (event.detail !== null) {
      selected = users.find((x, index) => index === event.detail);

      handleChange("reservedForUser")({
        target: { value: selected.userId },
      });
      handleChange("reservedForEmail")({
        target: { value: selected.email },
      });
    }
  };
};

const onChangeAvailability = (handleChange) => {
  return (event) => {
    let selected = null;
    if (event.detail !== null) {
      selected = Object.keys(ReservationTypes).find(
        (x, index) => index === event.detail
      );
      handleChange("availability")({
        target: { value: ReservationTypes[selected] },
      });
    }
  };
};

const DialogContentForEditReservation = ({
  item,
  allowEditReservedFor,
  classes,
  state,
  users,
  handleChange,
}) => {
  return (
    <>
      <DateStyled data-testid="booking-calendar-edit-modal-day">
        {moment(item?.date).format("dddd, DD-MMM-YYYY")}
      </DateStyled>
      <DialogContentText data-testid="booking-calendar-edit-modal-content-text">
        {allowEditReservedFor && (
          <div style={{ marginBottom: 20 }}>
            <OwcEditableDropdown
              value={state["reservedForEmail"]}
              state={state}
              style={{ width: "100%" }}
              id="inputs-for-one-booking-reserved-for"
              label="Booking for"
              data-testid="booking-filter-admin-reserved-for-email"
              onSelectChange={onChangeReservedForEmailFactory(
                users,
                handleChange
              )}
            >
              {users?.map((user) => {
                return (
                  <>
                    <OwcListItem value={user.email} key={user.email}>
                      {user?.name || user?.email}
                    </OwcListItem>
                  </>
                );
              })}
            </OwcEditableDropdown>
          </div>
        )}
        <div style={{ marginBottom: 20 }}>
          <OwcEditableDropdown
            value={state["availability"]}
            state={state}
            style={{ width: "100%" }}
            id="inputs-for-one-booking-reservation-type"
            label="Booking type"
            onSelectChange={onChangeAvailability(handleChange)}
          >
            {Object.keys(ReservationTypes).map((reservationKey) => {
              return (
                <>
                  <OwcListItem
                    value={reservationKey.toLowerCase()}
                    key={reservationKey.toLowerCase()}
                  >
                    {ReservationTypes[reservationKey]}
                  </OwcListItem>
                </>
              );
            })}
          </OwcEditableDropdown>
        </div>
        <div style={{ marginBottom: 20 }}>
          <EditReservationField
            state={state}
            handleChange={handleChange}
            style={{ width: "100%" }}
            shortName="project"
            label="Project"
            data-testid="inputs-for-one-booking-assign-to-project"
            maxLength={30}
          />
        </div>
        <EditReservationField
          state={state}
          handleChange={handleChange}
          style={{ width: "100%" }}
          shortName="description"
          label="Description *"
          data-testid="inputs-for-one-booking-description"
          maxLength={200}
          {...(!state.description.length
            ? { helperText: "This field is required" }
            : null)}
          error={!state.description.length}
          required
        >
          {!state.description.length ? (
            <span slot="error-text">This field is required</span>
          ) : null}
        </EditReservationField>
      </DialogContentText>
    </>
  );
};
export default DialogContentForEditReservation;
