import { debounce } from "underscore";
import { useContext, useEffect } from "react";
import { ReservationTypes } from "../../features/booking-calendars/redux/reservationTypes";
import { BOOKING_BY_SITE_AND_DATE } from "../../gql/bookingapi/queries";
import { CustomFilterContext } from "../../features/booking-instruments/booking-instruments-custom-filter/context";
import { createGQLFilterObj, getAllData } from "../helpers/fetching";

export const getReservations = debounce(
  async ({
    query,
    client,
    callback,
    filter,
    variables,
    sortDirection,
    dataPath,
    setFetching,
    ...rest
  }) => {
    setFetching(true);
    const { items: reservations } = await getAllData({
      client,
      query,
      fetchPolicy: "no-cache",
      dataPath,
      drillData: true,
      variables: {
        dateRange: [rest?.dateFrom, rest?.dateTo],
        site: rest?.site,
        filter: createGQLFilterObj({ active: rest?.active, ...filter }),
        sortDirection,
        ...variables,
      },
    });

    callback(
      reservations.map((reservation) => ({
        ...reservation,
        availability: ReservationTypes[reservation.type],
      }))
    );
    setFetching(false);
  },
  300
);

export const useFilteredReservations = (
  client,
  callback,
  filter = { active: true },
  variables = { limit: 1000 },
  dataPath = ["data", "bookingBySiteAndDate"],
  query = BOOKING_BY_SITE_AND_DATE
) => {
  const { dateFrom, dateTo, site, active, sortDirection, setFetching } =
    useContext(CustomFilterContext);

  useEffect(() => {
    callback([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReservations({
      query,
      dataPath,
      client,
      callback,
      variables,
      filter,
      site,
      dateFrom,
      dateTo,
      active,
      sortDirection,
      setFetching,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, site, active, sortDirection]);
};
