import { format, parse } from "date-fns";
import styled from "styled-components";
import {
  InstrumentMainInformation,
  TitleInfoRow,
  SerialNumberRow,
} from "../../../booking-calendars/describe-task-page/InfoAboutInstrumentRow";
import InstrumentPicture, {
  IconPreviewStyled,
} from "../../../booking-instruments/booking-instruments-cards-view/elements/InstrumentPicture";
import InstrumentStatusIcon from "../../../booking-instruments/booking-instruments-icon-statuses/InstrumentStatusIcon";

const IconStyle = {
  width: 20,
  height: 20,
};

const MaintanaceColumn = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 4px;
`;
const InstrumentStatusRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;
const SerialNumberRowForDetailed = styled(SerialNumberRow)`
  margin-left: 0;
  margin-bottom: 14px;
`;
const MainInfoRow = styled.div`
  margin-left: 30px;
  flex-grow: 1;
`;

const TitleInfoRowMargin = styled(TitleInfoRow)`
  margin-left: 10px;
`;
const InstrumentMainInformationMargin = styled(InstrumentMainInformation)`
  margin-bottom: 32px;

  ${IconPreviewStyled} {
    flex: 1 0 30%;
    height: auto;
  }
`;

const MainInfoColumnForDetailedInfo = ({
  serialNumber,
  materialNumber,
  instrumentStatus,
  lastMaintanace,
  nextMaintanace,
  displayImage,
  shortIdentifier,
  equipmentModel,
}) => {
  return (
    <InstrumentMainInformationMargin data-testid="detailed-info-for-instrument-info-section-main-info-column">
      <InstrumentPicture
        equipmentModel={equipmentModel}
        displayImage={displayImage}
      />
      <MainInfoRow data-testid="detailed-info-for-instrument-info-section-main-info-column-basic-data">
        <SerialNumberRowForDetailed data-testid="detailed-info-for-instrument-info-section-main-info-column-basic-data-serial-number">
          {shortIdentifier}
        </SerialNumberRowForDetailed>
        <InstrumentStatusRow data-testid="detailed-info-for-instrument-info-section-main-info-column-basic-instrument-status-row">
          <InstrumentStatusIcon
            status={instrumentStatus}
            style={IconStyle}
            data-testid="detailed-info-for-instrument-info-section-main-info-column-basic-instrument-status-row-icon"
          />
          <TitleInfoRowMargin data-testid="detailed-info-for-instrument-info-section-main-info-column-basic-instrument-status-row-status-text">
            {instrumentStatus}
          </TitleInfoRowMargin>
        </InstrumentStatusRow>
        <MaintanaceColumn data-testid="detailed-info-for-instrument-info-section-main-info-column-basic-instrument-last-maintanace">
          Last maintenance:
          {lastMaintanace ? (
            <span>
              {" "}
              {format(
                parse(lastMaintanace, "yyyy-MM-dd", new Date()),
                "dd-MMM-yyyy"
              )}
            </span>
          ) : (
            <span> –</span>
          )}
        </MaintanaceColumn>
        <MaintanaceColumn data-testid="detailed-info-for-instrument-info-section-main-info-column-basic-instrument-upcoming-maintanace">
          Upcoming maintenance:
          {nextMaintanace ? (
            <span>
              {" "}
              {format(
                parse(nextMaintanace, "yyyy-MM-dd", new Date()),
                "dd-MMM-yyyy"
              )}
            </span>
          ) : (
            <span> –</span>
          )}
        </MaintanaceColumn>
      </MainInfoRow>
    </InstrumentMainInformationMargin>
  );
};

export default MainInfoColumnForDetailedInfo;
