import React, { useContext } from "react";
import { ReasonContext } from "./context";
import { OwcIconButton } from "@one/react";
import { Tooltip } from "@mui/material";

const ReasonActions = ({ item }) => {
  const { setDialogOpen, setIsEditMode, setIsDeleteMode, setCurrentReason } =
    useContext(ReasonContext);
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <Tooltip
        data-testid={"equipment-edit-button-tooltip" + item.id}
        title={"Edit reason"}
        placement="left"
        arrow
      >
        <OwcIconButton
          key={`booking-admin-table-edit-instrument-button-${item.id}`}
          data-testid={`booking-admin-table-edit-instrument-button-${item.id}`}
          onClick={() => {
            setCurrentReason(item);
            setIsEditMode(true);
            setDialogOpen(true);
          }}
          icon="edit"
          type="legacy"
          flat
        />
      </Tooltip>
      <Tooltip
        data-testid={"equipment-edit-button-tooltip" + item.id}
        title={"Delete reason"}
        placement="left"
        arrow
      >
        <OwcIconButton
          key={`booking-admin-table-edit-instrument-button-${item.id}`}
          data-testid={`booking-admin-table-edit-instrument-button-${item.id}`}
          onClick={() => {
            setCurrentReason(item);
            setIsEditMode(false);
            setDialogOpen(true);
            setIsDeleteMode(true);
          }}
          icon="delete"
          type="legacy"
          flat
        />
      </Tooltip>
    </div>
  );
};
export default ReasonActions;
