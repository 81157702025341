import { OwcIcon, OwcTypography } from "@one/react";
import React from "react";
import { IconStatus } from "../../../constants";
import { formatUserListWithoutShortId } from "../../../utils/helpers/text";
import { useSelector } from "react-redux";

const PopoverBookingTitle = ({ data }) => {
  const eventData = data?.eventData;
  const usersList = useSelector((state) => state.users.users);

  let list = usersList.map((item) => ({
    ...item,
    key: item.email,
    value: formatUserListWithoutShortId(item),
  }));

  // Booking by
  const bookingBy = eventData?.createdBy;
  const user = list.find((user) => user?.userId === bookingBy);
  const iconDetails = IconStatus.find((i) => i.text === eventData?.type);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <OwcIcon
          type="outlined"
          name={iconDetails?.icon}
          style={{
            color: iconDetails?.color,
          }}
        />
        <OwcTypography
          variant="button"
          style={{ paddingLeft: 10, color: iconDetails?.color }}
        >
          {iconDetails?.text + ` by ${user?.value}`}
        </OwcTypography>
      </div>
    </div>
  );
};

export default PopoverBookingTitle;
