import { OwcChip, OwcGrid, OwcTooltip, OwcTypography } from "@one/react";
import React from "react";
import { valueOrEmpty } from "../../../utils/helpers/text";

const PopoverAttributesDetails = ({ data, title, metaData }) => {
  const displayDataInChipsOrText = (key, data) => {
    if (key === "qualificationStatus" || key === "systemStatus") {
      let value = valueOrEmpty(data[key], false, "-");
      return value !== "-" ? (
        <div>
          <OwcChip outlined id={`attr_${value}`}>
            {value}{" "}
          </OwcChip>
          <OwcTooltip anchor={`attr_${value}`}>{value}</OwcTooltip>
        </div>
      ) : (
        value
      );
    } else {
      return (
        <div>
          <OwcTypography variant="button" id={`attr_${key}`}>
            {valueOrEmpty(data[key], false, "-")}
          </OwcTypography>
          <OwcTooltip anchor={`attr_${key}`}>
            {valueOrEmpty(data[key], false, "-")}
          </OwcTooltip>
        </div>
      );
    }
  };
  return (
    <div>
      <OwcTypography variant="button" style={{ margin: "16px 0" }}>
        {title}
      </OwcTypography>
      <div style={{ padding: "0 30px" }}>
        <OwcGrid container columns="4" vGutter={{ m: 10, l: 15, xl: 20 }}>
          {Object.keys(metaData).map((key) => (
            <OwcGrid
              item
              l={1}
              m={1}
              xs={1}
              s={1}
              key={metaData[key].key}
              style={{ marginBottom: 16 }}
            >
              <OwcTypography variant="caption">
                {metaData[key].value}
              </OwcTypography>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {displayDataInChipsOrText(key, data)}
              </span>
            </OwcGrid>
          ))}
        </OwcGrid>
      </div>
    </div>
  );
};

export default PopoverAttributesDetails;
