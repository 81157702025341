import React from "react";
import PlayCircleFilledWhiteRoundedIcon from "@mui/icons-material/PlayCircleFilledWhiteRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import PowerOffRoundedIcon from "@mui/icons-material/PowerOffRounded";
import ServiceIcon from "../../../icons/Service";
import {
  ErrorStatusesDetailed,
  NormalStatusesDetailed,
  UnknownStatusesDetailed,
  ServiceStatusesDetailed,
} from "../InstrumentStatuses";
import { getText } from "../../../utils/helpers/text";
import styled from "styled-components";
import { Tooltip } from "@mui/material";

const InstrumentStatus = React.memo(({ status = "", ...rest }) => {
  const statusText = getText(status);

  if (Object.values(NormalStatusesDetailed).includes(statusText)) {
    return (
      <Tooltip title={`Monitoring status: ${statusText}`}>
        <PlayCircleFilledWhiteRoundedIcon
          data-testid="normal-icon"
          color="primary"
          {...rest}
        />
      </Tooltip>
    );
  }
  if (Object.values(ErrorStatusesDetailed).includes(statusText)) {
    return (
      <Tooltip title={`Monitoring status: ${statusText}`}>
        <WarningRoundedIcon data-testid="error-icon" color="error" {...rest} />
      </Tooltip>
    );
  }
  if (Object.values(ServiceStatusesDetailed).includes(statusText)) {
    return (
      <Tooltip title={`Monitoring status: ${statusText}`}>
        <ServiceIcon data-testid="service-icon" className="orange" {...rest} />
      </Tooltip>
    );
  }
  if (Object.values(UnknownStatusesDetailed).includes(statusText)) {
    return (
      <Tooltip title={`Monitoring status: ${statusText}`}>
        <PowerOffRoundedIcon data-testid="unknown-icon" {...rest} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={`Monitoring status: ${statusText ?? "-"}`}>
      <HelpRoundedIcon data-testid="help-icon" {...rest} />
    </Tooltip>
  );
});

export const InstrumentStatusIcon = styled(InstrumentStatus)`
  &[data-testid="service-icon"] {
    color: "#FFC414";
  }
  &[data-testid="unknown-icon"],
  &[data-testid="help-icon"] {
    color: "#737373";
  }
`;

export default InstrumentStatusIcon;
