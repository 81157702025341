import { useState } from "react";
import { ReservationPopoverContext } from "./context";
import ReservationPopover from "./ReservationPopover";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

export const defaultDataToDisplay = [
  {
    label: DATA_MODEL_TABLE.responsiblePerson.value,
    field: DATA_MODEL_TABLE.responsiblePerson.key,
  },
  {
    label: DATA_MODEL_TABLE.systemStatus.value,
    field: DATA_MODEL_TABLE.systemStatus.key,
  },
  {
    label: DATA_MODEL_TABLE.configurationBaseline.value,
    field: DATA_MODEL_TABLE.configurationBaseline.key,
  },
  {
    label: DATA_MODEL_TABLE.softwareVersion.value,
    field: DATA_MODEL_TABLE.softwareVersion.key,
  },
  {
    label: DATA_MODEL_TABLE.belongingToGroup.value,
    field: DATA_MODEL_TABLE.belongingToGroup.key,
  },
];

const ReservationPopoverWrapper = ({
  children,
  allowReservationEdit = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [details, setDetails] = useState(null);
  const [dataToDisplay, setDataToDisplay] = useState(defaultDataToDisplay);
  const [reasonValue, setReasonValue] = useState("");
  const [isEquipmentDetails, setEquipmentDetails] = useState("");
  const [isEventID, setEventID] = useState("");
  const [hideFooter, setHideFooter] = useState("");
  const [equipmentPopOverOpen, setEquipmentPopOverOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const handleOpen = (event, theDetails, theDataToDisplay) => {
    setAnchorEl(event.currentTarget);
    setDetails(theDetails);
    if (theDataToDisplay) {
      setDataToDisplay(theDataToDisplay);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDataToDisplay(defaultDataToDisplay);
  };

  const isOpen = anchorEl !== null;

  const openForElement = (element) => isOpen && element === anchorEl;

  return (
    <ReservationPopoverContext.Provider
      value={{
        isOpen,
        anchorEl,
        handleOpen,
        handleClose,
        details,
        dataToDisplay,
        allowReservationEdit,
        openForElement,
        reasonValue,
        setReasonValue,
        isEquipmentDetails,
        setEquipmentDetails,
        equipmentPopOverOpen,
        setEquipmentPopOverOpen,
        isEventID,
        setEventID,
        hideFooter,
        setHideFooter,
        showLoader,
        setShowLoader,
      }}
    >
      {children}
      <ReservationPopover />
    </ReservationPopoverContext.Provider>
  );
};

export default ReservationPopoverWrapper;
