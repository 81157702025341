import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnCount}, auto);
`;

const BaseCell = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
`;

const Heading = styled(BaseCell)`
  color: #737373;
`;

const Cell = styled(BaseCell)`
  color: #333333;
  font-weight: 500;
  cursor: pointer;
`;

const NoDataFound = styled.div`
  width: 17rem;
  padding: 1rem;
`;

const arrowcell = {
  justifyContent: "space-between",
};
const selectarrow = {
  marginRight: 24,
};

const DefaultMeta = [
  {
    title: DATA_MODEL_TABLE.equipmentNickName.value,
    prop: DATA_MODEL_TABLE.equipmentNickName.key,
    dataTestId: "equipment-nick-name",
  },
  {
    title: DATA_MODEL_TABLE.equipmentModel.value,
    prop: DATA_MODEL_TABLE.equipmentModel.key,
    dataTestId: "instrument-type",
  },
  {
    title: DATA_MODEL_TABLE.materialNumber.value,
    prop: DATA_MODEL_TABLE.materialNumber.key,
    dataTestId: "material-number",
  },
];

const ItemFormSuggestionTable = ({
  onSelect,
  instrumentSuggestions,
  meta = DefaultMeta,
}) => {
  return (
    <Table
      columnCount={meta.length}
      data-testid="suggestion-equpiment-search-table"
    >
      {(!instrumentSuggestions || instrumentSuggestions?.length < 1) && (
        <NoDataFound data-testid="no-suggestion-found">
          No equipment found.
        </NoDataFound>
      )}
      {instrumentSuggestions &&
        instrumentSuggestions?.length >= 1 &&
        meta.map((info) => (
          <Heading
            key={`suggestion-equipment-table-heading-${info.dataTestId}`}
            data-testid={`suggestion-equipment-table-heading-${info.dataTestId}`}
          >
            {info.title}
          </Heading>
        ))}
      {instrumentSuggestions &&
        instrumentSuggestions?.length >= 1 &&
        instrumentSuggestions.map((instrument) => (
          <>
            {meta.map((info, index) => (
              <Cell
                key={`suggestion-equipment-table-cell-${info.dataTestId}`}
                data-testid={`suggestion-equipment-table-cell-${info.dataTestId}`}
                className={index + 1 === meta.length && arrowcell}
                onClick={() => onSelect(instrument)}
              >
                {instrument[info.prop] && instrument[info.prop] !== "null"
                  ? instrument[info.prop]
                  : "-"}
                {index + 1 === meta.length && (
                  <KeyboardArrowRightIcon
                    key={`suggestion-equipment-table-icon-${info.dataTestId}`}
                    color="primary"
                    className={selectarrow}
                  />
                )}
              </Cell>
            ))}
          </>
        ))}
    </Table>
  );
};

export default ItemFormSuggestionTable;
