import Actions from './Actions';
import { GroupList } from '../../booking-table/CustomCells';

export const GroupTableMeta = {
  fields: {
    groupName: {
      text: 'Booking group',
      headProps: {
        style: {
          flex: '0 1 30%',
        },
      },
      cellProps: {
        style: {
          flex: '0 1 30%',
        },
      },
    },
    usersString: {
      component: GroupList,
      text: 'Users',
      headProps: {
        style: {
          flex: '0 1 65%',
        },
      },
      cellProps: {
        style: {
          flex: '0 1 65%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          paddingRight: 15,
        },
      },
    },
    action: {
      component: Actions,
      headProps: {
        style: {
          flex: '0 1 5%',
        },
      },
      cellProps: {
        style: {
          flex: '0 1 5%',
        },
      },
    },
  },
};

export default GroupTableMeta;
