import React, { useCallback, useState, useEffect, useContext } from "react";
import { debounce } from "lodash";
import { PaginationContextReason } from "../../features/booking-admin/reasons/PaginationContextReason";
import { getCookieData, eraseCookieData } from "../../utils/helpers/cookie";
import { DEBOUNCE_DELAY, entryType } from "../../constants";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { updateSearchDropDownFilter as updateSearchDropDownFilterActions } from "./redux/actions";
import { OwcIconButton, OwcInput } from "@one/react";

const Search = ({
  initialQuery = "",
  setQuery,
  label,
  testid = "search",
  rectSearch = false,
  addOnComponent = null,
  searchDropDownFilter,
  updateSearchDropDownFilter,
}) => {
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const { fetching, dispatchAction } = useContext(PaginationContextReason);
  /* eslint-disable react-hooks/exhaustive-deps */
  const delayedQuery = useCallback(
    debounce((q) => setQuery(q), DEBOUNCE_DELAY), // updated for 1 sec
    [setQuery]
  );
  useEffect(() => {
    const searchCookieQuery = getCookieData("searchQuery");
    const type = searchCookieQuery?.split(":")?.[1] || "";
    const query = searchCookieQuery?.split(":")?.[0] || "";
    if (searchCookieQuery) {
      const updatedMenuList = searchDropDownFilter.map((item) => {
        if (type === entryType?.cluster) {
          item.checked = true;
        }
        if (type === entryType?.standaloneEquipment && item.title === "Name") {
          item.checked = true;
        }
        return item;
      });
      updateSearchDropDownFilter([...updatedMenuList]);
      setSearchQuery(type === entryType?.cluster ? "" : query);
      delayedQuery(type === entryType?.cluster ? searchCookieQuery : query);
      eraseCookieData("searchQuery");
    }
  }, []);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  const onClear = () => {
    setQuery("");
    setSearchQuery("");
  };

  const delayedQueryed = useCallback(
    debounce((q) => {
      setQuery(q);
      dispatchAction({
        type: "refreshToken",
        payload: {
          nextToken: null,
          prevTokens: [],
        },
      });
      dispatchAction({
        type: "refresh",
      });
    }, DEBOUNCE_DELAY),
    [setQuery]
  );

  const onChanged = (e) => {
    setSearchQuery(e.target.value);
    delayedQueryed(e.target.value);
  };

  const onCleared = () => {
    setQuery("");
    setSearchQuery("");
    dispatchAction({
      type: "refreshToken",
      payload: {
        nextToken: null,
        prevTokens: [],
      },
    });

    dispatchAction({
      type: "refresh",
    });
  };

  return (
    <>
      {rectSearch ? (
        <OwcInput
          data-testid={testid}
          label={label}
          placeholder={label}
          id="searchInstrument"
          color="primary"
          variant="filled"
          value={searchQuery}
          disabled={fetching}
          onInputChange={onChanged}
          compact
        >
          <OwcIconButton type="outlined" slot="prefix" icon="search" flat />
          <div slot="suffix">
            {searchQuery && (
              <OwcIconButton
                type="filled"
                icon="circle_clear"
                onClick={() => onCleared()}
                className="pointer"
                flat
              />
            )}
            {addOnComponent}
          </div>
        </OwcInput>
      ) : (
        <OwcInput
          data-testid={testid}
          placeholder={label}
          id="searchInstrument"
          value={searchQuery}
          onInputChange={onChange}
          compact
        >
          <OwcIconButton type="outlined" slot="prefix" icon="search" flat />

          <div slot="suffix">
            {searchQuery && (
              <OwcIconButton
                data-testid="clear-search-button"
                type="filled"
                icon="circle_clear"
                flat
                onClick={() => onClear()}
                className="pointer"
              />
            )}
            {addOnComponent}
          </div>
        </OwcInput>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  searchDropDownFilter: state?.bookingInstruments?.searchDropDownFilter,
});

export default compose(
  connect(mapStateToProps, {
    updateSearchDropDownFilter: updateSearchDropDownFilterActions,
  }),

  withApollo
)(Search);
