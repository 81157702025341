const DATA_MODEL_TABLE = {
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname",
  },
  equipmentId: {
    key: "equipmentId",
    value: "Equipment ID",
  },
  materialNumber: {
    key: "materialNumber",
    value: "Material number",
  },
  serialNumber: {
    key: "serialNumber",
    value: "Serial number",
  },
  equipmentCategory: {
    key: "equipmentCategory",
    value: "Category",
  },
  manufacturer: {
    key: "manufacturer",
    value: "Manufacturer",
  },
  manufacture: {
    key: "manufacture",
    value: "Manufacture",
  },
  equipmentModel: {
    key: "equipmentModel",
    value: "Model",
  },
  variant: {
    key: "variant",
    value: "Variant",
  },
  instrumentGTIN: {
    key: "instrumentGTIN",
    value: "GTIN",
  },
  instrumentRUDI: {
    key: "instrumentRUDI",
    value: "RUDI",
  },
  inventoryNumber: {
    key: "inventoryNumber",
    value: "Inventory number",
  },
  belongingToGroup: {
    key: "belongingToGroup",
    value: "Group",
  },
  siteName: {
    key: "siteName",
    value: "Site",
  },
  buildingLocation: {
    key: "buildingLocation",
    value: "Building",
  },
  floor: {
    key: "floor",
    value: "Floor",
  },
  room: {
    key: "room",
    value: "Room",
  },
  functionalLocation: {
    key: "functionalLocation",
    value: "Functional location",
  },
  systemOwner: {
    key: "systemOwner",
    value: "System owner",
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person",
  },
  responsibleProxy: {
    key: "responsibleProxy",
    value: "Responsible proxy",
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status",
  },
  gxpRelevant: {
    key: "gxpRelevant",
    value: "Intended use GxP relevant",
  },
  qualificationStatus: {
    key: "qualificationStatus",
    value: "Qualification status",
  },
  qualificationDocuments: {
    key: "qualificationDocuments",
    value: "Qualification documents",
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "Software version",
  },
  configurationBaseline: {
    key: "configurationBaseline",
    value: "Configuration",
  },
  comment: {
    key: "comment",
    value: "Comment",
  },
  sop: {
    key: "sop",
    value: "SOP",
  },
  installedTests: {
    key: "installedTests",
    value: "Installed tests",
  },
  dateOfLastMaintanance: {
    key: "dateOfLastMaintanance",
    value: "Date of executed maintenance",
  },
  dateOfNextMaintanance: {
    key: "dateOfNextMaintanance",
    value: "Next maintenance or calibration",
  },
  documentedMaintenanceDate: {
    key: "documentedMaintenanceDate",
    value: "Maintenance dates documented elsewhere?",
  },
  dateOfNextPeriodicReview: {
    key: "dateOfNextPeriodicReview",
    value: "Date of periodic review",
  },
  maintenanceIntervalInDays: {
    key: "maintenanceIntervalInDays",
    value: "Maintenance interval in days",
  },
  maintenancePlan: {
    key: "maintenancePlan",
    value: "Maintenance or calibration plan",
  },
  electronicRecord: {
    key: "electronicRecord",
    value: "Electronic records",
  },
  isBookable: {
    key: "isBookable",
    value: "Bookable",
  },
  csv: {
    key: "csv",
    value: "CSV relevant",
  },
  electronicSignatures: {
    key: "electronicSignatures",
    value: "Electronic signatures",
  },
  isVisualized: {
    key: "isVisualized",
    value: "Visualized",
  },
  cluster: {
    key: "cluster",
    value: "Cluster",
  },
  equipmentAdministrator: {
    key: "equipmentAdministrator",
    value: "Test equipment admin",
  },
  costCenter: {
    key: "costCenter",
    value: "Cost center",
  },
  location: {
    key: "location",
    value: "Location",
  },
  linkedInstance: {
    key: "linkedInstance",
    value: "Linked instance",
  },
  controlledEquipmentStatus: {
    key: "controlledEquipmentStatus",
    value: "Controlled equipment status",
  },
  trackPositionInCluster: {
    key: "trackPositionInCluster",
    value: "Position is relevant",
  },
  weightInkg: {
    key: "weightInkg",
    value: "Weight in kg",
  },
  heightInmm: {
    key: "heightInmm",
    value: "Height in mm",
  },
  lengthInmm: {
    key: "lengthInmm",
    value: "Length in mm",
  },
  depthInmm: {
    key: "depthInmm",
    value: "Depth in mm",
  },
  clearanceLeftInmm: {
    key: "clearanceLeftInmm",
    value: "Clearance left in mm",
  },
  clearanceRightInmm: {
    key: "clearanceRightInmm",
    value: "Clearance right in mm",
  },
  clearanceHeightInmm: {
    key: "clearanceHeightInmm",
    value: "Clearance height in mm",
  },
  clearanceToFrontInmm: {
    key: "clearanceToFrontInmm",
    value: "Clearance to front in mm",
  },
  clearanceBackwardsInmm: {
    key: "clearanceBackwardsInmm",
    value: "Clearance backwards in mm",
  },
  editReason: {
    key: "editReason",
    value: "Select reason",
  },
  editComment: {
    key: "editComment",
    value: "Add comment",
  },
  tags: {
    key: "tags",
    value: "Tags",
  },
  positionInCluster: {
    key: "positionInCluster",
    value: "positionInCluster",
  },
  instrumentAvailability: {
    key: "instrumentAvailability",
    value: "Booking availability",
  },
  instrumentStatus: {
    key: "instrumentStatus",
    value: "Monitoring status",
  },
  instrumentBookingStatus: {
    key: "instrumentStatus",
    value: "Booking status",
  },
  shortIdentifier: {
    key: "shortIdentifier",
    value: "Short identifier",
  },
  displayQualificationStatus: {
    key: "displayQualificationStatus",
    value: "Qualification/ Calibration status",
  },
  status: {
    key: "status",
    value: "Equipment status",
  },
};

export default DATA_MODEL_TABLE;
