import { OwcModalDialog, OwcButton, OwcTypography } from "@one/react";
import moment from "moment";
import { useSelector } from "react-redux";

const PopOverModal = ({
  open,
  close,
  title = null,
  children,
  onDialogCloseCallBack,
  props,
  data,
  closeTitle,
  showEmailButton = true,
}) => {
  const user = useSelector((store) => store.user);
  const handleClose = () => {
    close();
    onDialogCloseCallBack();
  };

  const dateFromFormatter = moment(data?.eventData?.start)
    .tz(user.timezone)
    .format("DD-MMM-YYYY");

  const dateToFormatter = moment(data?.eventData?.end)
    .tz(user.timezone)
    .format("DD-MMM-YYYY");

  const timeFrom = moment(data?.eventData?.start)
    .tz(user.timezone)
    .format("hh:mm a");
  const timeTo = moment(data?.eventData?.end)
    .tz(user.timezone)
    .format("hh:mm a");

  let dateFrom = `${dateFromFormatter} ${timeFrom}`;
  let dateTo = `${dateToFormatter} ${timeTo}`;
  let timeRanges = [dateFrom, dateTo];

  const getValue = (value) => (!value || value === "null" ? "-" : value);
  const getEmailHref = (details, timeRanges) => {
    return encodeURI(`mailto:${getValue(details?.eventData?.reservedForEmails)}
    ?subject=Support needed &body=
    Booking date : ${timeRanges}
    Description : ${getValue(details?.eventData?.description)}
    Model : ${getValue(details?.equipmentData?.equipmentModel)}
    Nickname : ${getValue(details?.equipmentData?.equipmentNickName)}
    Responsible person : ${getValue(details?.equipmentData?.responsiblePerson)}
    System status : ${getValue(details?.equipmentData?.systemStatus)}
    Configuration : ${getValue(details?.equipmentData?.configurationBaseline)}
    Software version : ${getValue(details?.equipmentData?.softwareVersion)}
    Group : ${getValue(details?.equipmentData?.belongingToGroup)}`);
  };

  return (
    <OwcModalDialog
      disableScrollLock
      visible={open}
      className="owcmodalZIndex"
      data-testid={"common-pop-over-modal-testid"}
      size={"lg"}
      disableBackdropClick={true}
      {...props}
    >
      {children}
      <div slot="header">
        {title && <OwcTypography variant="button">{title}</OwcTypography>}
      </div>
      <div slot="actions">
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <OwcButton variant="secondary" onClick={handleClose}>
            {closeTitle}
          </OwcButton>
          {Object.keys(data?.eventData).length > 0 && showEmailButton && (
            <a
              href={getEmailHref(data, timeRanges)}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecorationLine: "none" }}
            >
              <OwcButton variant="primary" style={{ marginLeft: "20px" }}>
                Send email
              </OwcButton>
            </a>
          )}
        </div>
      </div>
    </OwcModalDialog>
  );
};

export default PopOverModal;
