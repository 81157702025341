import * as types from './actionTypes';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.LOAD_ROOM_LIST:
      return { ...state, roomList: payload };
    case types.LOAD_BUILDING_LIST:
      return { ...state, buildingList: payload };
    case types.LOAD_ADDREASON_LIST:
      return { ...state, bookingAddReason: payload };
    default:
      return state;
  }
}
