import { useContext, useEffect } from "react";
import { OwcIcon, OwcList, OwcListItem } from "@one/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AdminLinkItem from "../features/booking-admin/AdminLinkItem";
import { BookingContext } from "../features/booking/context";

const DrawerMenu = ({
  setLeaveStatus,
  setTabUrl,
  popupEnableStatus,
  setSideMenu,
  sideMenu,
}) => {
  const history = useHistory();
  const { isEditMode, dispatchAction: bookingDispatchAction } =
    useContext(BookingContext);

  const handleRedirect = (item) => {
    if (!item.isActive) {
      if (popupEnableStatus) {
        setTabUrl(item.url);
        setLeaveStatus(true);
      } else {
        if (isEditMode) {
          bookingDispatchAction({
            type: "resetBookingData",
          });
        }
        history.replace(item.url);
      }
    }
  };

  useEffect(() => {
    const pathName = window.location.pathname;
    const updatedSideMenu = sideMenu.map((item) => {
      item.isActive = item.url === pathName;
      return item;
    });
    setSideMenu(updatedSideMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.listen((location) => {
      const pathName = location.pathname;
      const updatedSideMenu = sideMenu.map((item) => {
        item.isActive = item.url === pathName;
        return item;
      });
      setSideMenu(updatedSideMenu);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <OwcList
        data-testid="sidebar-list"
        id={"side-bar-menu-parent"}
        key={"side-bar-menu-parent"}
      >
        {sideMenu.map((item, i) => {
          return item.text !== "Admin panel" ? (
            <OwcListItem
              selected={item.isActive}
              onClick={() => handleRedirect(item)}
              noBorder
              id={`side-menu-${i}`}
              key={`side-menu-${i}`}
              disabled={item.disable}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {typeof item.icon === "string" ? (
                  <OwcIcon name={item.icon} type="legacy" />
                ) : (
                  <div>{item.icon}</div>
                )}

                <span style={{ marginLeft: 15 }}>{item.text}</span>
              </div>
            </OwcListItem>
          ) : (
            <AdminLinkItem
              item={item}
              handleRedirect={handleRedirect}
              id={`side-menu-${i}`}
              key={`side-menu-${i}`}
              disabled={item.disable}
            />
          );
        })}
      </OwcList>
    </>
  );
};

export default DrawerMenu;
