import React from "react";
import { OwcDrawer, OwcIconButton, OwcTypography } from "@one/react";
import styles from "./AppDrawer.module.scss";
import DATA_LABEL_TABLE from "../utils/constants/dataLabelTable";
import DrawerMenu from "./DrawerMenu";
import { useContext } from "react";
import { TogglerContext } from "../contexts";
import ConfirmDialog from "../components/shared/ConfirmDialog";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { HOME_SUB_MENU } from "../constants";

const AppDrawer = ({ children, popupEnableStatus, setPopupEnableStatus }) => {
  const history = useHistory();
  const { isMini, setIsMini, leaveStatus, setLeaveStatus } =
    useContext(TogglerContext);
  const [tabUrl, setTabUrl] = useState(null);
  const [sideMenu, setSideMenu] = useState(HOME_SUB_MENU);
  const handleOnclose = () => {
    setLeaveStatus(false);
    setTabUrl(null);
    return true;
  };
  const handlerOnCancelDeleteBooking = () => {
    setTabUrl(null);
    setLeaveStatus(false);
  };

  const handlerOnLeave = () => {
    setLeaveStatus(false);
    history.replace(tabUrl);
    setPopupEnableStatus(false);
    return true;
  };
  return (
    <>
      <div className={styles.appdrawerOwcdrawer} data-testid="app-drawer">
        <OwcDrawer id="drawer" mode="push" hideBackdrop visible mini={isMini}>
          <div slot="panel" className={styles.appdrawerPanel}>
            <DrawerMenu
              setLeaveStatus={setLeaveStatus}
              setTabUrl={setTabUrl}
              popupEnableStatus={popupEnableStatus}
              sideMenu={sideMenu}
              setSideMenu={setSideMenu}
            />
          </div>
          <div slot="header" className={styles.appdrawerHeader}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <OwcIconButton
                type="outlined"
                flat
                icon="menu"
                onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
                className={styles.appdrawerIcon}
              />
              <OwcTypography variant="body2" style={{ marginLeft: 8 }}>
                {DATA_LABEL_TABLE.LEFT_MENU_BAR.main_menu_label}
              </OwcTypography>
            </div>

            <OwcIconButton
              type="outlined"
              flat
              icon="clear"
              onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
            />
          </div>
          <div className={styles.appdrawerDrawercontent}>
            <main>{children}</main>
          </div>
        </OwcDrawer>
      </div>
      <ConfirmDialog
        approveText="Leave page"
        approveColor="primary"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={() => handlerOnLeave()}
        title="Leave page"
        content={`The changes that you made won't be saved!`}
        disableBooking={false}
        close={handleOnclose}
        open={leaveStatus}
        onCancel={handlerOnCancelDeleteBooking}
        fullWidth={false}
        maxWidth="sm"
      />
    </>
  );
};

export default AppDrawer;
