import React, { useEffect, useContext } from "react";
import ConfirmDialogReason from "../../../components/shared/ConfirmDialogReason";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "./helpers";
import { ReasonContext } from "./context";
import styled from "styled-components";
import { OwcButton, OwcInput } from "@one/react";
export const CreateFieldStyled = styled.div`
  display: flex;
  justify-content: left;
  padding-bottom: 5px;
  & > .selectBox {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 100%;
  }
`;

const ReasonDialog = ({
  open,
  isEditMode,
  onCancel,
  submit,
  disableReason,
  setDisableReason,
}) => {
  const formik = useFormikContext();
  const classes = {};
  const { currentReason, dialogOpen } = useContext(ReasonContext);
  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue(
      "configText",
      isEditMode ? currentReason.configText : "",
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  useEffect(() => {
    if (isEditMode) {
      setDisableReason(
        currentReason.configText === formik.values.configText || !formik.isValid
      );
    } else {
      setDisableReason(!formik.isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, formik.values.configText]);

  return (
    <ConfirmDialogReason
      open={open}
      close={onCancel}
      approveText={isEditMode ? "Update" : "Add"}
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={onCancel}
      testid={"import-file-dialog"}
      title={isEditMode ? "Edit reason" : "Add reason"}
      renderActions={({ cancelText, approveText }) => (
        <div style={{ paddingBottom: 8, display: "flex" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={onCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={() => {
              submit(formik.values.configText?.trim());
            }}
            variant="primary"
            disabled={disableReason || formik.values?.configText.trim() === ""}
          >
            {approveText}
          </OwcButton>
        </div>
      )}
    >
      <CreateFieldStyled>
        <OwcInput
          data-testid={`text-field-reason-name`}
          label={isEditMode ? "Edit reason *" : "Add reason *"}
          className="TextFieldInput"
          name="configText"
          value={formik.values?.configText}
          onInputChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          style={{ width: "100%" }}
          {...commonPropsForInputsWithoutValue(
            classes,
            formik,
            "configText",
            "configText",
            "text-field",
            isEditMode
          )}
        >
          {formik.errors["configText"] &&
            formik.touched["configText"] === true && (
              <>
                <span slot="error-text">{formik.errors["configText"]}</span>
              </>
            )}
        </OwcInput>
      </CreateFieldStyled>
    </ConfirmDialogReason>
  );
};

export default ReasonDialog;
