import React from "react";
import AppBar from "./AppBarNotAuth";
import styled from "styled-components";
import WelcomeScreen from "../components/WelcomeScreen";
import NoConnectionScreen from "../components/NoConnectionScreen";
import { useOnlineEndpointStatus } from "../utils/hooks";
import { getEnv, checkEnvReturnValue } from "../utils/helpers/text";
const envStatus = checkEnvReturnValue(getEnv(), [true, false]);

const AppWrapper = styled.div`
  .MuiAppBar-root {
  }
`;
const MainPageNotAuthenticated = () => {
  const status = useOnlineEndpointStatus(
    process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
    5000
  );
  return (
    <>
      {envStatus ? (
        <AppWrapper>
          <AppBar disabled={status ? false : true} />
        </AppWrapper>
      ) : (
        <AppBar disabled={status ? false : true} />
      )}

      {status ? (
        <WelcomeScreen nameOfApplication={"Equipment Booking"} />
      ) : (
        <NoConnectionScreen
          text={
            "We are having difficulty connecting to the server. Check your connection or try again later."
          }
          title={"No connection"}
          onCallback={() => {
            window.location.reload();
          }}
        />
      )}
    </>
  );
};
export default MainPageNotAuthenticated;
