import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DLabEquipmentList as CommonAgGrid } from "@digitallab/grid-common-components";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";
import {
  DEFAULT_COLUMNS,
  DEFAULT_FILTER,
  DEFAULT_PAGE_SIZE,
  allTableColumn,
  entryType,
  listOfFieldsIgnoredForSearch,
  listOfFieldsSortable,
  listOfNumberFilter,
  INVALID_BARCODE_MESSAGE,
  CAMERA_TIMEOUT_MESSAGE,
  DURATION,
  agGridAutoGroupfieldName,
  equipmentStatus,
} from "../../constants";
import { filter, find, sortBy, uniq } from "lodash";
import {
  convertCloudToGraphQLObj,
  convertStrToObjColumns,
  convertToCameCase,
  convertToSnakeCase,
  getEnv,
} from "../../utils/helpers/text";
import {
  getColumnObj,
  onCreateSelectColumn,
  onCreatefilter,
  onDeleteSaveConfigurations,
  onUpdateFilter,
  onUpdateSelectColumn,
  saveLastPageSize,
} from "./EquipmentHelper";
import {
  TOOL_PANELS,
  filterKeyMapping,
} from "../../components/DLabGrid/dLabGridConstant";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { useSelector } from "react-redux";
import { withApollo } from "react-apollo";
import {
  CREATE_USER_DISPLAY_COLUMN,
  CREATE_USER_FILTER,
  DELETE_USER_DISPLAY_COLUMN,
  DELETE_USER_FILTER,
  UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
  UPDATE_USER_DISPLAY_COLUMN,
  UPDATE_USER_FILTER,
} from "../../gql/bookingapi";
import { OwcBadge, OwcIcon, OwcTypography } from "@one/react";
import { useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { CustomFilterContext } from "../booking-instruments/booking-instruments-custom-filter/context";
import { TogglerContext } from "../../contexts";
import { snackbarService } from "@one/web-components";
import { getContainerHeight } from "../../utils/helpers/fetching";
import { ConfirmDialog } from "../../components/shared";
import { BookingContext, BookingPopUpContext } from "../booking/context";

const currentEnv = getEnv();
const EquipmentMainPage = ({
  masterDataFilters,
  myLabFilter,
  lastFavFilter,
  setLastFavFilter,
  userFilters,
  setUserFilters,
  userDisplayColumn,
  setUserDisplayColumn,
  lastDisplayColumns,
  setLastDisplayColumns,
  lastPageSize,
  setLastPageSize,
  client,
  setEquipmentDetailList,
  isStepEnable,
  setNewEquipmentIds,
  limitExceedStatus,
  setLimitExceedStatus,
}) => {
  const remainingHeight = 261;
  const masterDataFilterGroups = useRef();
  masterDataFilterGroups.current = masterDataFilters;
  const [dataModel, setDataModel] = useState({});
  const [attributes, setAttributes] = useState({});
  const [defaultShowColumns, setDefaultShowColumns] = useState({
    ...DEFAULT_COLUMNS,
  });
  const { pendingReservations } = useSelector((state) => state.reservations);
  const { setSelectedList, selectedList } = useContext(CustomFilterContext);
  const { isEditMode, isOngoing } = useContext(BookingContext);
  const { dispatchAction: bookingPopUpDispatchAction } =
    useContext(BookingPopUpContext);
  const [onGoingEventArray] = useState(() => {
    let tempArray = [];
    if (isEditMode && isOngoing) {
      pendingReservations.forEach((x) => {
        if (x.isOngoingEventFlag) {
          tempArray.push(x.inventoryId);
        }
      });
    }
    return uniq(tempArray);
  });

  const storeUser = useSelector((store) => store.user);
  const localUserFilters = useRef(userFilters);
  const autoGroupColField = "equipment_model";
  const { listMessage, setListMessage } = useContext(TogglerContext);
  const [containerHeight, setContainerHeight] = useState(
    getContainerHeight(currentEnv, remainingHeight)
  );

  const navigation = useHistory();
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));
  });
  const { InventoryId } = useParams();
  // guarav code need to uncomment
  // useEffect(() => {
  //   if (editBookingData) {
  //     setEquipmentDetailList(
  //       editBookingData?.bookingEntries?.map((x) => {
  //         x.equipment = {
  //           ...x.equipment,
  //           id: x.inventoryId,
  //         };
  //         return x.equipment;
  //       })
  //     );
  //     setSelectedList(
  //       editBookingData?.bookingEntries?.map((x) => x.inventoryId)
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    setAttributes(() => attr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerHeight]);
  useEffect(() => {
    localUserFilters.current = userFilters;
  }, [userFilters]);

  useEffect(() => {
    const unorderedHidenColumn = [];
    const orderedShowColumns = sortBy(
      convertStrToObjColumns(lastDisplayColumns)?.displayDefinition
        ?.showColumns,
      ["order"],
      ["asc"]
    );

    allTableColumn()?.forEach((value, index) => {
      const obj = find(orderedShowColumns, {
        key: value?.key,
      });
      if (!obj) {
        unorderedHidenColumn.push(value);
      }
    });

    const redefinedColumns = [
      ...orderedShowColumns,
      ...unorderedHidenColumn,
    ]?.map((value) => {
      return getColumnObj(
        value,
        masterDataFilters,
        convertToCameCase(autoGroupColField)
      );
    });

    const defaultShowCols = filter(allTableColumn(), { show: true });
    setDefaultShowColumns(() => {
      return defaultShowCols?.map((value) => {
        return getColumnObj(
          value,
          masterDataFilters,
          convertToCameCase(autoGroupColField)
        );
      });
    });

    setDataModel([...redefinedColumns]);
    // eslint-disable-next-line
  }, [masterDataFilters]);

  let attr = {
    height: containerHeight,
    rowModelType: "serverSide",
    rowQuickSearch: false,
    treeData: true,
    suppressPaginationPanel: true,
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    rowExport: false,
    masterDetail: true,
    isGroupOpenByDefault: false,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    serverSideInfiniteScroll: false,
    serverSideSortOnServer: true,
    suppressRowClickSelection: true,
  };

  const requestServer = (cloudSearchConfigOption) => {
    return CloudSearchConfig(cloudSearchConfigOption);
  };
  const isServerSideGroup = useCallback((dataItem) => {
    // indicate if node is a group

    if (
      dataItem?.entry_type &&
      dataItem?.entry_type?.includes(entryType?.cluster)
    ) {
      return dataItem?.id;
    }

    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use

    if (
      dataItem?.entry_type &&
      dataItem?.entry_type?.includes(entryType?.cluster)
    ) {
      return dataItem?.id;
    }

    return "";
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Model",
      field: autoGroupColField,
      sortable: true,
      pinned: "left",
      lockPinned: true,
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        values: (params) => {
          const values =
            masterDataFilterGroups?.current[
              DATA_MODEL_TABLE?.equipmentModel?.key
            ] || [];
          params.success(values);
        },
      },
      showDisabledCheckboxes: true,
      cellStyle: (params) => {
        if (params?.data?.id && params?.data?.id[0] === "dummyRow") {
          return { display: "none" };
        }
        if (
          isEditMode &&
          isOngoing &&
          onGoingEventArray.includes(params?.data?.id[0])
        ) {
          return { "pointer-events": "none" };
        }

        return params?.node?.level > 0 &&
          (params?.data?.entry_type[0] === entryType?.cluster ||
            params?.data?.entry_type[0] === entryType?.clusterSubequipment)
          ? {
              paddingLeft: 20,
              border: "none",
              backgroundColor: "#F5F5F2",
              backgroundImage:
                "conic-gradient(at 7% 90%, #FFFFFF 180deg, #F5F5F2 180deg)",
            }
          : {};
      },
      cellRendererParams: {
        suppressCount: false,
        checkbox: (params) => {
          if (params?.data?.id && params?.data?.id[0] !== "dummyRow") {
            const item = {
              entryType:
                (params.data?.entry_type && params.data?.entry_type[0]) ||
                entryType?.standaloneEquipment,
              id: params.data.id[0],
              clusterId: params.data?.cluster_id
                ? params.data?.cluster_id[0]
                : null,
              status: params.data?.status[0],
              belongingToGroup:
                (params.data?.belonging_to_group &&
                  params.data?.belonging_to_group[0]) ||
                null,
              isBookingControlled:
                params.data?.is_booking_controlled !== undefined &&
                params.data?.is_booking_controlled
                  ? params.data?.is_booking_controlled[0] === "false"
                  : false,
              bookableForUsers:
                params.data?.bookable_for_users !== undefined &&
                params.data?.bookable_for_users
                  ? params.data?.bookable_for_users[0].includes(storeUser?.user)
                  : true,
            };
            if (
              params &&
              params !== undefined &&
              selectedList.includes(params?.data?.id[0])
            ) {
              params.node.setSelected(true);
            }

            if (InventoryId) {
              setEquipmentDetailList((prevState) => {
                return [...prevState, convertCloudToGraphQLObj(params.data)];
              });
              setSelectedList((prevState) => {
                return onlyUnique([...prevState, InventoryId]);
              });
              params.node.setSelected(true);
            }
            return (
              item?.clusterId === null &&
              item?.status !== equipmentStatus?.deleted?.key &&
              params?.node?.level === 0 &&
              (item?.isBookingControlled || item?.bookableForUsers)
            );
          } else {
            params.node.selectable = false;
            return false;
          }
        },

        innerRenderer: (params) => {
          return params?.data?.equipment_model[0];
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async ({
    currentState,
    chipName = "",
    type = "update",
    isAgGridObj = false,
    pannel = "",
    isSaveConfigUpdated = false,
    updateRedux = true,
  }) => {
    let result = null;
    if (pannel === TOOL_PANELS?.filters) {
      let obj = {};
      if (isAgGridObj) {
        for (const key in DEFAULT_FILTER.filterDefinition) {
          if (
            currentState[convertToSnakeCase(filterKeyMapping[key] || key)] ===
            undefined
          ) {
            obj[key] = DEFAULT_FILTER.filterDefinition[key];
          } else if (
            currentState[convertToSnakeCase(filterKeyMapping[key] || key)]
              ?.filterType === "set"
          ) {
            let values =
              currentState[convertToSnakeCase(filterKeyMapping[key] || key)]
                ?.values;
            if (
              filterKeyMapping[key] === DATA_MODEL_TABLE?.equipmentModel?.key ||
              key === DATA_MODEL_TABLE?.equipmentModel?.key
            ) {
              if (
                Object?.keys(currentState)?.includes(agGridAutoGroupfieldName)
              ) {
                values = uniq([
                  ...values,
                  ...currentState[agGridAutoGroupfieldName]?.values,
                ]);
              }
            }
            obj[key] = values;
          } else {
            obj[key] =
              currentState[convertToSnakeCase(filterKeyMapping[key] || key)];
          }
        }
      } else {
        obj = {
          ...currentState,
        };
      }

      const lastSavedFilter = {
        filterDefinition: obj,
        filterName: chipName,
      };
      if (type === "create") {
        lastSavedFilter.userID = storeUser?.id;
        lastSavedFilter.chipName = chipName;
        result = await onCreatefilter({
          id: storeUser?.id,
          email: storeUser?.email,
          userFilters: localUserFilters.current,
          lastFavFilter: lastSavedFilter,
          client,
          query: CREATE_USER_FILTER,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
          updateUserFilters: setUserFilters,
          updateLastFavFilter: setLastFavFilter,
        });
      } else {
        result = await onUpdateFilter({
          lastFavFilter: lastSavedFilter,
          isSaveConfigUpdated: isSaveConfigUpdated,
          updateRedux,
          id: storeUser?.id,
          email: storeUser?.email,
          client,
          query: UPDATE_USER_FILTER,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
          updateLastFavFilter: setLastFavFilter,
        });
      }
      return { savedObj: lastSavedFilter, result };
    } else if (pannel === TOOL_PANELS?.columns) {
      const savedColumns = [...currentState];
      const updatedSavedColumns = savedColumns.map((column, index) => {
        const dataModelKey = convertToCameCase(column?.colId);
        const obj = {
          key: dataModelKey,
          val: DATA_MODEL_TABLE[dataModelKey]?.value || "ignore",
          order: index,
          show: DATA_MODEL_TABLE[dataModelKey]?.value?.length
            ? !column?.hide
            : "ignore",
          sortStatus: listOfFieldsSortable.includes(
            DATA_MODEL_TABLE[dataModelKey]?.key
          ),
        };
        return obj;
      });
      const showColumns = filter(updatedSavedColumns, { show: true });
      const hideColumns = filter(updatedSavedColumns, { show: false });
      let savecolumnObj = {
        displayName: chipName,
        displayDefinition: {
          showColumns,
          hideColumns,
        },
      };
      if (type === "create") {
        savecolumnObj.userID = storeUser?.id;
        savecolumnObj.chipName = chipName;
        result = await onCreateSelectColumn({
          id: storeUser?.id,
          email: storeUser?.email,
          userDisplayList: userDisplayColumn,
          unParsedData: savecolumnObj,
          client,
          query: CREATE_USER_DISPLAY_COLUMN,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
          updateUserDisplayColumns: setUserDisplayColumn,
          updateLastDisplayColumn: setLastDisplayColumns,
        });
      } else {
        result = await onUpdateSelectColumn({
          data: JSON.stringify(savecolumnObj),
          isSaveConfigUpdated,
          updateRedux,
          id: storeUser?.id,
          email: storeUser?.email,
          client,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
          query: UPDATE_USER_DISPLAY_COLUMN,
          userDisplayList: userDisplayColumn,
          updateUserDisplayColumns: setUserDisplayColumn,
          updateLastDisplayColumn: setLastDisplayColumns,
        });
      }
      return { savedObj: savecolumnObj, result };
    }
  };

  const onChipDelete = async (
    chipToDelete,
    lastSavedChip,
    panel,
    userFilters
  ) => {
    return await onDeleteSaveConfigurations({
      userID: storeUser?.id,
      chipToDelete,
      panel,
      lastSavedChip,
      client,
      email: storeUser?.email,
      deleteFilterQuery: DELETE_USER_FILTER,
      deleteColumnQuery: DELETE_USER_DISPLAY_COLUMN,
      userProfileQuery: UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
      userFilters,
      userDisplayList: userDisplayColumn,
      updateUserFilters: setUserFilters,
      updateUserDisplayColumns: setUserDisplayColumn,
      updateLastFavFilter: setLastFavFilter,
      updateLastDisplayColumn: setLastDisplayColumns,
    });
  };

  const onNavigatePath = () => {
    navigation.push("/");
  };

  const onResetAll = async () => {
    await onUpdateFilter({
      lastFavFilter: DEFAULT_FILTER,
      updateRedux: true,
      id: storeUser?.id,
      email: storeUser?.email,
      client,
      query: UPDATE_USER_FILTER,
      userProfileQuery: UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
      updateLastFavFilter: setLastFavFilter,
    });
    await onUpdateSelectColumn({
      data: JSON.stringify(DEFAULT_COLUMNS),
      updateRedux: true,
      id: storeUser?.id,
      email: storeUser?.email,
      client,
      userProfileQuery: UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
      query: UPDATE_USER_DISPLAY_COLUMN,
      userDisplayList: userDisplayColumn,
      updateUserDisplayColumns: setUserDisplayColumn,
      updateLastDisplayColumn: setLastDisplayColumns,
    });
    setSelectedList([]);
    setEquipmentDetailList([]);
  };
  const onlyUnique = (list) => {
    return [...new Set(list)];
  };
  const onRowsPerChange = async (rowPerPage) => {
    await saveLastPageSize({
      lastPageSize: rowPerPage,
      query: UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
      updateLastPageSize: setLastPageSize,
      id: storeUser?.id,
      email: storeUser?.email,
      client,
    });
  };
  const onCheckBoxSelected = ({
    id = "",
    updatedIds = null,
    rowData = null,
  }) => {
    if (id) {
      setEquipmentDetailList((prevState) => {
        return [...prevState, convertCloudToGraphQLObj(rowData)];
      });
      setSelectedList((prevState) => {
        return onlyUnique([...prevState, id]);
      });
      if (isStepEnable) {
        setNewEquipmentIds((prevState) => {
          return onlyUnique([...prevState, id]);
        });
      }
    }

    if (updatedIds) {
      setSelectedList(() => {
        return updatedIds;
      });
      setEquipmentDetailList((prevState) => {
        return [...prevState, convertCloudToGraphQLObj(rowData)];
      });
      setLimitExceedStatus(false);
    }
  };

  // TODO : commented this code as for future use for instrumentDetail info
  // const openInstrumentDetailInfo = async (params) => {
  //   const isBookingControlled = params.data?.is_booking_controlled
  //     ? params.data?.is_booking_controlled[0] === "false"
  //     : false;
  //   const bookableForUsers = params.data?.bookable_for_users
  //     ? params.data?.bookable_for_users[0].includes(storeUser?.user)
  //     : true;
  //   if (
  //     params?.data?.id[0] &&
  //     params?.node?.level === 0 &&
  //     (isBookingControlled || bookableForUsers)
  //   )
  //     history.push("/instrumentDetailedInfo", {
  //       instrument: { inventoryId: params?.data?.id[0] },
  //     });
  // };

  useEffect(() => {
    if (listMessage !== "" && listMessage !== null) {
      if (listMessage === INVALID_BARCODE_MESSAGE) {
        snackbarService.show({
          type: "error",
          message: listMessage,
          duration: DURATION,
        });
      }
      if (listMessage === CAMERA_TIMEOUT_MESSAGE) {
        snackbarService.show({
          type: "info",
          message: listMessage,
          duration: DURATION,
        });
      }
      setListMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMessage]);

  const onClickHandler = () => {
    setLimitExceedStatus(false);
  };
  const handleOnclose = () => {
    setLimitExceedStatus(false);
    return true;
  };

  return (
    <>
      {Object?.keys(attributes)?.length > 0 && (
        <CommonAgGrid
          autoGroupColumnDefObj={autoGroupColumnDef}
          columnData={dataModel}
          masterDataFilters={masterDataFilters}
          userFilters={userFilters}
          myLabFilter={myLabFilter}
          lastFavFilter={InventoryId ? DEFAULT_FILTER : lastFavFilter}
          lastDisplayColumns={lastDisplayColumns ?? DEFAULT_COLUMNS}
          userDisplays={userDisplayColumn}
          systemDisplays={[]}
          onSave={onSave}
          onChipDelete={onChipDelete}
          defaultFilterObj={DEFAULT_FILTER}
          defaultShowColumns={defaultShowColumns}
          listOfFieldsIgnoredForSearch={listOfFieldsIgnoredForSearch}
          listOfNumberFilter={listOfNumberFilter}
          isServerSideGroupObj={isServerSideGroup}
          getServerSideGroupKeyObj={getServerSideGroupKey}
          selectedEquipment={selectedList}
          onResetAll={onResetAll}
          showSaveConfiguration={true}
          limit={lastPageSize ?? DEFAULT_PAGE_SIZE}
          onRowsPerChange={onRowsPerChange}
          requestServer={requestServer}
          siteName={storeUser?.site}
          onRowClicked={(params) => {
            if (
              isEditMode &&
              isOngoing &&
              onGoingEventArray.includes(params?.data?.id[0])
            ) {
              bookingPopUpDispatchAction({
                type: "onGoingBooking",
                payload: {
                  onApprove: () =>
                    bookingPopUpDispatchAction({
                      type: "reset",
                    }),
                },
              });
            }
          }}
          onCheckBoxChange={onCheckBoxSelected}
          isBookingApplication={true}
          equipmentInventoryId={InventoryId}
          onNavigatePath={onNavigatePath}
          {...attributes}
        >
          <span
            name="pagination"
            style={{ display: "flex", alignItems: "center" }}
          >
            <OwcTypography variant="body2" style={{ marginRight: "10px" }}>
              Components added
            </OwcTypography>
            <OwcBadge
              type="active"
              style={{
                marginRight: "15px",
                width: "24px",
                height: "24px",
              }}
            >
              {selectedList?.length}
            </OwcBadge>
          </span>
        </CommonAgGrid>
      )}

      <ConfirmDialog
        approveText="Ok"
        approveColor="primary"
        approveVariant="contained"
        onApprove={onClickHandler}
        title={
          <>
            <OwcIcon
              name="alarm"
              type="outlined"
              style={{
                color: "var(--one-color-yellow-a-300)",
                marginRight: 15,
              }}
            />
            <OwcTypography variant="button">
              Component limit exceeded
            </OwcTypography>
          </>
        }
        content={
          <OwcTypography variant="subtitle1">
            You can select in maximum <strong>50 components.</strong>
          </OwcTypography>
        }
        disableBooking={false}
        fullWidth={false}
        maxWidth="sm"
        open={limitExceedStatus}
        reasonDropdowmLabel={`Review your selected equipment in "What equipment" section.`}
        close={handleOnclose}
      />
    </>
  );
};

export default withApollo(EquipmentMainPage);
