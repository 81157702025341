const PreviousArrowButton = (props) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9929_93846)">
      <g clipPath="url(#clip1_9929_93846)">
        <path
          d="M23.2613 26L24.6613 24.6L20.0612 20L24.6613 15.4L23.2613 14L17.2613 20L23.2613 26Z"
          fill="#737373"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9929_93846">
        <rect
          width="40.5387"
          height="40"
          fill="white"
          transform="translate(40.926 40) rotate(180)"
        />
      </clipPath>
      <clipPath id="clip1_9929_93846">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(32.9259 32) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default PreviousArrowButton;
