import { useState, useCallback, useEffect, useContext } from "react";
import { BookingInstrumentsFilterContext } from "../booking-instruments-filter-context/context";
import { BookingInstrumentsSelectContext } from "./context";

export const compareInstrument = (x, y) => x?.inventoryId === y?.inventoryId;

export const BookingInstrumentsSelectedWrapper = ({
  children,
  selectedInstruments = [],
  resetFilterData = false,
}) => {
  const { filteredData } = useContext(BookingInstrumentsFilterContext);
  const [selected, setSelected] = useState(selectedInstruments);
  const [isAllSelected, setAllSelected] = useState(false);
  const [selectedCount, setSelectedCount] = useState("");

  const onSelect = useCallback((item) => {
    setSelected((prev) => {
      return prev.some((i) => compareInstrument(i, item))
        ? prev.filter((i) => !compareInstrument(i, item))
        : [...prev, item];
    });
  }, []);

  const onAllSelect = useCallback(() => {
    setSelected((prev) =>
      filteredData.every((filteredItem) =>
        prev.some((item) => compareInstrument(item, filteredItem))
      )
        ? []
        : filteredData.map((i) => ({
            inventoryId: i?.inventoryId,
          }))
    );
  }, [filteredData]);

  useEffect(() => {
    if (!resetFilterData) {
      setSelected((prev) =>
        prev.filter((item) =>
          filteredData.some((filteredItem) =>
            compareInstrument(item, filteredItem)
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  const isSelected = useCallback(
    (item) => selected.some((i) => compareInstrument(i, item)),
    [selected]
  );
  const getCount = (count) => {
    if (count > 0) {
      setSelectedCount(`(${selected.length})`);
    } else {
      setSelectedCount("");
    }
  };

  useEffect(() => {
    setAllSelected(selected.length && filteredData.length === selected.length);
  }, [filteredData, selected]);
  useEffect(() => {
    getCount(selected.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const clearSelection = () => {
    setSelected([]);
  };

  const hasAnySelection = selected.length > 0;

  return (
    <BookingInstrumentsSelectContext.Provider
      value={{
        selected,
        onSelect,
        onAllSelect,
        isSelected,
        isAllSelected,
        selectedCount,
        hasAnySelection,
        clearSelection,
      }}
    >
      {children}
    </BookingInstrumentsSelectContext.Provider>
  );
};

export default BookingInstrumentsSelectedWrapper;
