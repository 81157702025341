import CustomChip from "../../components/shared/CustomChip";
import SelectThemeColor from "../../components/shared/SelectThemeColor";
import { Availability } from "../booking-instruments/InstrumentAvailability";

const InstrumentAvailabilityRender = (params) => {
  const getDescription = (instrumentAvailability, bookedBy = "") => {
    switch (instrumentAvailability) {
      case Availability.available:
        return <>{"Available"}</>;
      case Availability.booking:
      case Availability.reservation:
      case Availability.maintenance:
        return (
          <>
            {instrumentAvailability?.[0].toLocaleUpperCase() +
              instrumentAvailability.slice(1)}{" "}
          </>
        );
      default:
        return <>{"Unknown state"}</>;
    }
  };

  const getTextColor = (instrumentAvailability) => {
    switch (instrumentAvailability) {
      case Availability.available:
        return SelectThemeColor.availability.color.available;
      case Availability.booking:
        return SelectThemeColor.availability.color.booking;
      case Availability.reservation:
        return SelectThemeColor.availability.color.reservation;
      case Availability.maintenance:
        return SelectThemeColor.availability.color.maintenance;
      default:
        return SelectThemeColor.availability.color.unknown;
    }
  };
  const getBGColor = (instrumentAvailability) => {
    switch (instrumentAvailability) {
      case Availability.available:
        return SelectThemeColor.availability.background.available;
      case Availability.booking:
        return SelectThemeColor.availability.background.booking;
      case Availability.reservation:
        return SelectThemeColor.availability.background.reservation;
      case Availability.maintenance:
        return SelectThemeColor.availability.background.maintenance;
      default:
        return SelectThemeColor.availability.background.unknown;
    }
  };

  if (
    params.data?.instrument_availability !== undefined &&
    params?.data?.instrument_availability
  ) {
    const statusText = params?.data?.instrument_availability[0].toLowerCase();
    const bookedBy =
      params.data?.booked_by !== undefined && params?.data?.booked_by
        ? params?.data?.booked_by[0]
        : "";
    const desc = getDescription(statusText, bookedBy);
    const textColor = getTextColor(statusText);
    const bgColor = getBGColor(statusText);
    if (statusText) {
      return <CustomChip text={desc} color={textColor} bgColor={bgColor} />;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
export default InstrumentAvailabilityRender;
