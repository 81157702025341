import { useContext, useEffect } from "react";
import styles from "./BookingEquipmentListTab.module.scss";
import { CustomFilterContext } from "../booking-instruments/booking-instruments-custom-filter/context";
import { loadPendingReservations as loadPendingReservationsAction } from "../booking-calendars/redux/actions";
import { connect, useSelector } from "react-redux";
import { compose } from "react-apollo";
import { DEFINE_EXPIREMENT_TEXT, MAX_COUNT_CAPACITY } from "../../constants";

const BookingEquipmentListTab = ({
  tabList,
  handleTabChange,
  setTabList,
  selectedStartDate,
  selectedEndDate,
  setDisableSelection,
  activeTab = 1,
  disabledSelection,
  loadPendingReservations,
  setSelectedStartDate,
  setSelectedEndDate,
  equipmentDetailList,
  setLimitExceedStatus,
}) => {
  const { selectedList } = useContext(CustomFilterContext);
  const { pendingReservations } = useSelector((state) => state.reservations);

  useEffect(() => {
    if (selectedList?.length > 0 && equipmentDetailList.length > 0) {
      tabList[1].isDisable = false;
      tabList[2].isDisable =
        disabledSelection || pendingReservations.length === 0;
    } else if (selectedList?.length === 0) {
      loadPendingReservations([]);
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      tabList[1].isDisable = true;
      tabList[1].content = DEFINE_EXPIREMENT_TEXT;
      handleTabChange(1);
    }
    tabList[0].content = "";

    if (selectedList.length > 0) {
      const equipmentModelList = selectedList.map((item) => {
        return equipmentDetailList.find((x) => x.id === item);
      });
      if (equipmentModelList.length > 0) {
        const modelContent = `${
          equipmentModelList[0]?.equipmentModel.length > 20
            ? equipmentModelList[0]?.equipmentModel.substring(0, 20) + "..."
            : equipmentModelList[0]?.equipmentModel.substring(0, 20)
        }`;

        tabList[0].content = `${modelContent} ${
          selectedList.length > 1 ? "+" + (selectedList.length - 1) : ""
        } `;
      }
    }

    let defaultList = tabList.find((v) => v.id === 1);
    if (
      defaultList !== undefined &&
      defaultList?.content === "" &&
      pendingReservations.length > 0
    ) {
      tabList[2].isDisable = true;
      tabList[1].content = DEFINE_EXPIREMENT_TEXT;
      loadPendingReservations([]);
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }

    if (selectedList.length > MAX_COUNT_CAPACITY) {
      tabList[1].isDisable = true;
      tabList[2].isDisable = true;
      setLimitExceedStatus(true);
    }

    setTabList([...tabList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  useEffect(() => {
    // third tab enable disable based on the date selected

    if (selectedEndDate !== undefined || selectedEndDate !== undefined) {
      tabList[2].isDisable =
        selectedStartDate === null || selectedEndDate === null;

      setTabList([...tabList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);
  return (
    <div>
      <div className={styles.BookingEquipmentListTabWidth}>
        {tabList?.map((item, i) => {
          setDisableSelection(
            item.id === 3 && item.isDisable && !item.isActive
          );
          return (
            <button
              key={item.id}
              onClick={() => handleTabChange(item.id)}
              disabled={item.isDisable}
              style={
                activeTab === item.id
                  ? {
                      backgroundColor:
                        "var(--one-color-interaction-active-brand-4)",
                      color: "var(--one-color-interaction-focus-brand-1)",
                    }
                  : {}
              }
            >
              <p>{item.heading}</p>
              <p>{item.content}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default compose(
  connect(null, {
    loadPendingReservations: loadPendingReservationsAction,
  })
)(BookingEquipmentListTab);
