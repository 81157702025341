const BooleanRender = (params) => {
  if (params?.column?.colId === "is_bookable") {
    return <div>{params?.data?.is_bookable[0]}</div>;
  }
  if (params?.column?.colId === "is_visualized") {
    return <div>{params?.data?.is_visualized[0]}</div>;
  }

  return <div>{"false"}</div>;
};

export default BooleanRender;
