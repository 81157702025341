import React, { useRef } from "react";
import styled from "styled-components";
import {
  CLOUD_RESOURCE_ICONS,
  DEFAULT_IMAGE,
  LOCALHOST,
} from "../../../../constants";
import DefaultInstrument from "../../../../icons/DefaultInstrument.png"; // This is used for local dev

export const IconPreviewStyled = styled.img`
  box-sizing: border-box;
  object-fit: cover;
  display: block;
  max-width: 100px;
  height: 59px;
  display: inline-block;
`;

const InstrumentPicture = React.memo(
  ({ equipmentModel = "", displayImage = "" }) => {
    const defaultImage =
      window.location.hostname === LOCALHOST
        ? DefaultInstrument
        : `${CLOUD_RESOURCE_ICONS}${DEFAULT_IMAGE}`;
    const pictureSrc = displayImage
      ? `${CLOUD_RESOURCE_ICONS}${displayImage}`
      : defaultImage;

    const imgRef = useRef(null);
    return (
      <IconPreviewStyled
        ref={imgRef}
        data-testid={`InstrumentPicture-${equipmentModel}`}
        src={pictureSrc}
        alt={`Picture-${equipmentModel}`}
        onError={() => {
          return (imgRef.current.src = defaultImage);
        }}
      />
    );
  }
);

export default InstrumentPicture;
