import CloudSearchDomain from "aws-sdk/clients/cloudsearchdomain";
import { Auth } from "@aws-amplify/auth";
import AWS from "aws-sdk";
const cloudsearch = new CloudSearchDomain({
  region: process.env.REACT_APP_AWS_PROJECT_REGION,
  endpoint: process.env.REACT_APP_AWS_CLOUD_SEARCH_ENDPOINT,
});

export const CloudSearchConfig = async ({
  start,
  size,
  searchValue,
  sort,
  sortBy,
  queryOptions,
  filterQuery,
}) => {
  AWS.util.update(AWS.CloudSearchDomain.prototype, {
    convertGetToPost: async (request) => {
      const authorizationid = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
      var httpRequest = request.httpRequest;
      var path = httpRequest.path.split("?");
      httpRequest.method = "POST";
      httpRequest.path = path[0];
      httpRequest.body = path[1];
      httpRequest.headers["Content-Length"] = httpRequest.body.length;
      httpRequest.headers["Content-Type"] = "application/x-www-form-urlencoded";
      httpRequest.headers["Access-Control-Allow-Origin"] = "*";
      httpRequest.headers["authorizationid"] = authorizationid;
    },
  });

  try {
    return new Promise((resolve, reject) => {
      let params = {
        query: searchValue || "* -cluster_id:*",
        queryParser: "lucene",
        start,
        size,
        sort: `${sortBy} ${sort}`,
      };

      if (filterQuery) {
        params["filterQuery"] = filterQuery;
      }

      if (queryOptions) {
        params["queryOptions"] = queryOptions;
      }

      cloudsearch.search(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let hits = data?.hits?.hit;
          let count = data?.hits?.found;
          let fields = [];

          hits.forEach((item) => {
            fields.push({ ...item.fields, id: [item?.id] });
          });

          resolve({
            data: fields,
            count: count || 0,
          });
        }
      });
    });
  } catch (error) {
    console.log("API error..", error);
  }
};
