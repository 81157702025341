import React from "react";
import styled from "styled-components";

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
`;

const returnIcon = ({
  icon,
  IconComponent,
  dataTestId,
  iconStyle,
  onClick,
}) => {
  if (icon) {
    return (
      <i
        className="one-icons"
        data-testid={dataTestId || "app-icon-with-text-icon"}
        onClick={onClick}
        style={iconStyle}
      >
        {icon}
      </i>
    );
  } else if (IconComponent) {
    return (
      <div onClick={onClick} style={iconStyle}>
        <IconComponent data-testid={dataTestId} />
      </div>
    );
  } else {
    return null;
  }
};

export const IconWithText = ({
  icon,
  iconStyle = {},
  iconComponent: IconComponent,
  children,
  onClick,
  "data-testid": dataTestId,
}) => {
  return (
    <StyledIcon>
      {returnIcon({ icon, IconComponent, dataTestId, iconStyle, onClick })}
      {children}
    </StyledIcon>
  );
};
