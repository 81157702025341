import React, { useState } from "react";
import styled from "styled-components";
import DropBooking from "./drop-booking/DropBooking";
import BookingAccessControl from "./booking-access-control/BookingAccessControl";
import ReasonContent from "./reasons/ReasonContent";
import DATA_LABEL_TABLE from "../../utils/constants/dataLabelTable";
import { OwcBackdrop, OwcProgressSpinner, OwcTab, OwcTabs } from "@one/react";
import styles from "./AdminTabs.module.scss";
import { useSelector, connect } from "react-redux";
import { getAllBookingInstrumentsList } from "../../utils/helpers/fetching";
import { loadBookingInstruments as loadBookingInstrumentsAction } from "../booking-instruments/redux/actions";
import { compose, withApollo } from "react-apollo";
import { filterBookingData } from "../../utils/helpers/text";
import { BACKDROP_ZINDEX } from "../../constants";

export const AdminTabStyled = styled.div`
  width: 100%;
  padding: 20px 0 20px 0;
  @media only screen and (max-width: 960px) {
    padding: 0 12px;
  }
`;

const AdminTabs = ({
  client,
  loadBookingInstruments,
  setSubscriptionStatus,
  subscriptionStatus,
  setPopupEnableStatus,
}) => {
  const [value, setValue] = useState("dropBooking");
  const [fetching, setFetching] = useState(false);
  const handleChange = (e) => {
    setValue(e.detail);
  };

  const user = useSelector((state) => state.user);
  const getBookingItems = async () => {
    const allBookingEquipmentList = await getAllBookingInstrumentsList({
      client,
      user,
    });

    const filteredBookingEquipmentList = filterBookingData(
      allBookingEquipmentList,
      user?.user
    );
    loadBookingInstruments({
      bookingInstruments: filteredBookingEquipmentList.map((instrument) => ({
        ...instrument,
        instrumentAvailability:
          instrument?.addInfo?.instrumentAvailability?.toLowerCase() ?? null,
      })),
    });
    setFetching(false);
  };

  return (
    <div className={styles.contentTabs}>
      <OwcTabs
        value={value}
        onTabChange={handleChange}
        style={{ backgroundColor: "var(--one-color-cobas-white)" }}
      >
        <OwcTab
          data-testid="admin-tab-drop-booking"
          label={DATA_LABEL_TABLE.ADMIN_TABS.drop_booking}
          value="dropBooking"
        >
          {DATA_LABEL_TABLE.ADMIN_TABS.drop_booking}
        </OwcTab>
        <OwcTab
          data-testid="admin-tab-widgets"
          label={DATA_LABEL_TABLE.ADMIN_TABS.reason_management}
          value="reasonManagement"
        >
          {DATA_LABEL_TABLE.ADMIN_TABS.reason_management}
        </OwcTab>
        <OwcTab
          data-testid="admin-tab-reason-management"
          label={DATA_LABEL_TABLE.ADMIN_TABS.booking_access_control}
          value="accessControl"
        >
          {DATA_LABEL_TABLE.ADMIN_TABS.booking_access_control}
        </OwcTab>
      </OwcTabs>

      {fetching ? (
        <OwcBackdrop opacity={0.3} style={{ zIndex: BACKDROP_ZINDEX }}>
          <OwcProgressSpinner />
        </OwcBackdrop>
      ) : (
        <>
          {value === "dropBooking" && (
            <DropBooking
              setSubscriptionStatus={setSubscriptionStatus}
              subscriptionStatus={subscriptionStatus}
              setPopupEnableStatus={setPopupEnableStatus}
            />
          )}
          {value === "reasonManagement" && <ReasonContent />}
          {value === "accessControl" && (
            <BookingAccessControl getBookingItems={getBookingItems} />
          )}
        </>
      )}
    </div>
  );
};

//export default AdminTabs;
export default compose(
  connect(null, {
    loadBookingInstruments: loadBookingInstrumentsAction,
  }),
  withApollo
)(AdminTabs);
