export const selectFavourites = (allApps, applicationSets) => {
  const arrOfAllApps = Object.keys(allApps).map((key) => allApps[key]);
  const setFavs = Array.isArray(applicationSets)
    ? applicationSets.filter((app) => app.name === "favourites")
    : [];
  if (setFavs.length === 0) {
    return [];
  }
  const favs = Array.isArray(setFavs[0]?.applicationReferences)
    ? setFavs[0]?.applicationReferences
    : [];
  return favs
    .map((f) => ({
      ...arrOfAllApps.find((a) => a.id === f.applicationID),
      fav: { ...f },
      entryPointSelected: f?.applicationEntryPointID,
    }))
    .filter((v) => v.id);
};
