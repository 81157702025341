import React, { useCallback, useReducer, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import CustomDatePicker from "../../components/shared/DatePicker";
import { OwcButton, OwcModalDialog, OwcTextarea } from "@one/react";
import { DialogContentText } from "@mui/material";

const DatesStyled = styled.h6`
  display: flex;
  & > * {
    flex: 1 1 50%;
    margin: 0;
    box-sizing: border-box;
  }
`;

const CHARACTERS_LIMIT = 300;
const SHORT_CHARACTERS_LIMIT = 100;

export const initalState = {
  dateFrom: new Date().toISOString(),
  dateTo: null,
  message: "",
  shortDescription: "Equipment change affecting my booking",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "dateFrom":
      return { ...state, dateFrom: action.payload };
    case "dateTo":
      return { ...state, dateTo: action.payload };
    case "message":
      return { ...state, message: action.payload };
    case "shortDescription":
      return { ...state, shortDescription: action.payload };
    case "clearAll":
      return {
        ...initalState,
      };
    default:
      return state;
  }
};

const DialogContentInputs = ({
  state,
  setDateCallback,
  loading,
  handleChange,
  classes,
}) => {
  return (
    <DialogContentText data-testid="booking-notification-modal-content-text">
      <DatesStyled>
        <CustomDatePicker
          date={state?.dateFrom}
          data-testid="datepicker-from-booking-notification"
          id="datepicker-from-booking-notification"
          setDate={setDateCallback("dateFrom")}
          label="Date from"
          disabled={loading}
          minDate={new Date().toISOString()}
          maxDate={state?.dateTo || moment().add(6, "months").toISOString()}
        />
        <CustomDatePicker
          date={state?.dateTo}
          data-testid="datepicker-to-booking-notification"
          id="datepicker-to-booking-notification"
          setDate={setDateCallback("dateTo")}
          label="Date to"
          disabled={loading}
          minDate={state?.dateFrom}
        />
      </DatesStyled>
      <NotificationInputFiled
        loading={loading}
        state={state}
        handleChange={handleChange}
        shortName="shortDescription"
        dataTestidLabel="short-description"
        label="Short description"
        charactersLimit={SHORT_CHARACTERS_LIMIT}
      />
      <NotificationInputFiled
        loading={loading}
        state={state}
        handleChange={handleChange}
        shortName="message"
        dataTestidLabel="message"
        label="Message"
        charactersLimit={CHARACTERS_LIMIT}
        rows={3}
        maxrows={4}
      />
    </DialogContentText>
  );
};

const DialogActionComponent = ({
  handleClose,
  onCancel,
  requiredFilled,
  loading,
  onSave,
  state,
}) => {
  return (
    <div slot="actions" style={{ display: "flex", justifyContent: "right" }}>
      <OwcButton
        variant="secondary"
        onClick={() => {
          handleClose();
          if (onCancel) {
            onCancel();
          }
        }}
        data-testid="booking-notification-modal-cancel-button"
        style={{ marginRight: 32 }}
      >
        Cancel
      </OwcButton>
      <OwcButton
        variant="primary"
        data-testid="booking-notification-modal-send-button"
        disabled={!requiredFilled || loading}
        onClick={() => {
          onSave({ ...state });
        }}
      >
        Send notification
      </OwcButton>
    </div>
  );
};

function handleCloseFactory(dispatch, close) {
  return () => {
    dispatch({
      type: "clearAll",
    });
    close();
  };
}

function handleChangeFactory(dispatch) {
  return (type) => (event) => {
    const value = event.target.value;
    if (value.length && !value.trim()) return;
    dispatch({
      type,
      payload: value,
    });
  };
}

const NotificationInputFiled = ({
  loading,
  state,
  handleChange,
  classes,
  shortName,
  dataTestidLabel,
  label,
  charactersLimit,
  ...props
}) => {
  return (
    <>
      <OwcTextarea
        data-testid={`input-for-notification-${dataTestidLabel}`}
        variant="filled"
        id={shortName}
        name={shortName}
        disabled={loading}
        value={state?.[shortName]}
        onInputChange={handleChange(shortName)}
        label={label}
        maxLength={charactersLimit}
        style={{ width: "100%" }}
        {...props}
        cols={18}
        rows={3}
      />
    </>
  );
};

const NotificationSendModal = ({
  open,
  close,
  onSave,
  onCancel,
  loading = false,
  item,
}) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const handleClose = handleCloseFactory(dispatch, close);
  useEffect(() => {
    if (!item) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const handleChange = handleChangeFactory(dispatch);
  const setDateCallback = useCallback(
    (key) => (value) => {
      dispatch({
        type: key,
        payload: moment(value).toISOString(),
      });
    },
    [dispatch]
  );
  const requiredFilled =
    state?.message?.length && state?.shortDescription?.length;
  return (
    <OwcModalDialog
      visible={open}
      data-testid="booking-notification-modal"
      className="owcmodalZIndex"
    >
      <div slot="header" data-testid="booking-notification-modal-title">
        Send notification
      </div>
      <div data-testid="booking-notification-modal-content">
        <DialogContentInputs
          state={state}
          setDateCallback={setDateCallback}
          loading={loading}
          handleChange={handleChange}
        />
      </div>
      <DialogActionComponent
        handleClose={handleClose}
        onCancel={onCancel}
        requiredFilled={requiredFilled}
        loading={loading}
        onSave={onSave}
        state={state}
      />
    </OwcModalDialog>
  );
};

export default NotificationSendModal;
