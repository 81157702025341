import { addMonths, subMonths, format } from "date-fns";
import { useContext } from "react";
import NextPreviousSwitcherUi from "../../../../components/NextPreviousSwitcherUi";
import { CalendarTypeContext } from "../../../booking-calendars/calendar-type-context/context";

const MonthSwitcher = () => {
  const { date, setDate } = useContext(CalendarTypeContext);
  const showDayOrWeek = action => {
    setDate(action(new Date(date), 1));
  };
  const dayOfWeekText = format(date, "MMMM") + " ";
  const restText = format(date, "yyyy");
  return (
    <NextPreviousSwitcherUi
      onNextClick={() => {
        showDayOrWeek(addMonths);
      }}
      onPrevClick={() => {
        showDayOrWeek(subMonths);
      }}
      dayOfWeekText={dayOfWeekText}
      restText={restText}
      dataTestIdForTextOfCalendar={"next-previous-switcher-date-info-by-month"}
    />
  );
};

export default MonthSwitcher;
