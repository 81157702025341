import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "styled-components";
import { useContext } from "react";
import { WidgetContext } from "../contexts";
import { CALENDAR_WIDGET } from "../constants";
import NextArrowButton from "../icons/NextArrowButton";
import PreviousArrowButton from "../icons/PreviousArrowButton";
import useStyle from "../utils/hooks/useStyle";
import moment from "moment";
import { IconButton } from "@mui/material";

const DayOfWeekStyled = styled.span`
  font-weight: 500;
`;
const DayDateStyled = styled.span`
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  margin-left: 16px;
`;

const DayDateStyledHighlight = styled.span`
  font-size: var(--one-text-font-size-px-16);
  line-height: 24px;
  font-weight: 400;
  margin-left: 16px;
  display: flex;
  flex-direction: inherit;
  background-color: var(--one-color-background-brand-1);
  color: var(--one-color-cobas-white);
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
`;

const CWIconButton = styled(IconButton)`
  root: {
    padding: 0;
    padding-top: 11px;
    padding-bottom: 5px;
  }
`;

const NextPreviousSwitcherStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CWDayOfWeekStyled = styled.span`
  color: var(--one-color-gray-600);
  font-family: var(--one-text-font-family-default);
  font-style: normal;
  font-weight: var(--one-text-font-weight-500);
  font-size: var(--one-text-font-size-px-16);
  line-height: 16px;
  padding-top: 7px;
`;

const NextPreviousSwitcherUi = ({
  onPrevClick,
  onNextClick,
  dayOfWeekText,
  restText,
  dataTestIdForTextOfCalendar,
}) => {
  const { widgetName } = useContext(WidgetContext);
  const currentDate = moment().format("DD-MMM-YYYY");
  const CustomDateStyled = (() => {
    switch (widgetName) {
      case CALENDAR_WIDGET:
        return (
          <CWDayOfWeekStyled data-testid={dataTestIdForTextOfCalendar}>
            {dayOfWeekText}
            {restText}
          </CWDayOfWeekStyled>
        );
      default:
        return (
          <>
            {currentDate === restText.slice(3) ? (
              <DayDateStyledHighlight data-testid={dataTestIdForTextOfCalendar}>
                <DayOfWeekStyled>{dayOfWeekText}</DayOfWeekStyled>
                {restText}
              </DayDateStyledHighlight>
            ) : (
              <DayDateStyled data-testid={dataTestIdForTextOfCalendar}>
                <DayOfWeekStyled>{dayOfWeekText}</DayOfWeekStyled>
                {restText}
              </DayDateStyled>
            )}
          </>
        );
    }
  })();

  const PreviousArrowBtn = (() => {
    switch (widgetName) {
      case CALENDAR_WIDGET:
        return (
          <PreviousArrowButton data-testid="next-previous-switcher-previous-icon" />
        );
      default:
        return (
          <ArrowBackIosIcon data-testid="next-previous-switcher-previous-icon" />
        );
    }
  })();
  const NextArrowBtn = (() => {
    switch (widgetName) {
      case CALENDAR_WIDGET:
        return (
          <NextArrowButton data-testid="next-previous-switcher-next-icon" />
        );
      default:
        return (
          <ArrowForwardIosIcon data-testid="next-previous-switcher-previous-icon" />
        );
    }
  })();

  const CustomIconButton = useStyle({
    widgetName,
    widgetStyle: CWIconButton,
    defaultStyle: IconButton,
  });
  return (
    <NextPreviousSwitcherStyled data-testid="next-previous-switcher-box">
      <CustomIconButton
        data-testid="next-previous-switcher-previous-button"
        onClick={onPrevClick}
      >
        {PreviousArrowBtn}
      </CustomIconButton>
      <CustomIconButton
        data-testid="next-previous-switcher-next-button"
        onClick={onNextClick}
      >
        {NextArrowBtn}
      </CustomIconButton>
      {CustomDateStyled}
    </NextPreviousSwitcherStyled>
  );
};

export default NextPreviousSwitcherUi;
