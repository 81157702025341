import gql from "graphql-tag";

export const UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE = gql`
  mutation updateDigitalLabBookingUserProfile(
    $id: ID!
    $email: String!
    $lastFilter: String!
  ) {
    updateDigitalLabBookingUserProfile(
      input: { id: $id, email: $email, lastFilter: $lastFilter }
    ) {
      id
      email
      lastFilter
    }
  }
`;

export const UPDATE_DIGITAL_LAB_BOOKING_USER_SEARCH_PREFERENCES = gql`
  mutation updateDigitalLabBookingUserProfile(
    $id: ID!
    $email: String!
    $lastFilter: String!
    $searchPreference: String
  ) {
    updateDigitalLabBookingUserProfile(
      input: {
        id: $id
        email: $email
        lastFilter: $lastFilter
        searchPreference: $searchPreference
      }
    ) {
      id
      email
      lastFilter
      searchPreference
    }
  }
`;

export const CREATE_DIGITAL_LAB_BOOKING_USER_PROFILE = gql`
  mutation createDigitalLabBookingUserProfile(
    $email: String!
    $lastFilter: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
  ) {
    createDigitalLabBookingUserProfile(
      input: {
        email: $email
        lastFilter: $lastFilter
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
      }
    ) {
      id
      email
      lastFilter
      status
      familyName
      givenName
      name
      user
      phone
    }
  }
`;

export const CREATE_BOOKING_COMMAND = gql`
  mutation createBookingCommand(
    $createdBy: String!
    $createdByEmail: String!
    $createdByName: String!
    $processingResult: BookingCommandProcessingResult
    $entries: [RequestedDeviceEntryInput!]!
    $type: String!
    $dateFrom: AWSDateTime!
    $dateTo: AWSDateTime!
    $reservedForUsers: [String]!
    $reservedForEmails: [String]!
    $site: String
    $description: String!
    $project: String
  ) {
    createBookingCommand(
      input: {
        commandType: BOOKING_REQUEST
        createdBy: $createdBy
        createdByEmail: $createdByEmail
        createdByName: $createdByName
        processingResult: $processingResult
        description: $description
        project: $project
        entries: $entries
        site: $site
        type: $type
        dateFrom: $dateFrom
        dateTo: $dateTo
        reservedForUsers: $reservedForUsers
        reservedForEmails: $reservedForEmails
      }
    ) {
      id
      entries {
        allDay
        bookingEntryId
        dateFrom
        dateTo
        inventoryId
        remark
        processingResult
      }
      commandType
      createdBy
      createdByEmail
      createdByName
      processingResult
      expiresAt
      reservedForEmails
      reservedForUsers
      site
      type
      description
      project
      coarseProcessingResult
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_BOOKING = gql`
  mutation createBookingCommand(
    $createdBy: String!
    $createdByEmail: String!
    $createdByName: String!
    $processingResult: BookingCommandProcessingResult
    $entries: [RequestedDeviceEntryInput!]!
    $type: String!
    $bookingId: String
    $remark: String
    $dateFrom: AWSDateTime!
    $dateTo: AWSDateTime!
    $reservedForUsers: [String]!
    $reservedForEmails: [String]!
    $site: String
    $description: String!
    $project: String
    $force: Boolean
  ) {
    createBookingCommand(
      input: {
        commandType: UPDATE_REQUEST
        createdBy: $createdBy
        createdByEmail: $createdByEmail
        createdByName: $createdByName
        processingResult: $processingResult
        description: $description
        project: $project
        remark: $remark
        bookingId: $bookingId
        entries: $entries
        site: $site
        type: $type
        dateFrom: $dateFrom
        dateTo: $dateTo
        reservedForUsers: $reservedForUsers
        reservedForEmails: $reservedForEmails
        force: $force
      }
    ) {
      id
      entries {
        allDay
        bookingEntryId
        dateFrom
        dateTo
        inventoryId
        remark
        processingResult
      }
      commandType
      createdBy
      createdByEmail
      createdByName
      processingResult
      expiresAt
      reservedForEmails
      reservedForUsers
      remark
      site
      type
      description
      project
      coarseProcessingResult
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation MyMutation(
    $createdBy: String!
    $createdByEmail: String!
    $createdByName: String!
    $processingResult: BookingCommandProcessingResult
    $entries: [RequestedDeviceEntryInput!]!
    $force: Boolean
  ) {
    createBookingCommand(
      input: {
        commandType: CANCEL_REQUEST
        createdBy: $createdBy
        createdByEmail: $createdByEmail
        createdByName: $createdByName
        processingResult: $processingResult
        entries: $entries
        force: $force
      }
    ) {
      entries {
        inventoryId
        processingResult
        type
        description
        project
        reservedForUser
        reservedForEmail
        reservedOnMorning
        reservedOnAfternoon
        dateFrom
        dateTo
        bookingId
        remark
        site
      }
      id
      createdByName
      createdByEmail
      createdBy
      createdAt
      processingResult
    }
  }
`;

export const CREATE_BOOKING_NOTIFICATION_COMMAND = gql`
  mutation createBookingNotificationCommand(
    $site: String!
    $createdBy: String!
    $createdByEmail: String!
    $dateFrom: AWSDateTime!
    $dateTo: AWSDateTime
    $body: String
    $inventoryId: String!
    $secondaryID: String!
    $secondaryIDMapping: String!
  ) {
    createBookingNotificationCommand(
      input: {
        site: $site
        createdBy: $createdBy
        createdByEmail: $createdByEmail
        dateFrom: $dateFrom
        dateTo: $dateTo
        body: $body
        inventoryId: $inventoryId
        secondaryID: $secondaryID
        secondaryIDMapping: $secondaryIDMapping
      }
    ) {
      id
      materialNumber
      serialNumber
      secondaryID
      secondaryIDMapping
      inventoryId
      site
      body
      dateFrom
      dateTo
      createdBy
      createdByEmail
      status
      expiresAt
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS = gql`
  mutation updateDigitalLabBookingUserProfile(
    $id: ID!
    $email: String!
    $lastFilter: String
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
    $lastDisplayColumns: String
    $lastPageSize: String
  ) {
    updateDigitalLabBookingUserProfile(
      input: {
        id: $id
        email: $email
        lastFilter: $lastFilter
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
        lastDisplayColumns: $lastDisplayColumns
        lastPageSize: $lastPageSize
      }
    ) {
      id
      email
      lastFilter
      status
      site
      familyName
      givenName
      name
      user
      phone
    }
  }
`;

export const CREATE_BOOKING_REASON_MASTER = gql`
  mutation createReasonMaster($configText: String!) {
    createBookingConfigurableMaster(input: { configText: $configText }) {
      id
      configText
    }
  }
`;

export const UPDATE_BOOKING_REASON_MASTER = gql`
  mutation MyMutation($configText: String!, $id: ID!) {
    updateBookingConfigurableMaster(
      input: { configText: $configText, id: $id }
    ) {
      id
      configText
    }
  }
`;

export const DELETE_BOOKING_REASON_MASTER = gql`
  mutation MyMutation($id: ID!) {
    deleteBookingConfigurableMaster(
      input: { id: $id, modelName: "BOOKINGCANCELREASON" }
    ) {
      id
      configText
    }
  }
`;

export const CREATE_BOOKING_GROUP_CONFIGURATION = gql`
  mutation createBookingGroupConfiguration(
    $users: [CreateDigitalLabBookingUserInput]!
    $groupName: String!
  ) {
    createBookingGroupConfiguration(
      input: {
        users: $users
        groupName: $groupName
        modelName: "BOOKINGGROUPCONFIG"
      }
    ) {
      groupName
      modelName
      users {
        email
        familyName
        givenName
        name
        userId
      }
    }
  }
`;

export const UPDATE_BOOKING_GROUP_CONFIGURATION = gql`
  mutation updateBookingGroupConfiguration(
    $users: [CreateDigitalLabBookingUserInput]!
    $groupName: String!
  ) {
    updateBookingGroupConfiguration(
      input: {
        users: $users
        groupName: $groupName
        modelName: "BOOKINGGROUPCONFIG"
      }
    ) {
      groupName
      modelName
      users {
        email
        familyName
        givenName
        name
        userId
      }
    }
  }
`;
export const CREATE_BOOKING_ACCESS_CONFIGURATION = gql`
  mutation createBookingAccessConfiguration(
    $modelName: String!
    $groupName: String!
    $inventoryId: String!
  ) {
    createBookingAccessConfiguration(
      input: {
        modelName: $modelName
        inventoryId: $inventoryId
        groupName: $groupName
      }
    ) {
      groupName
    }
  }
`;

export const UPDATE_BOOKING_ACCESS_CONFIGURATION = gql`
  mutation updateBookingAccessConfiguration(
    $modelName: String!
    $groupName: String!
    $inventoryId: String!
    $id: ID!
  ) {
    updateBookingAccessConfiguration(
      input: {
        modelName: $modelName
        groupName: $groupName
        inventoryId: $inventoryId
        id: $id
      }
    ) {
      inventoryId
      modelName
      groupName
      id
    }
  }
`;

export const DELETE_BOOKING_ACCESS_CONFIGURATION = gql`
  mutation deleteBookingAccessConfiguration(
    $modelName: String!
    $groupName: String!
  ) {
    deleteBookingAccessConfiguration(
      input: { modelName: $modelName, groupName: $groupName }
    ) {
      createdAt
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LP_USER_PROFILE_FILTER = gql`
  mutation updateDigitalLabLandingPageUserProfile(
    $id: ID!
    $email: String!
    $lastFilter: String!
  ) {
    updateDigitalLabLandingPageUserProfile(
      input: { id: $id, email: $email, lastFilter: $lastFilter }
    ) {
      id
      email
      lastFilter
    }
  }
`;
export const CREATE_USER_FILTER = gql`
  mutation createUserFilter(
    $userID: ID!
    $filterName: String!
    $filterDefinition: String
  ) {
    createUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
        filterModel: "EQUIPMENTLIST"
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const UPDATE_USER_FILTER = gql`
  mutation updateUserFilter(
    $userID: ID!
    $filterName: String!
    $filterDefinition: String
  ) {
    updateUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
        filterModel: "EQUIPMENTLIST"
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;
export const CREATE_USER_DISPLAY_COLUMN = gql`
  mutation createUserDisplay(
    $userID: ID!
    $displayName: String!
    $displayDefinition: String!
  ) {
    createUserDisplay(
      input: {
        userID: $userID
        displayName: $displayName
        displayDefinition: $displayDefinition
        displayModel: "EQUIPMENTLIST"
      }
    ) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;

export const UPDATE_USER_DISPLAY_COLUMN = gql`
  mutation updateUserDisplay(
    $userID: ID!
    $displayName: String!
    $displayDefinition: String
  ) {
    updateUserDisplay(
      input: {
        userID: $userID
        displayName: $displayName
        displayDefinition: $displayDefinition
        displayModel: "EQUIPMENTLIST"
      }
    ) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;
export const DELETE_USER_DISPLAY_COLUMN = gql`
  mutation deleteUserDisplay($userID: ID!, $displayName: String!) {
    deleteUserDisplay(
      input: {
        userID: $userID
        displayName: $displayName
        displayModel: "EQUIPMENTLIST"
      }
    ) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;
export const DELETE_USER_FILTER = gql`
  mutation deleteUserFilter($userID: ID!, $filterName: String!) {
    deleteUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterModel: "EQUIPMENTLIST"
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const DROP_BOOKING = gql`
  mutation MyMutation2(
    $createdBy: String!
    $bookingId: String!
    $force: Boolean
    $remark: String
  ) {
    cancelBookingCommand(
      input: {
        commandType: CANCEL_REQUEST
        createdBy: $createdBy
        force: $force
        bookingId: $bookingId
        remark: $remark
      }
    ) {
      commandType
    }
  }
`;
