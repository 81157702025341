const SelectThemeColor = {
  indicator: {
    normal: "#0066CC",
    error: "#CC0033",
    service: "#FFC414",
    unknown: "#737373",
  },
  availability: {
    color: {
      available: "rgba(0,135,90,1)",
      reservation: "rgba(0,102,204,1)",
      booking: "rgba(111,5,117,1)",
      maintenance: "rgba(135,101,0,1)",
      mixed: "#333333",
      mixedLight: "#737373",
      unknown: "#000000",
      qualified: "rgba(0,135,90,1)",
      not_qualified: "rgb(204, 0, 51)",
      calibrated: "rgba(0,135,90,1)",
      not_calibrated: "rgb(204, 0, 51)",
      NA: "#000000",
    },
    background: {
      available: "rgba(0,135,90,0.1)",
      reservation: "rgba(0,102,204,0.1)",
      booking: "rgba(111,5,117,0.1)",
      maintenance: "rgba(255,196,20,1)",
      mixed: "#EFEFEF",
      unknown: "rgba(176,176,176,0.6)",
      maintenanceLight: "rgba(255,196,20,0.1)",
      qualified: "rgba(0,135,90,0.1)",
      not_qualified: "rgba(204, 0, 51, 0.1)",
      calibrated: "rgba(0,135,90,0.1)",
      not_calibrated: "rgba(204, 0, 51, 0.1)",
      NA: "transparent",
    },
  },
};

export default SelectThemeColor;
