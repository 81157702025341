import React, { useReducer } from "react";
import { BookingPopUpContext } from "./context";
import { BOOKING_POP_UP_WRAPPER_INITALIZE } from "../../constants";
import { ConfirmDialog } from "../../components/shared";
import { OwcIcon, OwcTypography } from "@one/react";

export const initialState = BOOKING_POP_UP_WRAPPER_INITALIZE;
const warningIcon = () => (
  <OwcIcon
    type="outlined"
    name="alarm"
    style={{
      color: "var(--one-color-yellow-a-300)",
    }}
  />
);

export const warningMessageEquipment = () => (
  <>
    {warningIcon()}
    <OwcTypography variant="button" style={{ paddingLeft: 5 }}>
      Can't remove equipment
    </OwcTypography>
  </>
);

export const warningMessageEvent = () => (
  <>
    {warningIcon()}
    <OwcTypography variant="button" style={{ paddingLeft: 5 }}>
      Can't remove time slot
    </OwcTypography>
  </>
);

export function reducer(
  state = initialState,
  action = { type: "", payload: {} }
) {
  switch (action.type) {
    case "futureBooking":
      return {
        ...state,
        ...action.payload,
        ...{
          title: "Confirm equipment to remove",
          content: "Are you sure you want to remove this equipment?",
          open: true,
          cancelText: "Cancel",
          approveText: "Remove",
        },
      };

    case "onGoingBooking":
      return {
        ...state,
        ...action.payload,
        ...{
          title: warningMessageEquipment(),
          content:
            "This equipment can't be removed because it is associated with a date/time in the past.",
          open: true,
          approveText: "Ok",
        },
      };
    case "eventBooking":
      return {
        ...state,
        ...action.payload,
        ...{
          open: true,
          approveText: "Ok",
        },
      };
    case "cancelEvents":
      return {
        ...state,
        ...action.payload,
        ...{
          title: warningMessageEvent(),
          content: `This equipment time slot can't be removed because it is associated with a
                    date/time in the past. You can adjust the time slot only.`,
          open: true,
          approveText: "Ok",
        },
      };

    case "reset":
      return {
        ...BOOKING_POP_UP_WRAPPER_INITALIZE,
      };

    default:
      return state;
  }
}

const BookingPopUpWrapper = ({ children, initialValues }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues,
  });

  const handleOnclose = () => {
    if (typeof state.onclose === "function") {
      state.onclose();
    }
  };

  const handleOnApprove = () => {
    if (typeof state.onApprove === "function") {
      state.onApprove();
    }
  };

  return (
    <BookingPopUpContext.Provider
      value={{
        ...state,
        dispatchAction,
      }}
    >
      {children}
      <ConfirmDialog
        approveText={state.approveText}
        approveColor="primary"
        approveVariant="contained"
        cancelText={state.cancelText}
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={handleOnApprove}
        title={state.title}
        content={state.content}
        disableBooking={false}
        close={handleOnclose}
        open={state.open}
        onCancel={handleOnclose}
        fullWidth={false}
        maxWidth="sm"
      />
    </BookingPopUpContext.Provider>
  );
};

export default BookingPopUpWrapper;
