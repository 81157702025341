import React from "react";
import { connect } from "react-redux";
import { OwcButton, OwcModalDialog } from "@one/react";
import { Divider } from "@mui/material";

export const ConfirmDialogReason = ({
  isReasonUpdate = false,
  testid = "confirm-dialog",
  title = "",
  content = "",
  cancelText,
  approveText,
  cancelVariant,
  cancelColor,
  classes = {},
  approveColor,
  approveVariant,
  onCancel,
  onApprove,
  close,
  open,
  children,
  renderActions,
  isDivider = false,
}) => {
  const handleCancel = () => {
    close();
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <OwcModalDialog
      visible={open}
      onClose={handleCancel}
      data-testid={testid}
      classes={classes}
      fullWidth={!isReasonUpdate}
      className="owcmodalZIndex"
    >
      <div slot="header" data-testid="confirm-dialog-title">
        {title}
      </div>
      {isDivider && <Divider data-testid="divider" />}
      {!isReasonUpdate && (
        <div data-testid="confirm-dialog-content">
          {children ? (
            children
          ) : (
            <div
              data-testid="confirm-dialog-content-text"
              s
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              {content}
            </div>
          )}
        </div>
      )}

      <div
        slot="actions"
        data-testid="confirm-dialog-actions"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {typeof renderActions === "function" ? (
          renderActions({
            cancelColor,
            cancelVariant,
            approveColor,
            approveVariant,
            cancelText,
            approveText,
            close,
          })
        ) : (
          <>
            {cancelText && (
              <OwcButton
                data-testid="confirm-dialog-actions-button-cancel"
                onClick={handleCancel}
                variant="secondary"
                style={{ marginLeft: 24 }}
              >
                {cancelText || null}
              </OwcButton>
            )}
          </>
        )}
      </div>
    </OwcModalDialog>
  );
};
const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail,
});
export default connect(mapStateToProps, null)(ConfirmDialogReason);
