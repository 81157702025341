import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { changeDateFormat, convertToSnakeCase } from "../../utils/helpers/text";

const DateRender = (params) => {
  if (
    params?.column?.colId ===
      convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextMaintanance?.key) &&
    params?.data[
      convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextMaintanance?.key)
    ]
  ) {
    return (
      <div>
        {changeDateFormat(
          params?.data[
            convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextMaintanance?.key)
          ][0],
          "DD-MMM-YYYY"
        )}
      </div>
    );
  }
  if (
    params?.column?.colId ===
      convertToSnakeCase(DATA_MODEL_TABLE?.dateOfLastMaintanance?.key) &&
    params?.data[
      convertToSnakeCase(DATA_MODEL_TABLE?.dateOfLastMaintanance?.key)
    ]
  ) {
    return (
      <div>
        {changeDateFormat(
          params?.data[
            convertToSnakeCase(DATA_MODEL_TABLE?.dateOfLastMaintanance?.key)
          ][0],
          "DD-MMM-YYYY"
        )}
      </div>
    );
  }
  if (
    params?.column?.colId ===
      convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key) &&
    params?.data[
      convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key)
    ]
  ) {
    return (
      <div>
        {changeDateFormat(
          params?.data[
            convertToSnakeCase(DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key)
          ][0],
          "DD-MMM-YYYY"
        )}
      </div>
    );
  }
  return <div>-</div>;
};

export default DateRender;
