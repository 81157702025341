import React from "react";
import GroupBookingMainPage from "./groups/GroupBookingMainPage";
import GroupMainPage from "./assign-group/GroupMainPage";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { OwcExpandable, OwcExpandableGroup } from "@one/react";

const BookingAccessControl = ({
  client,
  loadBookingInstruments,
  getBookingItems,
}) => {
  const [expanded, setExpanded] = React.useState("createGroupAccordion");

  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Admin Panel/Booking access control",
      href: `${window.location?.origin}/AdminPanel/BookingAccessControl`,
    });
    getBookingItems();
    // eslint-disable-next-line
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <OwcExpandableGroup>
        <OwcExpandable
          variant="standard"
          round
          expanded={expanded === "createGroupAccordion"}
          onStatusChanged={handleChange("createGroupAccordion")}
        >
          <span slot="title">Create booking group </span>
          <span
            slot="content"
            style={{
              minHeight: "300px",
              height: "fit-content",
              overflowY: "scroll",
            }}
          >
            <GroupBookingMainPage />
          </span>
        </OwcExpandable>

        <OwcExpandable
          variant="standard"
          round
          expanded={expanded === "assignGroupAccordion"}
          onChange={handleChange("assignGroupAccordion")}
        >
          <span slot="title">Assign group to equipment</span>

          <span
            slot="content"
            style={{
              minHeight: "300px",
              height: "fit-content",
              overflowY: "scroll",
            }}
          >
            <GroupMainPage />
          </span>
        </OwcExpandable>
      </OwcExpandableGroup>
    </>
  );
};

export default BookingAccessControl;
