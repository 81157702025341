import React, { useEffect } from "react";
import ConfirmDialogReason from "../../../components/shared/ConfirmDialogReason";
import styled from "styled-components";
import { OwcButton } from "@one/react";
export const CreateFieldStyled = styled.div`
  display: flex;
  justify-content: left;
  padding-top: 8px;
  padding-bottom: 32px;
  & > .selectBox {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 100%;
  }
`;

const DeleteReasonDialog = ({
  open,
  onCancel,
  deleteReason,
  disableReason,
  setDisableReason,
}) => {
  useEffect(() => {
    setDisableReason(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfirmDialogReason
      open={open}
      close={onCancel}
      approveText={"Delete reason"}
      approveColor="primary"
      approveVariant="primary"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={onCancel}
      testid={"import-file-dialog"}
      title={"Delete reason"}
      renderActions={({ cancelText, approveText }) => (
        <div style={{ paddingBottom: 8, display: "flex" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={onCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={() => {
              deleteReason();
            }}
            variant="primary"
            disabled={disableReason}
          >
            {approveText}
          </OwcButton>
        </div>
      )}
    >
      <CreateFieldStyled>Do you want to delete the reason?</CreateFieldStyled>
    </ConfirmDialogReason>
  );
};

export default DeleteReasonDialog;
