import React, { useState } from 'react';
import { ReasonContext } from './context';

const ReasonWapper = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [showReasons, setShowReasons] = useState(false);
  const [currentReason, setCurrentReason] = useState({});

  return (
    <ReasonContext.Provider
      value={{
        dialogOpen,
        setDialogOpen,
        isEditMode,
        setIsEditMode,
        currentReason,
        setCurrentReason,
        showReasons,
        setShowReasons,
        isDeleteMode,
        setIsDeleteMode,
      }}
    >
      {children}
    </ReasonContext.Provider>
  );
};

export default ReasonWapper;
