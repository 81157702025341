import "./App.scss";
import "react-big-calendar/lib/sass/styles.scss";
import AppBar from "./views/AppBar";
import { StyledToastContainer } from "./features/notifications/Notify";
import "react-toastify/dist/ReactToastify.css";
import LoadBookingInstrumentsInfo from "./features/booking-instruments/LoadBookingInstrumentsInfo";
import BookingInstrumentScanMainPage from "./features/booking-instruments/booking-instruments-scan/BookingInstrumentScanMainPage";
import TogglerContextWrapper from "./components/shared/TogglerContextWrapper";
import AppDrawer from "./views/AppDrawer";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
// import MyBookings from "./features/booking-instruments/my-bookings/MyBookings";
import { useMobile } from "./utils/hooks";
import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { withApollo } from "react-apollo";
import _ from "underscore";
import { saveLastFilter } from "./features/user/saveLastFilter";
import InstrumentDetailedInfoMainPage from "./features/instrument-detailed-information/InstrumentDetailedInfoMainPage";
import TabsForDetailedInfoWrapper from "./features/instrument-detailed-information/tabs-for-detailed-info-context/TabsForDetailedInfoWrapper";
import ModuleVersion from "./features/system-version/ModuleVersion";
import AdminMainView from "./features/booking-admin/MainView";
import CustomFilterWrapper from "./features/booking-instruments/booking-instruments-custom-filter/CustomFilterWrapper";
import ReasonWrapper from "../src/features/booking-admin/reasons/ReasonWrapper";
import PaginationWrapperReason from "../src/features/booking-admin/reasons/PaginationWrapperReason";
import {
  BOOKING_WRAPPER_INITALIZE,
  DEFAULT_LIMIT,
  EDIT_BOOKING_URL,
} from "./constants";
import { getEnv } from "./utils/helpers/text";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { LIST_BACKEND_HEALTH_CHECKS } from "../src/gql/bookingapi/queries";
import { API } from "aws-amplify";
import { CssBaseline } from "@mui/material";
// import BookingListMainPage from "./features/booking-list/BookingListMainPage";
import { getCookieData } from "./utils/helpers/cookie";
import { useHistory } from "react-router-dom";
import BookEquipmentMainPage from "./features/book-equipment/BookEquipmentMainPage";
import MyBooking from "./features/my-booking/MyBooking";
import PaginationWrapper from "./components/shared/pagination/PaginationWrapper";
import BookingWrapper from "./features/booking/BookingWrapper";
import { AwsRum } from "aws-rum-web";
const currentEnv = getEnv();
// const FlexBox = styled.div`
//   display: flex;
// `;

/**
 * Code for AWS cloud watch RUM
 */
let awsRum = null; //very important otherwise variable is local to try/catch block and will be garbage collected, and possible even removed during compilation (production optimized build)
try {
  let app_telemetries =
    currentEnv === "dev" || currentEnv === "test"
      ? ["performance", "errors", "http"]
      : ["errors"];
  // help for config: https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
  awsRum = new AwsRum(
    process.env.REACT_APP_AWS_RUM_APPLICATION_ID,
    process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION,
    process.env.REACT_APP_AWS_RUM_APPLICATION_REGION,
    {
      sessionSampleRate: 1, //record 100% of sessions
      sessionEventLimit: 0, //no limit on number of events per session
      guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: process.env.REACT_APP_AWS_RUM_ENDPOINT,
      telemetries: app_telemetries,
      // in development, record all telemetries
      // telemetries: ["errors"], // for production/staging, only errors
      allowCookies: true,
      enableXRay: false,
    }
  );
  // console.log(awsRum);
  //https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-custom-events.html
  //Caution if you send empty data, you get a strange error:  1 validation error detected: Value null at 'rumEvents.3.member.details' failed to satisfy constraint: Member must not be null
  // Note: custom events must be enabled in the cloudwatch console, otherwise they are simply ignore.
  awsRum.recordEvent("my_custom_event", { value_1: "App Loaded" }); //record custom event (remove later)
} catch (error) {
  console.log(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
// Code for AWS cloud watch RUM ENDS

/**
 * ErrorBoundary
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("recordingError: " + error);
    awsRum.record(error);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Clear Error
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const MainViewContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
`;

const initialValues = {
  fetching: false,
  prevTokens: [],
  nextToken: null,
  nextData: 0,
  limit: DEFAULT_LIMIT,
  data: [],
  sortDirection: "ASC",
  orderBy: "groupName",
  refreshData: 0,
};
const getContainerHeight = (currentEnv) => {
  return currentEnv ? window.innerHeight - 125 : window.innerHeight - 75;
};
function App({ client }) {
  const isMobile = useMobile();
  const store = useStore();
  const [containerHeight, setContainerHeight] = useState(
    getContainerHeight(currentEnv)
  );
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv));
  });
  const storeUser = useSelector((theStore) => theStore.user);
  useEffect(() => {
    const unsubscribe = store.subscribe(
      _.throttle(
        async () => {
          const userState = store.getState().user;
          if (userState.id && userState.email) {
            await saveLastFilter(client, userState);
          }
        },
        5000,
        {
          leading: false,
        }
      )
    );

    return () => {
      unsubscribe();
    };
  }, [client, store]);

  const [enableVal, setEnableVal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [popupEnableStatus, setPopupEnableStatus] = useState(false);

  const getBackendAuthChecks = async () => {
    try {
      const backendBuildInfo = await API.graphql({
        query: LIST_BACKEND_HEALTH_CHECKS,
      });
      const items = backendBuildInfo?.data?.listBackendHealthChecks?.items;
      const trackDisable = items.map((item) => item?.enableAnalyticsTracking);
      setEnableVal(trackDisable[0]);
    } catch (error) {
      console.log("Matomo api error..", error);
    }
  };
  useEffect(() => {
    getBackendAuthChecks();
  }, []);

  const bookingUrl = getCookieData(EDIT_BOOKING_URL);
  const history = useHistory();
  if (bookingUrl) history.push("/bookings");

  // Matomo code added for analytics
  //urlBase: optional, default value: `${urlBase}matomo.php`
  // siteId : optional, default value: `${urlBase}matomo.js`
  // disabled :optional, false by default. Makes all tracking calls no-ops if set to true.
  // linkTracking :optional, default value: true
  // active: optional, default value: true
  // seconds: optional, default value: `15

  const instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_BASE_URL,
    siteId: process.env.REACT_APP_SITE_ID,
    trackerUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.php",
    srcUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.js",
    disabled: enableVal,
    linkTracking: true,
    trackPageView: true,
    heartBeat: {
      active: true,
      seconds: 10,
    },
  });

  return (
    <ErrorBoundary>
      <MatomoProvider value={instance}>
        <LoadBookingInstrumentsInfo>
          <TogglerContextWrapper initialState={!isMobile}>
            <CssBaseline />
            <AppBar />
            {/* <FlexBox> */}
            <BookingWrapper initialValues={BOOKING_WRAPPER_INITALIZE}>
              <AppDrawer
                setPopupEnableStatus={setPopupEnableStatus}
                popupEnableStatus={popupEnableStatus}
              >
                <MainViewContainer
                  id="MainViewContainer"
                  style={{ height: `${containerHeight}px` }}
                >
                  <Switch>
                    <Route exact path="/">
                      <BookEquipmentMainPage
                        setSubscriptionStatus={setSubscriptionStatus}
                        setPopupEnableStatus={setPopupEnableStatus}
                      />
                    </Route>
                    <Route exact path="/equipmentList/:InventoryId">
                      <BookEquipmentMainPage
                        setSubscriptionStatus={setSubscriptionStatus}
                        setPopupEnableStatus={setPopupEnableStatus}
                      />
                    </Route>
                    <Route exact path="/bookings">
                      <PaginationWrapper>
                        <MyBooking
                          setSubscriptionStatus={setSubscriptionStatus}
                          subscriptionStatus={subscriptionStatus}
                          setPopupEnableStatus={setPopupEnableStatus}
                        />
                      </PaginationWrapper>
                    </Route>
                    <Route exact path="/scan">
                      <CustomFilterWrapper
                        initialValues={{ site: storeUser?.site }}
                      >
                        <BookingInstrumentScanMainPage />
                      </CustomFilterWrapper>
                    </Route>
                    <Route exact path="/instrumentDetailedInfo/:inventoryId?">
                      <CustomFilterWrapper
                        initialValues={{ site: storeUser?.site }}
                      >
                        <TabsForDetailedInfoWrapper>
                          <InstrumentDetailedInfoMainPage />
                        </TabsForDetailedInfoWrapper>
                      </CustomFilterWrapper>
                    </Route>
                    <Route exact path="/admin">
                      <PaginationWrapperReason initialValues={initialValues}>
                        <ReasonWrapper>
                          <AdminMainView
                            setSubscriptionStatus={setSubscriptionStatus}
                            subscriptionStatus={subscriptionStatus}
                            setPopupEnableStatus={setPopupEnableStatus}
                          />
                        </ReasonWrapper>
                      </PaginationWrapperReason>
                    </Route>
                    <Route exact path="/info">
                      <ModuleVersion />
                    </Route>
                  </Switch>
                </MainViewContainer>
              </AppDrawer>
            </BookingWrapper>
            <StyledToastContainer />
          </TogglerContextWrapper>
        </LoadBookingInstrumentsInfo>
      </MatomoProvider>
    </ErrorBoundary>
  );
}

export default withApollo(App);
