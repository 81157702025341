import React, { useContext } from "react";
import { Header } from "@digitallab/grid-common-components";
import { getEnv, getEnvLebel } from "../../utils/helpers/text";
import { BackendInfoContext } from "../backend-info-context/context";
import styled from "styled-components";
const CustomWrapper = styled.div`
  > div {
    z-index: 1200;
  }
`;
const currentEnv = getEnv();
const leftLable = getEnvLebel(currentEnv);
const HeaderSection = () => {
  const { rightLabel } = useContext(BackendInfoContext);
  return (
    <CustomWrapper>
      <Header
        environment={currentEnv}
        leftLabel={leftLable}
        rightLabel={rightLabel}
      />
    </CustomWrapper>
  );
};
export default HeaderSection;
