import React, { useContext } from "react";
import { Footer } from "@digitallab/grid-common-components";
import { BackendInfoContext } from "../backend-info-context/context";
import styled from "styled-components";
const CustomWrapper = styled.div`
  & .WrapperDivFooter {
    z-index: 1200;
  }
`;
const FooterSection = () => {
  const { FooterLeftLabel } = useContext(BackendInfoContext);
  return (
    <CustomWrapper>
      <Footer
        style={{ zIndex: 1200 }}
        FooterLink="/info"
        leftLabel={FooterLeftLabel}
      />
    </CustomWrapper>
  );
};
export default FooterSection;
