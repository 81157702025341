import React from "react";
import { useEffect } from "react";
import CustomEventCalendar from "./CustomEventCalendar";
import { useState } from "react";
import omitDeep from "omit-deep-lodash";
import { groupBy, uniqueId } from "lodash";
import { getAllData } from "../../../utils/helpers/fetching";
import { GET_BOOKING_ENTRIES_BY_SITE_AND_INVENTORY_ID } from "../../../gql/bookingapi";
import { withApollo, compose } from "react-apollo";
import { useContext } from "react";
import { connect, useSelector } from "react-redux";
import { CustomFilterContext } from "../../booking-instruments/booking-instruments-custom-filter/context";
import {
  BACKDROP_ZINDEX,
  singleEventSkeleton,
  CLUSTER_DETAILS_ATTRIBUTE_META,
  STANDALONE_ATTRIBUTE_META,
  CLUSTER_DETAILS_HEADING,
  EQUIPMENT_DETAILS_HEADING,
  ATTRIBUTES_HEADING,
  entryType,
  RESOURCE,
  EVENT,
  IconStatus,
} from "../../../constants";
import { loadBookingInstruments as loadBookingInstrumentsAction } from "../../booking-instruments/redux/actions";
import { loadPendingReservations as loadPendingReservationsAction } from "../../booking-calendars/redux/actions";
import { areIntervalsOverlapping } from "date-fns";
import { changeDateToUtcString } from "../../../utils/helpers/text";
import { OwcBackdrop, OwcProgressSpinner } from "@one/react";
import PopOverModal from "../../../components/shared/CommonPopOverModal/PopOverModal";
import useDialog from "../../../utils/hooks/useDialog";
import PopOverClusterAgGridTable from "../../../components/shared/CommonPopOverModal/PopOverClusterAgGridTable";
import PopoverBookingStatusDetail from "../../../components/shared/CommonPopOverModal/PopoverBookingStatusDetail";
import PopoverAttributesDetails from "../../../components/shared/CommonPopOverModal/PopoverAttributesDetails";
import PopoverBookingTitle from "../../../components/shared/CommonPopOverModal/PopoverBookingTitle";
import { BookingContext } from "../../booking/context";
import { useFormikContext } from "formik";

const CalendarMainPage = ({
  client,
  loadBookingInstruments,
  loadPendingReservations,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  tabList,
  setTabList,
  view,
  setView,
  calView,
  setCalView,
  newEquipmentIds,
  setNewEquipmentIds,
  cancelStatus = false,
  setCancelStatus,
  deleteResourceId,
  setDeleteResourceId,
}) => {
  //const mockEventsData = omitDeep(mockEvents, "recurring");
  const formik = useFormikContext();
  const { isEditMode, isOngoing, editBookingData } = useContext(BookingContext);
  const { openDialog, ...dialogProps } = useDialog();
  const [eventsData, setEventsData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectionType, setSelectionType] = useState();
  const user = useSelector((store) => store.user);
  const { pendingReservations } = useSelector((state) => state.reservations);
  const dataTimeZone = "utc";
  const displayTimeZone = user.timezone;
  const bookingInstruments = useSelector((store) => store.bookingInstruments);
  const { selectedList, setSelectedList } = useContext(CustomFilterContext);
  const [popoverObject, setpopoverObject] = useState({
    equipmentData: {},
    eventData: {},
  });

  useEffect(() => {
    if (pendingReservations.length === 0) {
      tabList[2].isDisable = true;
      setTabList([...tabList]);
    } else if (pendingReservations.length !== 0) {
      tabList[2].isDisable = false;
      setTabList([...tabList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingReservations]);

  useEffect(() => {
    if (bookingInstruments.bookingInstruments.length > 0) {
      const tempResource = [];
      bookingInstruments.bookingInstruments.forEach((item) => {
        tempResource.push({
          name: item?.shortIdentifier,
          id: item?.inventoryId,
          eventOverlap: false,
        });
      });
      setResourceData(tempResource);
      // setResourceData(uniqBy(tempResource, "id"));
    }
  }, [bookingInstruments.bookingInstruments]);

  const clearPendingReservation = () => {
    loadPendingReservations([]);
  };
  const updatePendingReservation = (list) => {
    loadPendingReservations([...list]);
  };

  const getReservationBooking = async (dateFrom, dateTo) => {
    const reservationData = await getBookings({
      client,
      siteName: user?.site,
      dateFrom,
      dateTo,
    });
    reservationData.map((item) => {
      item["editable"] = false;
      item["title"] = item?.type;
      return item;
    });

    return reservationData;
  };
  const getEventDetails = (
    startDate,
    endDate,
    reservationList,
    onGoingEventList = []
  ) => {
    let tempInterval = [];
    let intervalRowConfiguration = null;
    if (startDate && endDate) {
      const startRange = changeDateToUtcString(startDate);
      const EndRange = changeDateToUtcString(endDate);
      const reservationData = reservationList.filter((x) =>
        areIntervalsOverlapping(
          { start: new Date(startRange), end: new Date(EndRange) },
          { start: new Date(x.start), end: new Date(x.end) }
        )
      );
      if (isEditMode) {
        const row = IconStatus.find(
          (x) => x.text === formik?.values?.bookingType
        );
        intervalRowConfiguration = row !== undefined ? row : null;
      }

      selectedList.forEach((item, index) => {
        const findRow = reservationData.find((x) => x.resource === item);
        if (findRow === undefined) {
          let tempRow = {
            ...singleEventSkeleton,
            id: uniqueId("available_"),
            start: changeDateToUtcString(startDate),
            end: changeDateToUtcString(endDate),
            resource: item,
          };
          if (isEditMode) {
            if (intervalRowConfiguration) {
              tempRow = {
                ...tempRow,
                type: formik?.values?.bookingType,
                color: intervalRowConfiguration.backgroundColor,
                fcolor: intervalRowConfiguration.color,
                title: formik?.values?.bookingType,
              };
            }
          }
          tempInterval.push(tempRow);
        }
      });

      if (reservationData.length > 0) {
        // sorting and groupby eventsData
        const sortedGroupedReservations =
          groupAndOrderEventsData(reservationData);
        // manipulate available slots function
        //resource loop

        sortedGroupedReservations.forEach((events) => {
          let localStartDate = changeDateToUtcString(startDate);
          let localEndDate = changeDateToUtcString(endDate);
          //event loop
          events.forEach((event) => {
            // comparing the selected start Date hour with start hour of each event
            if (localStartDate < event.start) {
              let intervalRow = {
                ...singleEventSkeleton,
                id: uniqueId("available_"),
                start: localStartDate, // if event is starting from 12 morning then take event start date
                end: event.start,
                resource: event.resource,
              };
              localStartDate = event.end;
              if (isEditMode) {
                if (intervalRowConfiguration) {
                  intervalRow = {
                    ...intervalRow,
                    type: formik?.values?.bookingType,
                    color: intervalRowConfiguration.backgroundColor,
                    fcolor: intervalRowConfiguration.color,
                    title: formik?.values?.bookingType,
                  };
                }
              }
              tempInterval.push(intervalRow);
            } else {
              localStartDate = event.end;
            }
          });

          if (events[events.length - 1].end < localEndDate) {
            // comparing the end hour  with max limit hour of a day(i.e 24)
            let intervalRow = {
              ...singleEventSkeleton,
              id: uniqueId("available_"),
              start:
                localStartDate > events[events.length - 1].end
                  ? localStartDate
                  : events[events.length - 1].end,
              end: localEndDate,
              resource: events[events.length - 1].resource,
            };
            if (isEditMode) {
              if (intervalRowConfiguration) {
                intervalRow = {
                  ...intervalRow,
                  type: formik?.values?.bookingType,
                  color: intervalRowConfiguration.backgroundColor,
                  fcolor: intervalRowConfiguration.color,
                  title: formik?.values?.bookingType,
                };
              }
            }
            tempInterval.push(intervalRow);
          }
        });
      }
      if (isEditMode && isOngoing) {
        setEventsData([...reservationList, ...tempInterval]);
        loadPendingReservations([...onGoingEventList, ...tempInterval]);
      } else {
        setEventsData([...reservationList, ...tempInterval]);
        loadPendingReservations([...tempInterval]);
      }
    } else {
      setEventsData([...reservationList]);
      updatePendingReservation([]);
    }
  };

  const getLatestEventDetailsOnly = (
    startDate,
    endDate,
    reservationList,
    newSelectedList = []
  ) => {
    let tempInterval = [];
    if (startDate && endDate) {
      const startRange = changeDateToUtcString(startDate);
      const endRange = changeDateToUtcString(endDate);
      const latestSelectedList = newSelectedList.filter(
        (x) => selectedList.indexOf(x) !== -1
      );
      let intervalRowConfiguration = null;
      if (isEditMode) {
        const row = IconStatus.find(
          (x) => x.text === formik?.values?.bookingType
        );
        intervalRowConfiguration = row !== undefined ? row : null;
      }

      if (latestSelectedList.length > 0) {
        const filteredReservationList = reservationList.filter((x) => {
          return latestSelectedList.indexOf(x.resource) !== -1;
        });

        let reservationData = filteredReservationList.filter(
          (x) => x.end >= startRange && x.start <= endRange
        );
        const moreReservationData = pendingReservations.filter(
          (x) =>
            x.end >= startRange &&
            x.start <= endRange &&
            latestSelectedList.indexOf(x.resource) !== -1
        );
        reservationData = [...reservationData, ...moreReservationData];

        latestSelectedList.forEach((item, index) => {
          const findRow = reservationData.find((x) => x.resource === item);
          if (findRow === undefined) {
            let intervalRow = {
              ...singleEventSkeleton,
              id: uniqueId("available_"),
              start: changeDateToUtcString(startDate),
              end: changeDateToUtcString(endDate),
              resource: item,
            };
            if (isEditMode) {
              if (intervalRowConfiguration) {
                intervalRow = {
                  ...intervalRow,
                  type: formik?.values?.bookingType,
                  color: intervalRowConfiguration.backgroundColor,
                  fcolor: intervalRowConfiguration.color,
                  title: formik?.values?.bookingType,
                };
              }
            }
            tempInterval.push(intervalRow);
          }
        });

        if (reservationData.length > 0) {
          // sorting and groupby eventsData
          const sortedGroupedReservations =
            groupAndOrderEventsData(reservationData);
          // manipulate available slots function
          //resource loop

          sortedGroupedReservations.forEach((events) => {
            let localStartDate = changeDateToUtcString(startDate);
            let localEndDate = changeDateToUtcString(endDate);
            //event loop
            events.forEach((event) => {
              // comparing the selected start Date hour with start hour of each event
              if (localStartDate < event.start) {
                let intervalRow = {
                  ...singleEventSkeleton,
                  id: uniqueId("available_"),
                  start: localStartDate, // if event is starting from 12 morning then take event start date
                  end: event.start,
                  resource: event.resource,
                };
                localStartDate = event.end;
                if (isEditMode) {
                  if (intervalRowConfiguration) {
                    intervalRow = {
                      ...intervalRow,
                      type: formik?.values?.bookingType,
                      color: intervalRowConfiguration.backgroundColor,
                      fcolor: intervalRowConfiguration.color,
                      title: formik?.values?.bookingType,
                    };
                  }
                }
                tempInterval.push(intervalRow);
              } else {
                localStartDate = event.end;
              }
            });

            if (events[events.length - 1].end < localEndDate) {
              // comparing the end hour  with max limit hour of a day(i.e 24)
              let intervalRow = {
                ...singleEventSkeleton,
                id: uniqueId("available_"),
                start:
                  localStartDate > events[events.length - 1].end
                    ? localStartDate
                    : events[events.length - 1].end,
                end: localEndDate,
                resource: events[events.length - 1].resource,
              };
              if (isEditMode) {
                if (intervalRowConfiguration) {
                  intervalRow = {
                    ...intervalRow,
                    type: formik?.values?.bookingType,
                    color: intervalRowConfiguration.backgroundColor,
                    fcolor: intervalRowConfiguration.color,
                    title: formik?.values?.bookingType,
                  };
                }
              }

              tempInterval.push(intervalRow);
            }
          });
        }
        setEventsData([
          ...reservationList,
          ...tempInterval,
          ...pendingReservations,
        ]);
        loadPendingReservations([...pendingReservations, ...tempInterval]);
      } else {
        setEventsData([...reservationList, ...pendingReservations]);
      }
    }
  };

  /**
   * sorting and group by data based on start
   * @param {array} eventsData - array of all events Data
   * @returns {array} - sorted and grouped by resource (inventoryId)
   */
  const groupAndOrderEventsData = (eventsData) => {
    const groupedReservations = groupBy(eventsData, "resource");
    const sortedGroupedReservations = Object.keys(groupedReservations).map(
      (event) =>
        groupedReservations[event].sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        )
    );
    return sortedGroupedReservations;
  };

  /**
   * getting booked slots for the selected equipmentID with time range
   * @param  sitename {string}
   * @param  dateFrom { date }
   * @param  dateTo { date }
   * @returns {Promise<string>} JSON - sorted and grouped by resource (inventoryId)
   */
  // const getBookingsOLD = async ({ client, siteName, dateFrom, dateTo }) => {
  //   const tempFilter = {
  //     or: [],
  //   };
  //   selectedList.forEach((x) => {
  //     tempFilter.or.push({ inventoryId: { eq: x } });
  //   });
  //   let filter = {
  //     and: [
  //       { active: { eq: true } },
  //       {
  //         or: [{ start: { le: dateTo } }, { end: { ge: dateFrom } }],
  //       },
  //       tempFilter,
  //     ],
  //   };
  //   if (isEditMode) {
  //     filter.and.push({ bookingId: { ne: editBookingData.id } });
  //   }
  //   const { items } = await getAllData({
  //     client,
  //     query: GET_BOOKING_ENTRIES_BY_SITE_AND_DATE,
  //     drillData: true,
  //     fetchPolicy: "no-cache",
  //     variables: {
  //       limit: 1000,
  //       nextToken: null,
  //       site: siteName,
  //       filter: filter,
  //       date: { le: dateTo },
  //       sortDirection: "DESC",
  //     },
  //     dataPath: ["data", "bookingEntriesBySiteAndDate"],
  //   });
  //   console.log("items ==================> ", items);
  //   return omitDeep(items, "recurring");
  // };
  const getBookings = async ({ client, siteName, dateFrom, dateTo }) => {
    const tempFilter = {
      or: [],
    };
    selectedList.forEach((x) => {
      tempFilter.or.push({ inventoryId: { eq: x } });
    });
    let filter = {
      and: [
        { active: { eq: true } },
        {
          or: [{ start: { le: dateTo } }, { end: { ge: dateFrom } }],
        },
      ],
    };
    if (isEditMode) {
      filter.and.push({ bookingId: { ne: editBookingData.id } });
    }

    if (selectedList.length > 0) {
      try {
        const resultArray = [];
        await Promise.all(
          selectedList.map(async (k) => {
            const { items } = await getAllData({
              client,
              query: GET_BOOKING_ENTRIES_BY_SITE_AND_INVENTORY_ID,
              drillData: true,
              fetchPolicy: "no-cache",
              variables: {
                inventoryId: { eq: k },
                limit: 1000,
                nextToken: null,
                site: siteName,
                filter: filter,
              },
              dataPath: ["data", "bookingEntriesBySiteAndInventoryId"],
            });

            const result = omitDeep(items, "recurring").filter(
              (x) => x.start < x.end
            );
            resultArray.push(...result);

            return items;
            //resultArray.push({ [inventoryId]: [...items] });
          })
        );
        return resultArray;
      } catch (e) {
        console.log("error ", e);
      }
    }
  };

  /**
   * to remove the equipment whilee click on cross icon
   * @returns {array} - eventData and resourceData
   */
  const removeEquipmentAndReservation = (
    resourceId,
    pendingReservationsList
  ) => {
    const tempEventData = eventsData.filter((x) => x.resource !== resourceId);
    const tempResourceData = resourceData.filter((x) => x.id !== resourceId);
    setEventsData(tempEventData);
    setResourceData(tempResourceData);
    setSelectedList(selectedList.filter((x) => x !== resourceId));
    updatePendingReservation(
      pendingReservationsList.filter((x) => x.resource !== resourceId)
    );
    loadBookingInstruments({
      bookingInstruments: bookingInstruments.bookingInstruments.filter(
        (x) => x.inventoryId !== resourceId
      ),
    });
  };
  const manipulatePopoverContent = ({
    userSelectionType,
    resourceId = null,
    eventId = null,
  }) => {
    setSelectionType(userSelectionType);
    switch (userSelectionType) {
      case EVENT:
        if (eventId) {
          let eventData = eventsData.find((x) => x.id === eventId);
          const equipmentData = bookingInstruments?.bookingInstruments.find(
            (x) => x.id === eventData.resource
          );
          openDialog();
          setpopoverObject({
            equipmentData: equipmentData,
            eventData: eventData,
          });
        }

        break;
      case RESOURCE:
        if (resourceId) {
          const equipmentData = bookingInstruments?.bookingInstruments.find(
            (x) => x.id === resourceId
          );
          const eventData = {};
          openDialog();
          setpopoverObject({
            equipmentData: equipmentData,
            eventData: eventData,
          });
        }
        break;

      default:
        break;
    }
  };
  return (
    <>
      {loading && (
        <OwcBackdrop opacity={0.3} style={{ zIndex: BACKDROP_ZINDEX }}>
          <OwcProgressSpinner />
        </OwcBackdrop>
      )}
      {bookingInstruments.bookingInstruments.length > 0 && (
        <CustomEventCalendar
          eventData={eventsData}
          resourceData={resourceData}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          setEventsData={setEventsData}
          setResourceData={setResourceData}
          dataTimeZone={dataTimeZone}
          displayTimeZone={displayTimeZone}
          getReservationBooking={getReservationBooking}
          clearPendingReservation={clearPendingReservation}
          updatePendingReservation={updatePendingReservation}
          removeEquipmentAndReservation={removeEquipmentAndReservation}
          pendingReservations={pendingReservations}
          getEventDetails={getEventDetails}
          setloading={setloading}
          loading={loading}
          view={view}
          setView={setView}
          calView={calView}
          setCalView={setCalView}
          siteTimezone={user.timezone}
          manipulatePopoverContent={manipulatePopoverContent}
          newEquipmentIds={newEquipmentIds}
          setNewEquipmentIds={setNewEquipmentIds}
          getLatestEventDetailsOnly={getLatestEventDetailsOnly}
          setCancelStatus={setCancelStatus}
          cancelStatus={cancelStatus}
          deleteResourceId={deleteResourceId}
          setDeleteResourceId={setDeleteResourceId}
        />
      )}
      <PopOverModal
        title={
          selectionType === RESOURCE
            ? popoverObject?.equipmentData?.shortIdentifier
            : Object.keys(popoverObject?.equipmentData).length > 0 && (
                <PopoverBookingTitle data={popoverObject} />
              )
        }
        closeTitle={selectionType === RESOURCE ? "Close" : "Cancel"}
        data={popoverObject}
        {...dialogProps}
        onDialogCloseCallBack={() =>
          setpopoverObject({
            equipmentData: {},
            eventData: {},
          })
        }
      >
        {selectionType === RESOURCE && (
          <PopoverAttributesDetails
            title={ATTRIBUTES_HEADING}
            metaData={
              popoverObject?.equipmentData?.entryType === entryType.cluster
                ? CLUSTER_DETAILS_ATTRIBUTE_META
                : STANDALONE_ATTRIBUTE_META
            }
            data={popoverObject?.equipmentData}
          />
        )}
        {selectionType === EVENT && (
          <>
            <PopoverBookingStatusDetail data={popoverObject} />
            <PopoverAttributesDetails
              title={
                popoverObject?.equipmentData?.entryType === entryType.cluster
                  ? CLUSTER_DETAILS_HEADING
                  : EQUIPMENT_DETAILS_HEADING
              }
              metaData={
                popoverObject?.equipmentData?.entryType === entryType.cluster
                  ? CLUSTER_DETAILS_ATTRIBUTE_META
                  : STANDALONE_ATTRIBUTE_META
              }
              data={popoverObject?.equipmentData}
            />
          </>
        )}
        {popoverObject?.equipmentData?.entryType === entryType.cluster && (
          <PopOverClusterAgGridTable data={[popoverObject?.equipmentData]} />
        )}
      </PopOverModal>
    </>
  );
};

export default compose(
  connect(null, {
    loadBookingInstruments: loadBookingInstrumentsAction,
    loadPendingReservations: loadPendingReservationsAction,
  }),
  withApollo
)(CalendarMainPage);
