import { useSelector } from "react-redux";
import { OwcEditableDropdown, OwcListItem } from "@one/react";

const InputSelectType = ({ value, onHandleChange }) => {
  const { bookingAddReason } = useSelector((state) => state.bookingAdmin);
  const handleChange = (selectedValue) => {
    onHandleChange(selectedValue);
  };
  return (
    <>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <OwcEditableDropdown
          label={`Select reason *`}
          style={{ width: "100%" }}
          value={value || null}
          name="bookingAddReason"
          key={value}
          required
          testidprefix="action-data"
          data-testid="Selectreason"
          onSelectChange={(selectedValue) => {
            let tempArray = null;
            if (selectedValue.detail !== null) {
              tempArray = bookingAddReason.find(
                (x, index) => index === selectedValue.detail
              );
            }
            handleChange(tempArray === null ? null : tempArray.configText);
          }}
        >
          {bookingAddReason?.map((item, index) => {
            const option = item.configText;
            return (
              <>
                <OwcListItem value={option} key={option}>
                  {option}
                </OwcListItem>
              </>
            );
          })}
        </OwcEditableDropdown>
      </div>
    </>
  );
};

export default InputSelectType;
