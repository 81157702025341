import { OwcChip } from "@one/react";

const CustomChip = ({ text, color, bgColor }) => {
  return (
    <OwcChip round style={{ color: color, backgroundColor: bgColor }}>
      {text}
    </OwcChip>
  );
};
export default CustomChip;
