import React, { useEffect, useState, useContext } from "react";
import { sortBy, get } from "lodash";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Auth } from "aws-amplify";
import { configureAwsAppSync } from "../../utils/helpers/configureAwsAppSync";
import { loadUserInfo as loadUserInfoAction } from "../user/redux/actions";
import {
  loadRoomList as loadRoomListAction,
  loadBuildingList as loadBuildingListAction,
  loadAddReasonList as loadAddReasonListAction,
} from "../booking-admin/redux/actions";
import styled from "styled-components";
import { signOut } from "../../utils/signout";
import {
  ACCESS_TOKEN_PATH,
  BOOKING_USER,
  DURATION,
  GROUPS_TOKEN_PATH,
  SYNC_PROFILE_FIELDS,
  equipmentStatus,
} from "../../constants";
import {
  LIST_DIGITAL_LAB_BOOKING_INSTRUMENTS,
  LIST_DIGITAL_LAB_BOOKING_USERS,
  GET_LOGGED_USER_DETAILS,
  LIST_FILTERBOOKING_DATA_BY_SITE_NAME_AND_FILTER_ATTRIBUTES,
  LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE_DROPDOWN,
  LP_GET_LOGGED_USER_DETAILS,
  LP_LIST_DIGITAL_LAB_LANDING_PAGE_APPLICATIONS,
  GET_SITE_TIMEZONE,
} from "../../gql/bookingapi/queries";
import {
  loadBookingInstruments as loadBookingInstrumentsAction,
  loadUnfilteredBookingInstruments as loadUnfilteredBookingInstrumentsAction,
  updateSearchDropDownFilter as updateSearchDropDownFilterActions,
} from "./redux/actions";
import { emptyLastFilter, stringifyFilter } from "../../utils/store/lastFilter";
import {
  CREATE_DIGITAL_LAB_BOOKING_USER_PROFILE,
  UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
  UPDATE_DIGITAL_LAB_LP_USER_PROFILE_FILTER,
} from "../../gql/bookingapi/mutations";
import { loadUsers as loadUsersAction } from "../booking-users/redux/actions";
import { initialState as filterInitalState } from "../booking-instruments/booking-instruments-custom-filter/CustomFilterWrapper";
import { isEqual, pick } from "underscore";
import {
  getAllData,
  getAllBookingInstrumentsList,
} from "../../utils/helpers/fetching";
import { WidgetContext } from "../../contexts";
import { filterBookingData } from "../../utils/helpers/text";
import { getCookieData } from "../../utils/helpers/cookie";
import { API } from "aws-amplify";
import { OwcButton, OwcProgressSpinner, OwcTypography } from "@one/react";
import { snackbarService } from "@one/web-components";

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;
`;

class SiteError extends Error {
  constructor(message) {
    super(message);
    this.name = "SiteError";
  }
}

const stripPhoneNumber = (phoneNumber) =>
  phoneNumber?.replace(/[^+\d]/gi, "") ?? "";

const checkRoleAndGetUserEmail = async (
  theAuth,
  required_group = BOOKING_USER
) => {
  const currentAuthenticatedUser = await theAuth.currentAuthenticatedUser();
  const access_groups = get(currentAuthenticatedUser, GROUPS_TOKEN_PATH);
  if (
    !Array.isArray(access_groups) ||
    !access_groups.includes(required_group)
  ) {
    throw new Error("You do not have permission to access.");
  }
  const syncFields = pick(
    get(currentAuthenticatedUser, ACCESS_TOKEN_PATH),
    Object.values(SYNC_PROFILE_FIELDS)
  );
  const email = get(currentAuthenticatedUser, "attributes.email");

  if (syncFields?.[SYNC_PROFILE_FIELDS.phone]) {
    syncFields[SYNC_PROFILE_FIELDS.phone] = stripPhoneNumber(
      syncFields?.[SYNC_PROFILE_FIELDS.phone]
    );
  }
  return { email, roles: access_groups, ...syncFields };
};

export const getBookingInstruments = async ({
  client,
  siteName,
  user,
  loadUnfilteredBookingInstruments,
}) => {
  let { items: bookingInstruments } = await getAllData({
    client,
    query: LIST_DIGITAL_LAB_BOOKING_INSTRUMENTS,
    drillData: true,
    fetchPolicy: "no-cache",
    variables: {
      limit: 500,
      nextToken: null,
      filter: {
        and: [
          { siteName: { eq: siteName } },
          { status: { eq: equipmentStatus?.active?.key } },
          { isBookable: { eq: true } },
        ],
      },
    },
    dataPath: ["data", "listDigitalLabBookingInstruments"],
  });

  const allBookingEquipmentList = await getAllBookingInstrumentsList({
    client,
    user,
  });
  loadUnfilteredBookingInstruments({
    allBookingInstruments: allBookingEquipmentList,
  });

  bookingInstruments = filterBookingData(bookingInstruments, user?.user);
  return {
    bookingInstruments,
  };
};

export const getBookingAddReason = async ({ client }) => {
  const { items: bookingAddReason } = await getAllData({
    client,
    query: LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE_DROPDOWN,
    fetchPolicy: "no-cache",
    dataPath: ["data", "configurationByModelAndText"],
    variables: {
      limit: 500,
    },
    drillData: true,
  });

  return {
    bookingAddReason,
  };
};

export const getRoomList = async ({ client, siteName }) => {
  const { items } = await getAllData({
    client,
    query: LIST_FILTERBOOKING_DATA_BY_SITE_NAME_AND_FILTER_ATTRIBUTES,
    drillData: true,
    fetchPolicy: "no-cache",
    variables: {
      siteName,
      limit: 500,
      nextToken: null,
      filterAttribute: { eq: "room" },
    },
    dataPath: ["data", "listFilterBookingDataBySiteNameAndFilterAttributes"],
  });

  const roomList = items.map((item) => item.filterValue);
  return { roomList };
};

export const getBuildingList = async ({ client, siteName }) => {
  const { items } = await getAllData({
    client,
    query: LIST_FILTERBOOKING_DATA_BY_SITE_NAME_AND_FILTER_ATTRIBUTES,
    drillData: true,
    fetchPolicy: "no-cache",
    variables: {
      siteName,
      limit: 500,
      nextToken: null,
      filterAttribute: { eq: "buildingLocation" },
    },
    dataPath: ["data", "listFilterBookingDataBySiteNameAndFilterAttributes"],
  });

  const buildingList = items.map((item) => item.filterValue);
  return { buildingList };
};
export const getBookingUsers = async ({ client, query_bookingUsers }) => {
  const { items: bookingUsers = [] } = await getAllData({
    client,
    query: query_bookingUsers,
    fetchPolicy: "no-cache",
    dataPath: ["data", "listDigitalLabBookingUsers"],
    drillData: true,
    variables: {
      limit: 1000,
    },
  });

  return {
    bookingUsers,
  };
};
export const updateProfileFields = async ({
  client,
  email,
  user,
  ...syncFields
}) => {
  const syncKeys = Object.keys(SYNC_PROFILE_FIELDS);
  const profileObj = syncKeys.reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]: syncFields?.[SYNC_PROFILE_FIELDS?.[currKey]] ?? "",
    }),
    {}
  );
  if (!user) {
    return profileObj;
  }
  const shouldUpdate = !isEqual(pick(user, syncKeys), {
    ...profileObj,
    site: profileObj?.site || user.site,
  });
  if (!shouldUpdate) return user;
  const {
    data: { updateDigitalLabBookingUserProfile: updatedUser },
  } = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE_PERSONAL_FIELDS,
    fetchPolicy: "no-cache",
    variables: {
      id: user?.id,
      email,
      lastFilter: user?.lastFilter,
      ...profileObj,
      site: profileObj?.site || user?.site,
    },
  });
  return updatedUser;
};

export const updateLPState = async ({ client, user }) => {
  let lastFilterData = {};
  if (user?.lastFilter) {
    lastFilterData = JSON.parse(decodeURI(user?.lastFilter));
  }
  lastFilterData.selectedGroup = {
    id: "",
    name: "Applications",
    type: "allApplications",
  };
  lastFilterData = encodeURI(JSON.stringify(lastFilterData));
  const LP_config = {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_LP_AWS_GRAPHQL_ENDPOINT,
  };
  configureAwsAppSync(LP_config);
  const { updatedDigitalLabLandingPageUserProfile } = await API.graphql({
    query: UPDATE_DIGITAL_LAB_LP_USER_PROFILE_FILTER,
    client,
    variables: {
      id: user?.id,
      email: user?.email,
      lastFilter: lastFilterData,
    },
  });
  return {
    updatedDigitalLabLandingPageUserProfile,
  };
};

export const getLPUserDetails = async ({ client }) => {
  const LP_config = {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_LP_AWS_GRAPHQL_ENDPOINT,
  };

  configureAwsAppSync(LP_config);
  const userInfo = await API.graphql({
    query: LP_GET_LOGGED_USER_DETAILS,
    client,
  });

  return get(userInfo, "data.getLoggedInUserDetails.items[0]");
};
export const getLPAllApp = async ({ client }) => {
  const LP_config = {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_LP_AWS_GRAPHQL_ENDPOINT,
  };

  configureAwsAppSync(LP_config);
  const allApps = await API.graphql({
    query: LP_LIST_DIGITAL_LAB_LANDING_PAGE_APPLICATIONS,
    client,
  });

  return get(allApps, "data.listDigitalLabLandingPageApplications");
};
export const getUserDetails = async ({ client, email, ...syncFields }) => {
  const result = await client.query({
    query: GET_LOGGED_USER_DETAILS,
    fetchPolicy: "no-cache",
  });

  const user = get(result, "data.getLoggedInUserDetails.items[0]");
  if (!syncFields?.[SYNC_PROFILE_FIELDS.site] && !user?.site) {
    throw new SiteError(
      "You do not have site location assigned. Go to the Landing Page to configure this."
    );
  }
  if (!syncFields?.[SYNC_PROFILE_FIELDS.site]) {
    snackbarService.show({
      message: `Your location has been changed. Please check your settings at the Landing Page.`,
      type: "error",
      duration: DURATION,
    });
  }
  const profileFieldsOrUpdatedUser = await updateProfileFields({
    client,
    user,
    email,
    ...syncFields,
  });
  if (profileFieldsOrUpdatedUser?.id) {
    return { user: profileFieldsOrUpdatedUser };
  }
  const {
    data: { createDigitalLabBookingUserProfile },
  } = await client.mutate({
    mutation: CREATE_DIGITAL_LAB_BOOKING_USER_PROFILE,
    fetchPolicy: "no-cache",
    variables: {
      email,
      lastFilter: stringifyFilter(emptyLastFilter),
      ...profileFieldsOrUpdatedUser,
    },
  });

  return {
    user: createDigitalLabBookingUserProfile,
  };
};

export const getSiteTimezone = async ({ client, siteName }) => {
  const result = await client.query({
    query: GET_SITE_TIMEZONE,

    variables: {
      siteName: siteName,
    },
    fetchPolicy: "no-cache",
  });

  const siteDetail = get(result, "data.getSite");
  return siteDetail;
};

const LoadBookingInstrumentsInfo = ({
  children,
  client,
  loadUserInfo,
  loadBookingInstruments,
  loadUnfilteredBookingInstruments,
  loadUsers,
  loadRoomList,
  loadBuildingList,
  loadAddReasonList,
  updateSearchDropDownFilter,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);
  const storeUser = useSelector((store) => store.user);
  const { widgetName } = useContext(WidgetContext);
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setError(null);
      try {
        const { email, roles, ...syncFields } = await checkRoleAndGetUserEmail(
          Auth
        );
        const { user } = await getUserDetails({ client, email, ...syncFields });
        const siteName = user?.site || filterInitalState?.site;
        const { timezone } = await getSiteTimezone({ client, siteName });
        //TODO :For optimization
        // const { bookingInstruments } = await getBookingInstruments({
        //   client,
        //   siteName,
        //   user,
        //   loadUnfilteredBookingInstruments,
        // });
        const { bookingUsers } = await getBookingUsers({
          client,
          query_bookingUsers: LIST_DIGITAL_LAB_BOOKING_USERS,
        });
        loadUsers(bookingUsers);
        loadUserInfo({
          ...user,
          timezone: timezone,
          site: siteName,
          roles: roles,
        });
        //TODO :For optimization
        // loadBookingInstruments({
        //   bookingInstruments: bookingInstruments.map((instrument) => ({
        //     ...instrument,
        //     instrumentAvailability:
        //       instrument?.addInfo?.instrumentAvailability?.toLowerCase() ??
        //       null,
        //   })),
        // });
        const { bookingAddReason } = await getBookingAddReason({
          client,
        });

        loadAddReasonList(bookingAddReason);
        const { buildingList } = await getBuildingList({
          client,
          siteName,
        });
        loadBuildingList(buildingList);
        const { roomList } = await getRoomList({
          client,
          siteName,
        });

        loadRoomList(roomList);
        if (widgetName === undefined) {
          if (user?.searchPreference !== null && user?.searchPreference) {
            let searchPreferances = sortBy(
              JSON.parse(user?.searchPreference),
              "title"
            );
            if (searchPreferances?.searchPreference) {
              searchPreferances = searchPreferances?.searchPreference;
            }
            const searchCookieQuery = getCookieData("searchQuery");

            if (searchCookieQuery) {
              searchPreferances.map(
                (item) =>
                  (item.checked =
                    item.coloumn === "equipmentId" ||
                    item.coloumn === "serialNumber"
                      ? true
                      : item.checked)
              );
            }
            if (searchPreferances)
              updateSearchDropDownFilter(searchPreferances);
          }
        }
      } catch (err) {
        console.warn(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadUserInfo,
    loadBookingInstruments,
    loadUnfilteredBookingInstruments,
    loadUsers,
    client,
    retry,
    loadRoomList,
    loadBuildingList,
    loadAddReasonList,
  ]);
  if (loading)
    return (
      <FullScreenCentered>
        <OwcProgressSpinner data-testid="loading" diameter={80} />
      </FullScreenCentered>
    );
  if (error !== null)
    return (
      <FullScreenCentered>
        <>
          <OwcTypography
            variant="title6"
            data-testid="message-error"
            gutterBottom
          >
            {error?.message ? error?.message : "Unexpected error has occurred"}
          </OwcTypography>
          {error?.name !== "SiteError" && (
            <OwcButton
              variant="primary"
              onClick={() => setRetry(retry + 1)}
              data-testid="try-again-button"
            >
              Try again
            </OwcButton>
          )}
          <OwcButton
            data-testid="signout-button"
            variant="primary"
            style={{ marginTop: ".7rem" }}
            onClick={() => signOut(client, storeUser)}
          >
            Sign out
          </OwcButton>
        </>
      </FullScreenCentered>
    );
  return children;
};

export default compose(
  connect(null, {
    loadUserInfo: loadUserInfoAction,
    loadBookingInstruments: loadBookingInstrumentsAction,
    loadUnfilteredBookingInstruments: loadUnfilteredBookingInstrumentsAction,
    loadUsers: loadUsersAction,
    loadRoomList: loadRoomListAction,
    loadBuildingList: loadBuildingListAction,
    loadAddReasonList: loadAddReasonListAction,
    updateSearchDropDownFilter: updateSearchDropDownFilterActions,
  }),
  withApollo
)(LoadBookingInstrumentsInfo);
