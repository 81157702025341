import React, { useReducer } from "react";
import { BookingContext } from "./context";
import { BOOKING_WRAPPER_INITALIZE } from "../../constants";

export const initialState = BOOKING_WRAPPER_INITALIZE;

export function reducer(
  state = initialState,
  action = { type: "", payload: {} }
) {
  switch (action.type) {
    case "editBookingData":
      return {
        ...state,
        ...action.payload,
      };

    case "resetBookingData":
      return {
        ...BOOKING_WRAPPER_INITALIZE,
      };

    default:
      return state;
  }
}

const BookingWrapper = ({ children, initialValues }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues,
  });

  return (
    <BookingContext.Provider
      value={{
        ...state,
        dispatchAction,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingWrapper;
