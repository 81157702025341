import * as R from "ramda";
import { at } from "lodash/fp";
import {
  DAYS,
  MONTHS,
  ADD_INFO,
  DEFAULT_CLUSTER_POPOVER_SKELETON,
  CLUSTER_SUB_EQUIPMENT_OBJ,
  MEGA_CLUSTER_SUB_EQUIPMENT_OBJ,
  GXPemptyInstrumentsOptions,
  CalibrationStatusOptions,
  GXP_DROP_CHECK,
  entryType,
  POPOVER_CONTROL,
} from "../../../constants";
import { isEqual } from "date-fns";
import { find, orderBy, trim, uniqBy, uniq } from "lodash";
import omitDeep from "omit-deep-lodash";
import { OwcIconButton } from "@one/react";
import { format } from "date-fns";
import moment from "moment/moment";

export const emailToName = (email) =>
  typeof email === "string" ? email.split("@")[0]?.replace(/\./g, " ") : "";

export const getText = (text) =>
  typeof text === "string" ? R.compose(R.toLower, R.trim)(text) : "";

export const filterByQuery = (
  data,
  query,
  propFilter = ["equipmentNickName", ["serialNumber"]],
  propSort = ["equipmentNickName"],
  reverse = false
) => {
  if (!query) R.sort(R.descend(R.path(propSort)))(data);
  const _query = getText(query);
  const valuesAt = at(propFilter);
  const result = R.compose(
    R.sort(R.descend(R.path(propSort))),
    R.filter(
      R.compose(R.contains(_query), getText, R.compose(R.join(""), valuesAt))
    )
  )(data);

  return reverse ? result.reverse() : result;
};

export const filterFilters = (
  data,
  filters,
  getValue = (item, key) => item[key]
) => {
  return data.filter((item) => {
    return Object.keys(filters)
      .filter((key) => filters[key] !== "" && filters[key].length !== 0)
      .every((key) => {
        const value = ADD_INFO.includes(key)
          ? getValue(item?.addInfo, key)
          : getValue(item, key);
        if (Array.isArray(filters[key]) && filters[key].includes(value)) {
          return true;
        }
        return filters[key] === value;
      });
  });
};

const getRawValue = ({ data, key }) => {
  if (ADD_INFO.includes(key) && data?.addInfo !== undefined) {
    return !data?.addInfo[key] || data?.addInfo[key] === "null"
      ? ""
      : data?.addInfo[key];
  }

  return !data[key] || data[key] === "null" ? "" : data[key];
};

export const sortData = (data, keys, reverse = false) => {
  return [...data].sort((a, b) => {
    for (const key of keys) {
      const aRawValue = getRawValue({ data: a, key });
      const bRawValue = getRawValue({ data: b, key });
      const aValue = `${aRawValue}`.toLowerCase();
      const bValue = `${bRawValue}`.toLowerCase();

      const result = (reverse ? 1 : -1) * aValue.localeCompare(bValue);
      if (result !== 0) {
        return result;
      }
    }

    return 0;
  });
};

export const getListOfItemsByKey = (
  instruments,
  key,
  getValue = (item, _key) => item[_key]
) => {
  const Sortinstruments = sortData(instruments, [key], true);
  return Sortinstruments.map((instrument) => getValue(instrument, key))
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter((v) => v);
};

export const getLocationText = (itemWithLocationDetails) => {
  const { location } = itemWithLocationDetails ?? {};
  return location && location !== "null" ? location : "-";
};

export const changeDateFormat = (inputDate, format = null) => {
  try {
    if (typeof inputDate === "string") {
      if (format === null) {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        const FormattedDateObj = new Date(currentDate);
        return FormattedDateObj;
      }
      if (format === "DateToISO") {
        const dateObj = new Date(inputDate).toISOString();

        return dateObj;
      }
      if (format === "dd-MMM-yyyy" || format === "DD-MMM-YYYY") {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        return currentDate;
      }
      if (format === "mm/dd/yyyy h:mm:ss") {
        const dateObjString = new Date(inputDate).toUTCString();
        let dateArray = inputDate.split("T");
        dateArray = dateArray[0].split("-");
        const splitDateFormat = dateObjString?.split(" ");
        const currentDate =
          parseInt(dateArray[1]) +
          "/" +
          dateArray[2] +
          "/" +
          splitDateFormat[3];
        const timeVal = inputDate.split("T");
        const time = timeVal[1].split(":");
        let hours = parseInt(time[0]);
        const seconds = time[2].slice(0, 2);
        let mid = " AM";
        if (hours === 12) {
          mid = " PM";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " PM";
          if (hours === 0) {
            mid = " AM";
          }
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        return currentDate + ", " + hours + ":" + time[1] + ":" + seconds + mid;
      }
      if (format === "h:mm") {
        let timeVal = inputDate.split("T");
        let time = timeVal[1].split(":");
        let hours = parseInt(time[0]);

        let mid = " am";
        if (hours === 12) {
          mid = " pm";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " pm";
          if (hours === 0) {
            mid = " am";
          }
        }
        return hours + ":" + time[1] + mid;
      }
      if (format === "ISOToDate") {
        const timeVal = inputDate.split("T");
        const date = timeVal[0].split("-");
        const month = parseInt(date[1]) - 1;
        const time = timeVal[1].split(":");
        const hours = parseInt(time[0]);
        const dateObj = new Date(date[2] + "-" + MONTHS[month] + "-" + date[0]);
        dateObj.setTime(dateObj.getTime() + hours * 60 * 60 * 1000);
        return dateObj;
      }
    } else {
      if (format === "EEEE, dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const weekDay = inputDate.getDay();
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone,
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return (
          DAYS[weekDay] +
          ", " +
          day +
          "-" +
          MONTHS[monthVal] +
          "-" +
          year +
          " ."
        );
      }
      if (format === "DD-MMM-YYYY" || format === "dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone,
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return day + "-" + MONTHS[monthVal] + "-" + year;
      }
      if (format === "yyyy-MM-dd") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone,
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        var month = splitDateFormat[1];
        let year = splitDateFormat[2];

        return year + "-" + month + "-" + day;
      }
      if (format === "ISO") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone,
        });

        let isoVal = inputDate.toTimeString();
        isoVal = isoVal.split(" ");
        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let month = splitDateFormat[1];
        let year = splitDateFormat[2];

        const isoString =
          year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
        return isoString;
      }
      if (format === "h:mm") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone,
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[1]?.split(":");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let hour = splitDateFormat[0].trim();
        let min = splitDateFormat[1];

        return hour + ":" + min;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const filterBookingData = (list, user) => {
  const data = [];
  list.forEach((item) => {
    if (
      item?.addInfo?.bookableForUsers?.includes(user) ||
      item?.addInfo?.isBookingControlled === false
    ) {
      data.push(item);
    }
  });
  return data;
};

const envs = ["dev", "test", "staging", "stage", "hotfix"];

export const getEnv = () => {
  if (envs?.includes(process.env?.REACT_APP_ENV)) {
    if (
      process.env?.REACT_APP_ENV === "staging" ||
      process.env?.REACT_APP_ENV === "stage"
    ) {
      return "stg";
    } else {
      return process.env?.REACT_APP_ENV;
    }
  } else {
    return null;
  }
};

export const getEnvLebel = (currentEnv) => {
  switch (currentEnv) {
    case "hotfix":
      return "Test Hotfix Environment";
    case "test":
      return "Test Environment";
    case "stg":
      return "Staging Environment";
    case "dev":
      return "Development Environment";
    default:
      return "";
  }
};

export const checkEnvReturnValue = (currentEnv, returnVal) => {
  if (
    currentEnv === "hotfix" ||
    currentEnv === "test" ||
    currentEnv === "stg" ||
    currentEnv === "dev"
  ) {
    return returnVal[0];
  } else {
    return returnVal[1];
  }
};

export const getHashCommitValue = (url) => {
  const fragments = url.split("/");
  return fragments[fragments.length - 1].toUpperCase();
};

export const scrollAvailability = () => {
  const currLocation = window.location.href.split("/");
  const scrollAvailability =
    currLocation[currLocation.length - 1] === "info" ? "auto" : "hidden";
  return scrollAvailability;
};

export const replaceNullWithHyphen = (value) => {
  return value === undefined ||
    value === null ||
    value === "null" ||
    value === ""
    ? "-"
    : value;
};

// function to sort subEquipment based PositionInCluster
export const subEquipmentSorting = (value) => {
  return value.sort((a, b) =>
    a.positionInCluster > b.positionInCluster ? 1 : -1
  );
};

export const getClusterPopoverInfoObject = ({
  item,
  itemWithBookingDetails,
  status = "",
  headerHeading = "",
  headerDetails = null,
  projectHeading = "",
  projectDetails = null,
  clusterSubEquipmentsHeading = "",
  clusterSubEquipments = [],
  detailHeading = "",
  detail = null,
  emailHREF = "",
  options = {
    hideFooter: false,
    hideHeader: false,
    hideBookingDetails: false,
    showEmailIcon: false,
  },
}) => {
  let equipmentDetail = DEFAULT_CLUSTER_POPOVER_SKELETON().equipmentDetail;
  equipmentDetail = {
    id: item?.inventoryId,
    status,
    headerHeading,
    headerDetails,
    projectHeading,
    projectDetails,
    clusterSubEquipmentsHeading,
    clusterSubEquipments,
    detailHeading,
    detail,
    emailHREF,
    options,
  };
  let clusterFlag = false;
  let clusterSubEquipmentsData = [];
  const replaceNullWithValue = (field, value) => {
    if (GXP_DROP_CHECK.includes(field)) {
      return getEnumValue(field, value);
    }
    return replaceNullWithHyphen(value);
  };

  if (equipmentDetail.projectDetails) {
    Object.keys(equipmentDetail.projectDetails).forEach((key) => {
      if (key !== "bookedBy" && key !== "bookingTime") {
        equipmentDetail.projectDetails[key].value = replaceNullWithValue(
          key,
          itemWithBookingDetails[key]
        );
      }
    });
  }

  if (clusterSubEquipments.length > 0) {
    const subEquimentSorted = subEquipmentSorting(clusterSubEquipments);
    let isTrueCluster =
      subEquimentSorted.filter((x) => {
        return x.entryType === entryType.cluster;
      }).length !== 0
        ? true
        : false;
    clusterSubEquipmentsData = subEquimentSorted.map((equipment, index) => {
      let subEquipmentRow = {};
      if (isTrueCluster) {
        for (const [key] of Object.entries(MEGA_CLUSTER_SUB_EQUIPMENT_OBJ)) {
          if (key === POPOVER_CONTROL[0]) {
            subEquipmentRow[key] = index + 1;
          } else if (key === POPOVER_CONTROL[1]) {
            const clusterIcon = (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <OwcIconButton
                  icon="rack"
                  type="legacy"
                  id={`cluster-identification-icon-${item?.inventoryId}`}
                  flat
                />
              </div>
            );
            if (equipment[key] === entryType.cluster) {
              clusterFlag = true;
            }
            subEquipmentRow[key] =
              equipment[key] === entryType.cluster ? clusterIcon : "";
          } else {
            subEquipmentRow[key] = replaceNullWithValue(key, equipment[key]);
          }
        }
      } else {
        for (const [key] of Object.entries(CLUSTER_SUB_EQUIPMENT_OBJ)) {
          if (key === "index") {
            subEquipmentRow[key] = index + 1;
          } else {
            subEquipmentRow[key] = replaceNullWithValue(key, equipment[key]);
          }
        }
      }
      return subEquipmentRow;
    });
    if (!clusterFlag) {
      clusterSubEquipmentsData = omitDeep(
        clusterSubEquipmentsData,
        "entryType"
      );
    }
    isTrueCluster
      ? (equipmentDetail.clusterSubEquipments = [
          MEGA_CLUSTER_SUB_EQUIPMENT_OBJ,
          ...clusterSubEquipmentsData,
        ])
      : (equipmentDetail.clusterSubEquipments = [
          CLUSTER_SUB_EQUIPMENT_OBJ,
          ...clusterSubEquipmentsData,
        ]);
  }

  Object.keys(equipmentDetail.detail).forEach((key) => {
    if (item.hasOwnProperty(key)) {
      equipmentDetail.detail[key].value = replaceNullWithValue(key, item[key]);
    }
  });

  return { equipmentDetail };
};

export const capitalizeStr = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getEnumValue = (fieldKey, fieldValue) => {
  const enumArray = GXPemptyInstrumentsOptions[fieldKey] || [];
  const enumObj = find(enumArray, { key: fieldValue }) || null;
  return enumObj ? enumObj?.value : "-";
};

export const filterEnumValue = (fieldKey, fieldValue) => {
  const enumArray = GXPemptyInstrumentsOptions[fieldKey] || [];
  const enumObj = find(enumArray, { key: fieldValue }) || null;
  return enumObj ? enumObj?.value : null;
};

export const getEnumQualCalibrationValue = (
  fieldKey,
  fieldValue,
  calibrationStatus
) => {
  const enumArray = GXPemptyInstrumentsOptions[fieldKey] || [];
  const enumCalArray =
    CalibrationStatusOptions["controlledEquipmentStatus"] || [];
  const enumObj = find(enumArray, { key: fieldValue }) || null;
  const enumCalObj = find(enumCalArray, { key: calibrationStatus }) || null;

  const valueEnum =
    fieldValue === "NA" && calibrationStatus === "NA"
      ? "NA"
      : fieldValue === "NA" && calibrationStatus === null
      ? "NA"
      : fieldValue === "NA" || fieldValue === null
      ? enumCalObj?.value
      : calibrationStatus === "NA" || calibrationStatus === null
      ? enumObj?.value
      : "-";

  return valueEnum;
};

export const getChipColor = (fieldValue) => {
  const chipColor =
    fieldValue === "Qualified" || fieldValue === "Calibrated"
      ? true
      : fieldValue === "Not qualified" || fieldValue === "Not calibrated"
      ? false
      : "";
  return chipColor;
};

export const convertStrToObjColumns = (savedColumn) => {
  let displayColumn =
    typeof savedColumn === "string" ? JSON.parse(savedColumn) : savedColumn;
  displayColumn = {
    ...displayColumn,
    displayDefinition:
      typeof displayColumn?.displayDefinition === "string"
        ? JSON.parse(displayColumn?.displayDefinition)
        : displayColumn?.displayDefinition,
  };

  return displayColumn;
};

export const convertToSnakeCase = (value) => {
  return value
    .replace(/([A-Z])/g, "_$1")
    .trim()
    ?.toLowerCase();
};

export const convertToCameCase = (str) => {
  if (!str?.includes("_")) {
    return str;
  }
  let arr = str.split("_");

  return arr
    .map((value, index) => {
      return index === 0
        ? value
        : value.charAt(0).toUpperCase() + value.slice(1);
    })
    ?.join("");
};
export const convertCloudToGraphQLObj = (cloudArrayObj = null) => {
  if (cloudArrayObj) {
    let rowKeys = Object.keys(cloudArrayObj);
    let obj = {};
    rowKeys.forEach((key) => {
      obj = {
        ...obj,
        [convertToCameCase(key)]: cloudArrayObj[key][0],
      };
    });
    return obj;
  } else {
    return {};
  }
};
export const sortedList = (items, fieldName = [], sortDirection = ["asc"]) => {
  return orderBy(items, [...fieldName], [...sortDirection]);
};

export const uniqList = (items, fieldName) => {
  return uniqBy(items, fieldName);
};

export const getSortedUniqueList = (list = [], columnName) => {
  return uniqList(list.map((x) => trim(x[columnName]))).sort();
};

export const getUniqValuesWithCaseInsensitive = (values) => {
  let uniqueValues = [];
  let repeatedValuesCaseInSensitive = [];
  values?.forEach((value) => {
    if (!repeatedValuesCaseInSensitive?.includes(value?.toLowerCase())) {
      uniqueValues.push(value);
      repeatedValuesCaseInSensitive.push(value?.toLowerCase());
    }
  });
  return uniqueValues;
};
export const changeDateToUtcString = (dateString) => {
  if (typeof dateString === "string") {
    const dateStringSegment = dateString.split("T");
    const dateStr = dateStringSegment[0];
    const timeStr = dateStringSegment[1].split(":")[0];
    return `${dateStr}T${timeStr}:00:00Z`;
  }
};

export const replaceOffsetWithOther = (
  dateString,
  offset,
  isEndDate = false
) => {
  if (typeof dateString === "string") {
    const dateStringSegment = dateString.split("T");
    const dateStr = dateStringSegment[0];
    const timeStr = dateStringSegment[1].split(":");
    return isEndDate
      ? `${dateStr}T23:59:00${offset}`
      : `${dateStr}T${timeStr[0]}:${timeStr[1]}:00${offset}`;
  }
};

export const formatUserList = (item) => {
  return item?.givenName && item?.familyName && item?.name
    ? `${item?.givenName} ${item?.familyName} (${item?.name})`
    : item?.givenName && item?.familyName
    ? `${item?.givenName} ${item?.familyName}`
    : item.email;
};

export const formatUserListWithoutShortId = (item) => {
  return item?.givenName && item?.familyName
    ? `${item?.givenName} ${item?.familyName}`
    : item.email;
};
export const valueOrEmpty = (
  value,
  itsADate = false,
  defaultValue = "",
  dateFormat = "dd-MMM-yyyy"
) => {
  if (value && itsADate) {
    return format(new Date(value), dateFormat);
  } else if (value && value !== "null" && !itsADate) {
    return value;
  } else {
    return defaultValue;
  }
};

export const stringOrEmpty = (
  value,
  { propLabel = "value", defaultValue = "", joinString = ", " }
) => {
  if (Array.isArray(value) && value.length > 0) {
    if (typeof value[0] === "object")
      return value.map((item) => item[propLabel]).join(joinString);
    else return value.join(joinString);
  }
  return defaultValue;
};

export const onlyUnique = (list) => {
  return uniq(list);
};

export const filterParams = {
  filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
  inRangeFloatingFilterDateFormat: "DD-MMM-YYYY",
  suppressAndOrCondition: true,
  defaultJoinOperator: "OR",
  buttons: ["reset", "apply"],
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    let cellDate = new Date(cellValue);
    if (isEqual(cellDate, filterLocalDateAtMidnight)) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};
export const getWeekStartAndEndInISOString = (
  type,
  timezone,
  currentDate = null
) => {
  if (!currentDate) {
    currentDate = moment().tz(timezone).format("YYYY-MM-DD");
  }
  return type === "start"
    ? moment(currentDate).tz(timezone).startOf("week").add(1, "day").format()
    : moment(currentDate).tz(timezone).endOf("week").add(2, "day").format();
};
export const isSameDayBooking = (startDate, endDate, timezone) => {
  const truncateStartDate = new Date(startDate)
    .toLocaleString("en-US", {
      timeZone: timezone,
    })
    .split(",")[0];
  const truncateEndDate = new Date(endDate)
    .toLocaleString("en-US", {
      timeZone: timezone,
    })
    .split(",")[0];
  return truncateStartDate === truncateEndDate;
};
