import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  Page,
  momentTimezone,
} from "@mobiscroll/react";
import { useState } from "react";
import { BACKDROP_ZINDEX, IconStatus } from "../../constants";
import BookingScheduleStatus from "../book-equipment/calendar-timeline/BookingScheduleStatus";
import {
  OwcBackdrop,
  OwcIcon,
  OwcProgressSpinner,
  OwcTypography,
} from "@one/react";
import MyBookingViewChanger from "../booking-calendars/my-booking-view-changer/MyBookingViewChanger";
import { useSelector } from "react-redux";
import PopoverBookingTitle from "../../components/shared/CommonPopOverModal/PopoverBookingTitle";
import PopOverModal from "../../components/shared/CommonPopOverModal/PopOverModal";
import useDialog from "../../utils/hooks/useDialog";
import PopoverBookingStatusDetail from "../../components/shared/CommonPopOverModal/PopoverBookingStatusDetail";
import { valueOrEmpty } from "../../utils/helpers/text";
import moment from "moment";
import { GET_BOOKING_DATA_BY_SITE } from "../../gql/bookingapi";
import { getAllData } from "../../utils/helpers/fetching";
import { withApollo } from "react-apollo";

const MainViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  flex-grow: 1;
`;

const BookingGrid = ({ viewData, setViewData, client }) => {
  const dataTimeZone = "utc";
  const [myEvents, setEvents] = useState([]);
  const user = useSelector((store) => store.user);
  const { openDialog, ...dialogProps } = useDialog();
  const [loading, setloading] = useState(false);
  const displayTimeZone = user.timezone;
  const [popoverObject, setpopoverObject] = useState({
    equipmentData: {},
    eventData: {},
  });

  const view = useMemo(() => {
    return {
      schedule: { type: "week", allDay: false },
    };
  }, []);
  const equipmentDetails = (popoverObject) => {
    let equipment = popoverObject?.equipmentData?.bookedEquipments || [];
    equipment = equipment.toString();
    return (
      <>
        <div>
          <OwcTypography variant="button" style={{ margin: "16px 0" }}>
            Equipment details
          </OwcTypography>
        </div>
        <div style={{ margin: "16px 30px" }}>
          <OwcTypography variant="button">{equipment}</OwcTypography>
        </div>
      </>
    );
  };

  const onEventClick = useCallback((event) => {
    let eventData = {
      ...event?.event,
      reservedForEmails: event?.event?.bookedUsers?.map((k) => k.email),
    };
    openDialog();
    setpopoverObject({
      equipmentData: event?.event,
      eventData: eventData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Render Custom events for timeline
   * @returns {} - template for Custom events
   */
  const renderScheduleEvent = useCallback((data) => {
    let labelColor = IconStatus?.filter((k) => k.text === data?.original?.type);
    return (
      <div
        className="md-custom-event-cont"
        style={{
          background: data?.color,
        }}
      >
        <div className="md-custom-event-wrapper">
          <div className="md-custom-event-category">
            <OwcIcon
              type="outlined"
              name={data?.original?.icon}
              style={{
                minWidth: 40,
                minHeight: 40,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid white",
                color: labelColor[0].color,
              }}
            />
            <div className="md-custom-event-title">
              <OwcTypography
                variant="badge"
                style={{ color: labelColor[0].color }}
              >
                {data?.original?.type}
              </OwcTypography>

              <div
                className="md-custom-event-time"
                style={{ color: labelColor[0].color }}
              >
                {data?.start === "" && data?.end === "" ? (
                  <> {data?.allDayText}</>
                ) : (
                  <>
                    {data?.start} - {data?.end}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="md-custom-event-details">
            <div className="md-custom-event-description">
              <OwcTypography
                variant="badge"
                style={{ color: labelColor[0].color }}
              >
                Description
              </OwcTypography>
              <span style={{ color: labelColor[0].textColor }}>
                {data?.original?.description}
              </span>
            </div>
            <div className="md-custom-event-description">
              <OwcTypography
                variant="badge"
                style={{ color: labelColor[0].color }}
              >
                Project
              </OwcTypography>
              <span style={{ color: labelColor[0].textColor }}>
                {valueOrEmpty(data?.original?.project, false, "-")}
              </span>
            </div>
            <div className="md-custom-event-description">
              <OwcTypography
                variant="badge"
                style={{ color: labelColor[0].color }}
              >
                Number of equipment
              </OwcTypography>
              <span style={{ color: labelColor[0].textColor }}>
                {data?.original?.noEquipment}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);
  /**
   * Render header for timeline
   * @returns {} - template for event header
   */
  const renderMyHeader = () => {
    return (
      <>
        <CalendarNav className="md-work-week-nav" />
        <CalendarPrev className="md-work-week-prev" />
        <CalendarToday className="md-work-week-today" />
        <CalendarNext className="md-work-week-next" />
        <div style={{ marginLeft: "auto" }}>
          <MyBookingViewChanger view={viewData} setView={setViewData} />
        </div>
      </>
    );
  };
  const onPageLoading = useCallback(
    async (event, inst) => {
      if (!loading) {
        setloading(true);
        setEvents([]);
        const dateFrom = moment(event.firstDay)
          .subtract(1, "days")
          .utc()
          .format();
        const dateTo = moment(event.lastDay).add(1, "days").utc().format();
        const { items } = await getAllData({
          client,
          query: GET_BOOKING_DATA_BY_SITE,
          dataPath: ["data", "getBookingDataBySite"],
          variables: {
            filter: JSON.stringify({
              and: [
                { active: { eq: true } },
                {
                  and: [
                    { dateFrom: { le: dateTo } },
                    { dateTo: { ge: dateFrom } },
                  ],
                },
                { reservedForUsers: { contains: user.user } },
              ],
            }),
            dateFrom: dateTo,
            site: user.site,
            limit: 1000,
            nextToken: null,
          },
        });
        const newModifiedData = items.map((item) => {
          item.noEquipment = item?.bookedEquipments.length;
          item.start = item?.dateFrom;
          item.end = item?.dateTo;
          const row = IconStatus.find((x) => x.text === item?.type);
          item.color = row.backgroundColor;
          item.icon = row.icon;
          item.title = item.type;
          return item;
        });
        setEvents(newModifiedData);
        setloading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <MainViewStyled>
        <>
          {loading && (
            <OwcBackdrop opacity={0.3} style={{ zIndex: BACKDROP_ZINDEX }}>
              <OwcProgressSpinner />
            </OwcBackdrop>
          )}
          <Page>
            <Eventcalendar
              theme="ios"
              renderHeader={renderMyHeader}
              themeVariant="light"
              clickToCreate={true}
              dragToCreate={false}
              dragToMove={false}
              dragToResize={false}
              eventDelete={false}
              data={myEvents}
              view={view}
              onEventClick={onEventClick}
              renderScheduleEvent={renderScheduleEvent}
              dataTimezone={dataTimeZone}
              displayTimezone={displayTimeZone}
              timezonePlugin={momentTimezone}
              eventOverlap={false}
              onPageLoading={onPageLoading}
            />
          </Page>
          <BookingScheduleStatus />
        </>
      </MainViewStyled>
      <PopOverModal
        title={
          Object.keys(popoverObject?.equipmentData).length > 0 && (
            <PopoverBookingTitle data={popoverObject} />
          )
        }
        closeTitle={"Close"}
        data={popoverObject}
        showEmailButton={false}
        {...dialogProps}
        onDialogCloseCallBack={() =>
          setpopoverObject({
            equipmentData: {},
            eventData: {},
          })
        }
      >
        <>
          <PopoverBookingStatusDetail
            data={popoverObject}
            dateRange={true}
            title="Event details"
          />
          {equipmentDetails(popoverObject)}
        </>
      </PopOverModal>
    </>
  );
};

export default withApollo(BookingGrid);
