import React, { useState } from 'react';
import { GroupContext } from './context';

const GroupWapper = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userList, setUserList] = useState([]);

  return (
    <GroupContext.Provider
      value={{
        dialogOpen,
        setDialogOpen,
        isEditMode,
        setIsEditMode,
        userList,
        setUserList,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export default GroupWapper;
