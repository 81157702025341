import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Search from "../../booking-instruments/Search";
import CustomList from "../../../components/shared/List";
import ReasonTableMeta from "./ReasonTableMeta";
// import PaginationActionReason from "./PaginationActionReason";
import { OwcPagination } from "@one/react";
import { PaginationContextReason } from "./PaginationContextReason";
import { getAllData } from "../../../utils/helpers/fetching";
import { LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE } from "../../../gql/bookingapi/queries";
import TopBarButtonReason from "../reasons/TopBarButtonReason";
import { withApollo } from "react-apollo";
import { compose } from "underscore";

export const TopBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: centre;
  height: 40px;
  margin: 25px 0 25px 5px;
`;

const BookingTableStyled = styled.div`
  & .row {
    background-color: #fafafa;
  }
`;
const ReasonTableStyled = styled.div`
  margin: -2px 3px 0 8px;
`;
const ReasonPaginationStyled = styled.div`
  margin-top: -16px;
`;

const AddReasonTable = ({
  reasonList,
  fetching,
  setShowPerPage,
  setPageNo,
  showPerPage,
  totalCount,
}) => {
  return (
    <>
      <div style={{ height: 328, Width: 1095 }}>
        <BookingTableStyled data-testid="booking-admin-table">
          <CustomList
            data={reasonList}
            meta={ReasonTableMeta}
            loading={fetching}
          />
        </BookingTableStyled>
        <ReasonPaginationStyled>
          <OwcPagination
            onPageChange={(e) => {
              setShowPerPage(e.detail.rowsPerPage);
              setPageNo(e.detail.page);
            }}
            options={[5, 10, 20, 50]}
            rowsPerPage={showPerPage}
            total={Math.ceil(totalCount / showPerPage)}
          >
            <span slot="rows-per-page">Rows per page</span>
          </OwcPagination>
        </ReasonPaginationStyled>
      </div>
    </>
  );
};
const TopBar = ({ setQuery, label, "data-testid": dataTestId, children }) => {
  return (
    <TopBarStyled>
      <Search
        setQuery={setQuery}
        label={label}
        data-testid={dataTestId}
        rectSearch={true}
      />
      {children}
      <TopBarButtonReason />
    </TopBarStyled>
  );
};

export const TopBarCancelButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 5px;
`;

const ReasonContent = ({ client, updateReasonsList }) => {
  const { fetching, limit, nextData, refreshData } = useContext(
    PaginationContextReason
  );

  const [reasonList, setReasonList] = useState([]);
  const [searchReason, setSearchReason] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [showPerPage, setShowPerPage] = useState(5);

  const getReasonList = async () => {
    let variables = {
      filter: { configText: { contains: searchReason } },
    };
    const { items } = await getAllData({
      client,
      query: LIST_CONFIGURATION_BY__MODEL_AND_CREATED_DATE,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "configurationByModelAndCreatedDate"],
    });
    setTotalCount(items.length);
    const lowIndex = pageNo === 1 ? 0 : (pageNo - 1) * showPerPage;
    const highIndex = pageNo === 1 ? showPerPage : lowIndex + showPerPage;
    const paginationData = items.slice(lowIndex, highIndex);
    setReasonList(paginationData);
  };

  useEffect(() => {
    getReasonList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReasonList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchReason, limit, nextData, refreshData, pageNo, showPerPage]);

  return (
    <>
      <TopBar label={"Search for reason"} setQuery={setSearchReason}></TopBar>

      <ReasonTableStyled>
        <AddReasonTable
          fetching={fetching}
          reasonList={reasonList}
          setShowPerPage={setShowPerPage}
          setPageNo={setPageNo}
          showPerPage={showPerPage}
          totalCount={totalCount}
        />
      </ReasonTableStyled>
    </>
  );
};
// export default ReasonContent;
export default compose(withApollo)(ReasonContent);
