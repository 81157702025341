import {
  Eventcalendar,
  Datepicker,
  CalendarNav,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  setOptions,
  formatDate,
  toast,
} from "@mobiscroll/react";
import { useRef, useState, useCallback, useEffect, useContext } from "react";
import {
  OwcBadge,
  OwcIcon,
  OwcTypography,
  OwcListItem,
  OwcSelectDropdown,
  OwcIconButton,
} from "@one/react";
import "./CalendarTimeline.scss";
import {
  BOOKING_TYPE,
  TIMELINE_TYPE,
  singleEventSkeleton,
  EVENT,
  RESOURCE,
  DAY_CONFIG,
  WEEK_CONFIG,
  WORK_WEEK_CONFIG,
  IconStatus,
} from "../../../constants";
import { momentTimezone } from "@mobiscroll/react";
import moment from "moment-timezone";
import {
  changeDateToUtcString,
  getEnv,
  isSameDayBooking,
} from "../../../utils/helpers/text";
import BookingScheduleStatus from "./BookingScheduleStatus";
import { cloneDeep, sortBy, uniqueId } from "lodash";
import { BookingContext, BookingPopUpContext } from "../../booking/context";
import { useFormikContext } from "formik";
import {
  getAllData,
  getContainerHeight,
} from "../../../utils/helpers/fetching";
import { withApollo } from "react-apollo";
import { FIND_BOOKABLE_INSTRUMENTS } from "../../../gql/bookingapi";
import { eachDayOfInterval } from "date-fns";
import { CustomFilterContext } from "../../booking-instruments/booking-instruments-custom-filter/context";

// setup Mobiscroll Moment plugin
momentTimezone.moment = moment;

setOptions({
  theme: "material",
  themeVariant: "light",
});

const CustomEventCalendar = ({
  eventData,
  resourceData,
  visibleDatePicker = true,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  dataTimeZone,
  displayTimeZone,
  clearPendingReservation,
  updatePendingReservation,
  removeEquipmentAndReservation,
  pendingReservations,
  getReservationBooking,
  getEventDetails,
  setloading,
  setEventsData,
  loading,
  view,
  setView,
  calView,
  setCalView,
  manipulatePopoverContent,
  siteTimezone,
  newEquipmentIds,
  setNewEquipmentIds,
  getLatestEventDetailsOnly,
  setCancelStatus,
  setDeleteResourceId,
  client,
}) => {
  const { isEditMode, editBookingData, isOngoing } = useContext(BookingContext);
  const { dispatchAction: bookingPopUpDispatchAction } =
    useContext(BookingPopUpContext);
  const { selectedList } = useContext(CustomFilterContext);

  const formik = useFormikContext();
  const calendarRef = useRef(null);
  const [timelineSelectedDate, setTimelineSelectedDate] = useState(null);
  const [eventHover, setEventHover] = useState(null);
  const [rangeInst, setRangeInst] = useState();
  const [colorsData, setColorsData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);
  const currentEnv = getEnv();
  const [disableDateList, setDisableDateList] = useState([]);
  const remainingHeight = 242;
  const [containerHeight, setContainerHeight] = useState(
    getContainerHeight(currentEnv, remainingHeight)
  );
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));
  });
  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      if (isEditMode) {
        setTimelineSelectedDate(
          moment(selectedStartDate).tz(siteTimezone).format()
        );
      } else {
        setTimelineSelectedDate(selectedStartDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Change the view of timeline
   * @param {any} view - selected view
   * @returns {} - setView & setCalView
   */
  const changeView = (view) => {
    let calView;

    switch (view) {
      case TIMELINE_TYPE.DAY:
        calView = DAY_CONFIG;
        break;
      case TIMELINE_TYPE.WORKWEEK:
        calView = WORK_WEEK_CONFIG;
        break;
      case TIMELINE_TYPE.WEEK:
        calView = WEEK_CONFIG;
        break;
      default:
        calView = DAY_CONFIG;
        break;
    }

    setView(view);
    setCalView(calView);
  };

  /**
   * Render header for timeline
   * @returns {} - template for event header
   */
  const renderMyHeader = () => {
    return (
      <>
        <CalendarNav className="md-work-week-nav" />
        <CalendarPrev className="md-work-week-prev" />
        <CalendarToday className="md-work-week-today" />
        <CalendarNext className="md-work-week-next" />
        <div style={{ marginLeft: "auto" }}>
          <OwcSelectDropdown
            style={{ width: 154 }}
            size="compact"
            outlined
            onSelectChange={(ev) => {
              changeView(
                ev.detail[0] === 1
                  ? TIMELINE_TYPE.WEEK
                  : ev.detail[0] === 0
                  ? TIMELINE_TYPE.DAY
                  : view
              );
            }}
          >
            <OwcListItem selected={view === TIMELINE_TYPE.DAY}>Day</OwcListItem>
            <OwcListItem selected={view === TIMELINE_TYPE.WEEK}>
              Week
            </OwcListItem>
          </OwcSelectDropdown>
        </div>
      </>
    );
  };

  // FIXME: This medthod should return a class when avaiable events are rendered
  /**
   * To apply css class dynamically for events
   * @param {events} event - has event details for that hour
   * @returns {} - eventOccurrence
   */
  const getHourStyle = useCallback(
    (events) => {
      let hourEventClass = {};
      events.forEach((element) => {
        if (
          isEditMode &&
          (element.bookingId === editBookingData.id ||
            element.bookingId === null)
        ) {
          const row = IconStatus.find((x) => x.text === element.type);
          if (row !== undefined) {
            hourEventClass = { background: row.backgroundColor };
          }
        } else {
          if (element.type === "Available") {
            const row = IconStatus.find((x) => x.text === element.type);
            if (row !== undefined) {
              hourEventClass = { background: row.backgroundColor };
            }
          }
        }
      });
      return hourEventClass;
    },
    [isEditMode, editBookingData]
  );

  /**
   * Change the date of timeline on the selected value in datepicker
   * @param {any} param - selected Date and time
   * @returns {} - set date and time in timeline
   */
  const handleDateChange = useCallback(
    async (param) => {
      if (param?.value) {
        const dateArray = param.value;
        const startDate = dateArray[0];
        const endDate = dateArray[1];
        let onGoingEventList = [];
        if (startDate && endDate) {
          if (isEditMode && isOngoing) {
            onGoingEventList = pendingReservations.filter(
              (x) => x.isOngoingEventFlag === true
            );
          }
          clearPendingReservation();
          setloading(true);
          setSelectedEndDate(endDate);
          setSelectedStartDate(startDate);
          const reservationData = await getReservationBooking(
            changeDateToUtcString(startDate),
            changeDateToUtcString(endDate)
          );

          getEventDetails(
            changeDateToUtcString(startDate),
            changeDateToUtcString(endDate),
            [...reservationData, ...onGoingEventList],
            onGoingEventList
          );

          setTimelineSelectedDate(moment(startDate).tz(siteTimezone).format());
          const truncateStartDate = new Date(startDate)
            .toLocaleString("en-US", {
              timeZone: displayTimeZone,
            })
            .split(",")[0];
          const truncateEndDate = new Date(endDate)
            .toLocaleString("en-US", {
              timeZone: displayTimeZone,
            })
            .split(",")[0];
          if (truncateStartDate === truncateEndDate) {
            changeView(TIMELINE_TYPE.DAY);
          } else {
            changeView(TIMELINE_TYPE.WEEK);
          }
          setloading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStartDate, selectedEndDate, pendingReservations]
  );

  /**
   * call when date change in timeline
   * @param {any} event  - selected Date
   * @param {any} inst  - selected instance
   * @returns {} - set date and time in timeline
   */
  const onSelectedDateChange = useCallback((event, inst) => {
    setTimelineSelectedDate(event.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageLoading = useCallback(
    async (event, inst) => {
      if (!loading) {
        setloading(true);
        setEventsData([]);
        const timelineStartDay = event.firstDay.toISOString();
        const timelineEndDay = event.lastDay.toISOString();
        const reservationData = await getReservationBooking(
          timelineStartDay,
          timelineEndDay
        );

        if (
          newEquipmentIds.length > 0 &&
          selectedEndDate &&
          selectedStartDate
        ) {
          getLatestEventDetailsOnly(
            isOngoing && isEditMode
              ? moment.tz(siteTimezone).startOf("hour").utc().format()
              : changeDateToUtcString(selectedStartDate),
            changeDateToUtcString(selectedEndDate),
            reservationData,
            newEquipmentIds
          );
          setNewEquipmentIds([]);
          setloading(false);
          return false;
        }

        setEventsData([...reservationData, ...pendingReservations]);
        setloading(false);
      }
      //await getReservationBooking(timelineStartDay, timelineEndDay);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingReservations, loading]
  );

  /**
   * Render Custom Hour for timeline
   * @returns {} - template for Custom Hour
   */
  const renderCustomHour = (args) => {
    return (
      <div className={"md-date-header-hour "} style={getHourStyle(args.events)}>
        {formatDate("h:mm A", args.date)}
      </div>
    );
  };

  /**
   * Render event icons based on booking type
   * @param {string} type  - Event type
   * @returns {} - icons for events
   */
  const renderBookingIcon = (type) => {
    switch (type) {
      case BOOKING_TYPE.BOOKED:
        return "schedule_on";
      case BOOKING_TYPE.RESERVED:
        return "order_approval";
      case BOOKING_TYPE.MAINTENANCE:
        return "test_result";
      case BOOKING_TYPE.AVAILABLE:
        return "confirm";
      default:
        return "confirm";
    }
  };

  /**
   * sets or update date picker
   */
  const updateDatePicker = (pendingReservations) => {
    const starts = sortBy(pendingReservations, ["start"]);
    const ends = sortBy(pendingReservations, ["end"]);

    setSelectedStartDate(starts[0]?.start);
    setSelectedEndDate(ends[ends?.length - 1]?.end);
  };

  /**
   * Render Custom events for timeline
   * @returns {} - template for Custom events
   */
  const renderCustomScheduleEvent = (data) => {
    const bookingId = data.original?.bookingId;
    //const isEditEventFlag = data.original.isEditEventFlag;
    const type = data.original.type;
    const fontColor = data.original.fcolor ?? "#0B41CD";
    const backgroundColor = data.original.color ?? "#D7E0F7";
    const borderColor = isEditMode
      ? (bookingId === editBookingData.id || bookingId === null) &&
        (data?.original?.fcolor ?? "#0B41CD")
      : type === "Available" && "#FBFBFA";

    return (
      <div
        className="md-timeline-template-event"
        style={{
          borderColor: borderColor,
          background: backgroundColor,
          boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.40)",
        }}
        onMouseEnter={() =>
          isEditMode
            ? setEventHover(
                bookingId === editBookingData.id || bookingId === null
                  ? data.original.id ?? null
                  : null
              )
            : setEventHover(bookingId ? null : data?.id)
        }
        onMouseLeave={() => setEventHover(null)}
      >
        <div
          className="md-timeline-template-event-cont"
          onClick={(e) => {
            if (bookingId) {
              manipulatePopoverContent({
                userSelectionType: EVENT,
                eventId: data?.id,
              });
            }
          }}
        >
          <OwcIcon
            className="bookingIcon one-icons-outlined"
            style={{
              color: fontColor,
              border: borderColor,
              fontSize: 16,
            }}
            value={data.id}
            key={data.id}
            name={
              eventHover && data.id === eventHover
                ? "clear"
                : renderBookingIcon(type)
            }
            onClick={(ev) => {
              if (isEditMode) {
                if (data.original.isOngoingEventFlag) {
                  bookingPopUpDispatchAction({
                    type: "cancelEvents",
                    payload: {
                      onApprove: handleOnClose,
                    },
                  });
                } else {
                  if (bookingId === editBookingData.id || bookingId === null) {
                    eventSlotDelete(data.id);
                  }
                }
              } else {
                if (bookingId === null) {
                  eventSlotDelete(data.id);
                }
              }
              ev.stopPropagation();
            }}
          />
          <span
            className="md-timeline-template-time"
            style={{ color: fontColor }}
          >
            {type}
          </span>
          <span
            className="md-timeline-template-title"
            style={{ color: fontColor }}
          >
            {data.start} - {data.end}
          </span>
        </div>
      </div>
    );
  };
  //old code for render event
  // const renderCustomScheduleEvent1 = (data) => {
  //   const color = data.original.color ?? "#D7E0F7";
  //   const foregroundColor = data.original.fcolor ?? "#0B41CD";
  //   const type = data.original.type;
  //   const isEditEventFlag = data.original.isEditEventFlag;
  //   const bookingId = data.original.bookingId;
  //   return isEditEventFlag ? (
  //     <div
  //       className="md-timeline-template-event"
  //       style={{
  //         borderColor: foregroundColor,
  //         background: color,
  //         boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.40)",
  //       }}
  //       onMouseEnter={() =>
  //         setEventHover(data.original.editable ? data?.id : null)
  //       }
  //       onMouseLeave={() => setEventHover(null)}
  //     >
  //       <div
  //         className="md-timeline-template-event-cont"
  //         onClick={(e) => {
  //           if (data.original.editable) {
  //             manipulatePopoverContent({
  //               userSelectionType: EVENT,
  //               eventId: data?.id,
  //             });
  //           }
  //         }}
  //       >
  //         <OwcIcon
  //           className="bookingIcon one-icons-outlined"
  //           style={{
  //             color: foregroundColor,
  //             border: `1px solid ${foregroundColor}`,
  //             fontSize: 16,
  //           }}
  //           value={data.id}
  //           key={data.id}
  //           name={
  //             eventHover && data.id === eventHover
  //               ? "clear"
  //               : renderBookingIcon(type)
  //           }
  //           onClick={(ev) => {
  //             if (isEditMode && data.original.isOngoingEventFlag) {
  //               bookingPopUpDispatchAction({
  //                 type: "cancelEvents",
  //                 payload: {
  //                   onApprove: handleOnClose,
  //                 },
  //               });
  //             }
  //             if (!data.original.isOngoingEventFlag) {
  //               eventSlotDelete(data.id);
  //             }
  //             ev.stopPropagation();
  //           }}
  //         />
  //         <span
  //           className="md-timeline-template-time"
  //           style={{ color: foregroundColor }}
  //         >
  //           {type}
  //         </span>
  //         <span
  //           className="md-timeline-template-title"
  //           style={{ color: foregroundColor }}
  //         >
  //           {data.start} - {data.end}
  //         </span>
  //       </div>
  //     </div>
  //   ) : (
  //     <div
  //       className="md-timeline-template-event"
  //       style={{
  //         borderColor: isEditMode
  //           ? foregroundColor
  //           : type === "Available" && "#FBFBFA",
  //         background: color,
  //         boxShadow:
  //           type === isEditMode
  //             ? "0px 1px 3px 0px rgba(0, 0, 0, 0.40)"
  //             : "Available" && "0px 1px 3px 0px rgba(0, 0, 0, 0.40)",
  //       }}
  //       onMouseEnter={() => setEventHover(bookingId ? null : data?.id)}
  //       onMouseLeave={() => setEventHover(null)}
  //     >
  //       <div
  //         className="md-timeline-template-event-cont"
  //         onClick={(e) => {
  //           if (data?.original?.bookingId !== null) {
  //             manipulatePopoverContent({
  //               userSelectionType: EVENT,
  //               eventId: data?.id,
  //             });
  //           }
  //         }}
  //       >
  //         <OwcIcon
  //           className="bookingIcon one-icons-outlined"
  //           style={{
  //             color: foregroundColor,
  //             border: isEditMode
  //               ? `1px solid ${foregroundColor}`
  //               : "1px solid #FBFBFA",
  //             fontSize: 16,
  //           }}
  //           value={data.id}
  //           key={data.id}
  //           name={
  //             eventHover && data.id === eventHover
  //               ? "clear"
  //               : renderBookingIcon(type)
  //           }
  //           onClick={(ev) => {
  //             if (bookingId === null) {
  //               eventSlotDelete(data.id);
  //             }
  //             ev.stopPropagation();
  //           }}
  //         />
  //         <span
  //           className="md-timeline-template-time"
  //           style={{ color: foregroundColor }}
  //         >
  //           {type}
  //         </span>
  //         <span
  //           className="md-timeline-template-title"
  //           style={{ color: foregroundColor }}
  //         >
  //           {data.start} - {data.end}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };
  // old code for render event
  /**
   * To handle overlap of events in time line
   * @param {any} event - args of created event
   * @param {inst} inst - instanse of the event calendar
   * @returns {} - boolean based on filterred events length
   */
  const hasOverlap = useCallback((event, inst) => {
    const events = inst
      .getEvents(event.start, event.end)
      .filter((e) => e.id !== event.id && e.resource === event.resource);
    return events.length > 0;
    // eslint-disable-next-line
  }, []);
  /**
   * To handle past  events in time line
   * @param {any} args - args of created event
   * @returns {} - boolean based on filterred events length
   */
  const hasPastEvent = useCallback((args) => {
    return (
      moment.tz(siteTimezone).startOf("hour").format() >
      moment(args.event.start).tz(siteTimezone).format()
    );
    //return new Date().getTime() > new Date(args.event.start).getTime();
    // eslint-disable-next-line
  }, []);
  /**
   * To handle past  events in time line
   * @param {any} args - args of created event
   * @returns {} - boolean based on filterred events length
   */
  const hasPastEndDate = useCallback(
    (args) => {
      return (
        moment.tz(siteTimezone).startOf("hour").format() >
        moment(args.event.end).tz(siteTimezone).format()
      );
      // eslint-disable-next-line
    },
    [siteTimezone]
  );

  const popupText = (
    <OwcTypography>
      Please select a valid date range in the future. <br />
      Adjusting past dates/time is not allowed.
    </OwcTypography>
  );

  const handleOnClose = () => {
    bookingPopUpDispatchAction({
      type: "reset",
    });
  };
  /**
   * to render the model name with close icon in mobiscroll calendar
   * @returns {component} - model name with cross icon
   */
  const customResource = (resource) => {
    return (
      <div className="md-resource-header-template-cont">
        <div className="md-resource-header-template-name">
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => {
              manipulatePopoverContent({
                userSelectionType: RESOURCE,
                resourceId: resource?.id,
              });
            }}
          >
            <OwcTypography
              variant="body2"
              style={{
                marginRight: "10px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {resource.name}
            </OwcTypography>
            <OwcIconButton
              icon="circle_clear"
              type="filled"
              flat
              style={{ marginLeft: "auto" }}
              onClick={(ev) => {
                const isAnyOnGoingEvent = pendingReservations.filter(
                  (x) => x.resource === resource?.id && x.isOngoingEventFlag
                );
                if (isEditMode && isAnyOnGoingEvent.length > 0) {
                  bookingPopUpDispatchAction({
                    type: "onGoingBooking",
                    payload: {
                      onApprove: handleOnClose,
                    },
                  });
                } else {
                  bookingPopUpDispatchAction({
                    type: "futureBooking",
                    payload: {
                      onApprove: () => handlerOnDelete(resource?.id),
                      onclose: handleOnClose,
                    },
                  });
                }
                setDeleteResourceId(resource?.id);
                setCancelStatus(true);
                ev.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  /**
   * to show the number of selected events for each equipment
   * @param {array} resourceData - array of resources Data
   * @returns {component} - show the number of selected events with model header
   */
  const customResourceHeader = () => {
    return (
      <div className="md-resource-header-template-title">
        <div className="md-resource-header-template-name">
          <div style={{ display: "flex", alignItems: "center" }}>
            <OwcTypography variant="body2" style={{ marginRight: "10px" }}>
              Equipment details
            </OwcTypography>
            <OwcBadge
              type="active"
              style={{
                marginLeft: "auto",
                width: "24px",
                height: "24px",
              }}
            >
              {resourceData?.length}
            </OwcBadge>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Event triggered when a timeline event is created
   * @param {any} args - args of created event
   * @param {inst} args - instanse of the event calendar
   * @returns {} - false if validation fails
   */
  const onEventCreate = useCallback(
    (args, inst) => {
      if (hasPastEvent(args)) {
        toast({
          message: "Past date not allowed",
        });
        return false;
      }
      if (hasOverlap(args.event, inst)) {
        toast({
          message: "Overlap not allowed",
        });

        return false;
      }
      let eventRow = {
        ...singleEventSkeleton,
        start: changeDateToUtcString(args.event.start),
        end: changeDateToUtcString(args.event.end),
        resource: args.event.resource,
        id: uniqueId("available_"),
      };
      if (isEditMode) {
        const row = IconStatus.find(
          (x) => x.text === formik?.values?.bookingType
        );
        if (row !== undefined) {
          eventRow = {
            ...eventRow,
            type: formik?.values?.bookingType,
            color: row.backgroundColor,
            fcolor: row.color,
            title: formik?.values?.bookingType,
          };
        }
      }

      setEventsData((prev) => [...prev, eventRow]);
      updateDatePicker([...pendingReservations, eventRow]);
      updatePendingReservation([...pendingReservations, eventRow]);
      return false;
    },
    // eslint-disable-next-line
    [pendingReservations, selectedStartDate, selectedEndDate]
  );
  /**
   * Event triggered when a timeline event is updated
   * @param {any} args - args of created event
   * @param {inst} args - instanse of the event calendar
   * @returns {} - false if validation fails
   */
  const onEventUpdate = useCallback(
    (args, inst) => {
      if (hasOverlap(args.event, inst)) {
        toast({
          message: "Overlap not allowed",
        });

        return false;
      }
      if (isEditMode && args.event.isOngoingEventFlag) {
        if (
          moment(args.event.start).tz(siteTimezone).format() !==
          moment(args.oldEvent.start).tz(siteTimezone).format()
        ) {
          toast({
            message: "Can't change start date.",
          });
          return false;
        }
        if (hasPastEndDate(args)) {
          bookingPopUpDispatchAction({
            type: "eventBooking",
            payload: {
              onApprove: handleOnClose,
              title: "Date range adjustment not permitted",
              content: popupText,
            },
          });
          return false;
        }
      } else {
        if (hasPastEvent(args)) {
          toast({
            message: "Past date not allowed",
          });
          return false;
        }
      }

      const tempReservation = pendingReservations.map((item) => {
        if (item.id === args.event.id) {
          item = {
            ...item,
            start: changeDateToUtcString(args.event.start),
            end: changeDateToUtcString(args.event.end),
            resource: args.event.resource,
          };
        }
        return item;
      });

      updateDatePicker(tempReservation);

      updatePendingReservation([...tempReservation]);
    },
    // eslint-disable-next-line
    [pendingReservations, selectedEndDate]
  );
  /**
   * Event triggered when a timeline event is deleted
   * @param {any} args - args of created event
   * @param {inst} args - instanse of the event calendar
   * @returns {} - false if validation fails
   */
  const onEventDeleted = useCallback(
    (args) => {
      const tempReservation = pendingReservations.filter(
        (item) => item.id !== args.event.id
      );
      updateDatePicker(tempReservation);
      updatePendingReservation(tempReservation);
      setEventsData((prev) => {
        const remainingEvents = prev.filter(
          (item) => item.id !== args.event.id
        );
        return remainingEvents;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingReservations]
  );

  /**
   * Event triggered when a user manually delete event
   * @param {id} args - args of created event
   * @returns {} - false if validation fails
   */
  const eventSlotDelete = useCallback(
    (id) => {
      onEventDeleted({ event: { id: id } });
      setEventsData((prev) => prev.filter((x) => x.id !== id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingReservations]
  );
  // relevant events

  const handlerOnDelete = (deleteResourceId) => {
    handleOnClose();
    removeEquipmentAndReservation(deleteResourceId, pendingReservations);
  };
  const hanldeActiveDateChange = (ev) => {
    if (ev.active === "start" && isOngoing) {
      setTimeout(() => {
        rangeInst.setActiveDate("end");
      });
    }
  };
  const updateLoaderinDatePicker = (event) => {
    const firstDay = event.firstDay;
    const lastDay = event.lastDay;
    const dateList = eachDayOfInterval({
      start: firstDay,
      end: lastDay,
    });
    const tempLabelsData = dateList.map((item) => {
      return {
        date: `${moment(item).format("YYYY-MM-DD")}T00:00`,
        text: "...",
        color: "white",
      };
    });
    setLabelsData(tempLabelsData);
    setColorsData([]);
  };
  const getAvailability = async (ev) => {
    let startDate = moment.tz(siteTimezone).isAfter(moment(ev.firstDay))
      ? moment.tz(siteTimezone).startOf("day")
      : moment(ev.firstDay).startOf("day");
    let endDate = `${moment(startDate.format("YYYY-MM-DD"))
      .endOf("month")
      .format("YYYY-MM-DD")}T23:59:59Z`;

    let originalStartDate = null;
    let originalEndDate = null;
    let originalStartDateString = null;
    let originalEndDateString = null;
    let isDateSame = false;
    if (isEditMode) {
      originalStartDateString = moment(editBookingData?.dateFrom)
        .tz(siteTimezone)
        .format("YYYY-MM-DD");
      originalEndDateString = moment(editBookingData?.dateTo)
        .tz(siteTimezone)
        .format("YYYY-MM-DD");
      originalStartDate = editBookingData?.dateFrom;
      originalEndDate = editBookingData?.dateTo;
      isDateSame = isSameDayBooking(
        originalStartDate,
        originalEndDate,
        siteTimezone
      );
    }
    const months = [];
    let count = 0;
    while (count < 3) {
      months.push({
        start: `${startDate.format("YYYY-MM-DD")}T00:00`,
        end: endDate,
      });
      startDate.add(1, "month").startOf("month");
      endDate = `${moment(startDate.format("YYYY-MM-DD"))
        .endOf("month")
        .format("YYYY-MM-DD")}T23:59:59Z`;
      count++;
    }

    let tempResult = [];
    await Promise.all(
      months.map(async (item) => {
        const { items } = await getAllData({
          client,
          query: FIND_BOOKABLE_INSTRUMENTS,
          drillData: true,
          fetchPolicy: "no-cache",
          variables: {
            siteTimezone: siteTimezone,
            startDate: item.start,
            endDate: item.end,
            inventoryIds: selectedList,
          },
          dataPath: ["data", "findBookableInstruments"],
        });
        tempResult.push(...items);
      })
    );
    let tempResultColorsData = [];
    let tempResultLablesData = [];
    let tempDisableDateList = [];

    tempResult.forEach((item) => {
      if (item.availability === "NONE") {
        // for disabling date
        const localDate = cloneDeep(item.date);
        if (isOngoing) {
          tempDisableDateList.push({
            start: moment(originalStartDate)
              .tz(siteTimezone)
              .add(1, "minutes")
              .utc()
              .format(),
            end: moment.tz(siteTimezone).startOf("hour").utc().format(),
          });
        }

        if (isEditMode) {
          if (localDate === originalStartDateString) {
            tempResultColorsData.push({
              date: localDate,
            });
            if (!isDateSame) {
              tempDisableDateList.push({
                start: moment(originalStartDate)
                  .tz(siteTimezone)
                  .add(1, "hours")
                  .utc()
                  .format(),
                end: moment(originalStartDate)
                  .tz(siteTimezone)
                  .endOf("day")
                  .utc()
                  .format(),
              });
            }
          } else if (localDate === originalEndDateString && !isDateSame) {
            tempResultColorsData.push({
              date: localDate,
            });
            tempDisableDateList.push({
              start: moment(originalEndDate)
                .tz(siteTimezone)
                .add(1, "hours")
                .utc()
                .format(),
              end: moment(originalEndDate)
                .tz(siteTimezone)
                .endOf("day")
                .utc()
                .format(),
            });
          } else {
            tempResultColorsData.push({
              date: localDate,
            });
            tempDisableDateList.push(localDate);
          }
        } else {
          tempResultColorsData.push({
            date: item.date,
          });
          tempDisableDateList.push(localDate);
        }
      }
      if (item.availability !== "ALL" && item.availability !== "NONE") {
        tempResultLablesData.push({
          date: item.date,
          text: item.availability,
          color: "white",
          textColor: "#ED4A0D",
          cellCssClass: "custom-label-text",
        });
      }
    });

    setColorsData(tempResultColorsData);
    setLabelsData(tempResultLablesData);
    setDisableDateList(tempDisableDateList);
  };
  const onPageLoadingInDatePicker = useCallback(
    async (event, inst) => {
      const tempValue = inst.getTempVal();

      setSelectedStartDate(tempValue[0]);
      setSelectedEndDate(tempValue[1]);

      updateLoaderinDatePicker(event);
      await getAvailability(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStartDate]
  );

  return (
    <>
      {visibleDatePicker && (
        <div
          className="customeventcalendar_datepickerInputStyle"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Datepicker
            id="datepicker-from-my-booking"
            controls={["calendar", "timegrid"]}
            ref={setRangeInst}
            labels={labelsData}
            colors={colorsData}
            onPageLoading={onPageLoadingInDatePicker}
            onInit={(event, inst) => {
              // check cond if it needs to be opened
              if (!(selectedStartDate && selectedEndDate)) {
                inst.open();
              }
            }}
            value={[selectedStartDate, selectedEndDate]}
            inputStyle="outline"
            select="range"
            display="anchored"
            inRangeInvalid={isEditMode}
            rangeEndInvalid={isEditMode}
            headerText="Experiment date(range)"
            stepMinute={60}
            touchUi={"auto"}
            min={
              isEditMode
                ? isOngoing
                  ? selectedStartDate
                  : moment.tz(siteTimezone).startOf("hour").format()
                : moment.tz(siteTimezone).startOf("hour").format()
            }
            minRange={3600000}
            dateFormat="D-MMM-YYYY"
            startIcon={<OwcIcon name="date" type="outlined" />}
            onClose={(param) => {
              if (
                param?.value &&
                (moment(param?.value[0]).utc().format() !==
                  moment(selectedStartDate).utc().format() ||
                  moment(param?.value[1]).utc().format() !==
                    moment(selectedEndDate).utc().format())
              ) {
                if (isEditMode) {
                  const isDateChange = !(
                    moment(selectedStartDate).utc().format() ===
                      moment(param?.value[0]).utc().format() &&
                    moment(selectedEndDate).utc().format() ===
                      moment(param?.value[1]).utc().format()
                  );
                  if (!isDateChange || param?.value[0] === param?.value[1]) {
                    return false;
                  }
                  if (
                    isOngoing &&
                    moment(param?.value[1]).tz(siteTimezone).utc().format() <
                      moment.tz(siteTimezone).startOf("hour").utc().format()
                  ) {
                    return false;
                  }
                  if (isOngoing) {
                    handleDateChange(param);
                  } else {
                    bookingPopUpDispatchAction({
                      type: "eventBooking",
                      payload: {
                        onApprove: () => handleDateChange(param),
                        title: "Experiment range changed",
                        content: `You have selected a new experiment range.
                                  Please be aware that your old equipment timeslots are removed.`,
                        onclose: handleOnClose,
                      },
                    });
                  }
                } else {
                  handleDateChange(param);
                }
              } else {
                setSelectedStartDate(
                  param?.value[1] ? param?.value[0] : param?.value[1]
                );
                setSelectedEndDate(param?.value[1]);
              }
            }}
            onCancel={() => {
              if (!timelineSelectedDate) {
                setTimelineSelectedDate(moment().tz(siteTimezone).format());
              }
            }}
            timezonePlugin={momentTimezone}
            dataTimezone={dataTimeZone}
            displayTimezone={displayTimeZone}
            buttons={["cancel", "set"]}
            onActiveDateChange={hanldeActiveDateChange}
            invalid={disableDateList}
          />
        </div>
      )}

      <Eventcalendar
        theme="ios"
        height={containerHeight}
        view={calView}
        data={eventData}
        resources={resourceData}
        renderHeader={renderMyHeader}
        renderResource={customResource}
        renderResourceHeader={customResourceHeader}
        renderHour={renderCustomHour}
        renderScheduleEvent={renderCustomScheduleEvent}
        ref={calendarRef}
        cssClass="md-timeline-template"
        clickToCreate={true}
        dragToCreat={true}
        dragToMove={true}
        dragToResize={true}
        dragTimeStep={60}
        selectedDate={timelineSelectedDate ?? new Date()}
        onSelectedDateChange={onSelectedDateChange}
        onPageLoading={onPageLoading}
        onEventCreate={onEventCreate}
        onEventUpdate={onEventUpdate}
        onEventDeleted={onEventDeleted}
        dataTimezone={dataTimeZone}
        displayTimezone={displayTimeZone}
        timezonePlugin={momentTimezone}
        eventOverlap={false}
      />
      <BookingScheduleStatus />
    </>
  );
};
export default withApollo(CustomEventCalendar);
