import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import App from "./App";
import Authentication from "./features/Authentication";
import { Route, Switch } from "react-router-dom";
import NotFoundScreen from "./components/NotFoundScreen";
import { federated } from "./aws-exports";
import ModuleVersion from "./features/system-version/ModuleVersion";
import { OwcThemeProvider } from "@one/react";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "CompanyName=F. Hoffmann-La Roche AG,LicensedGroup=Roche-DigitalLab,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-037360,SupportServicesEnd=15_February_2024_[v2]_MTcwNzk1NTIwMDAwMA==3d1de6a5550abcadfbedb4c5dae4d903"
);
/**
 * to turn off google login button - remove federated authenticatorProps below
 */
const AuthenticatedApp = withAuthenticator(App, { federated: federated });

const Main = () => {
  return (
    <OwcThemeProvider theme="cobas_light">
      <Switch>
        <Route
          exact
          path={[
            "/",
            "/bookings",
            "/scan",
            "/instrumentDetailedInfo/:inventoryId?",
            "/admin",
            "/equipmentList/:InventoryId",
          ]}
        >
          <Authentication>
            <AuthenticatedApp />
          </Authentication>
        </Route>
        <Route exact path="/info">
          <ModuleVersion />
        </Route>
        <Route path="*">
          <NotFoundScreen redirectPath={"/"} />
        </Route>
      </Switch>
    </OwcThemeProvider>
  );
};
export default Main;
