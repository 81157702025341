import React from "react";
import styled from "styled-components";
import InputSelectType from "./InputSelectType";
import { OwcButton, OwcModalDialog } from "@one/react";

const IdentificationForm = styled.div`
  & > .selectBoxLarge {
    margin: 0 20px 30px 20px;
    width: 418px;
    padding-left: 24px;
    padding-bottom: 20px;
  }
  & > .TextFieldBoxLarge {
    margin: 0 20px 5px 20px;
    width: 418px;
    padding-left: 24px;
  }
`;

const ActionButtonsRow = ({
  handleCancel,
  cancelColor,
  cancelVariant,
  cancelText,
  handleApprove,
  approveColor,
  approveVariant,
  loading,
  approveText,
  disableBooking,
}) => {
  return (
    <div style={{ display: "flex", marginLeft: "auto" }}>
      {cancelText && (
        <OwcButton
          data-testid="confirm-dialog-actions-button-cancel"
          onClick={handleCancel}
          variant="secondary"
          style={{ marginRight: 32 }}
        >
          {cancelText}
        </OwcButton>
      )}
      <OwcButton
        data-testid="confirm-dialog-actions-button-approve"
        onClick={handleApprove}
        variant="primary"
        disabled={loading || disableBooking}
      >
        {approveText || null}
      </OwcButton>
    </div>
  );
};

export const ConfirmDialog = ({
  testid = "confirm-dialog",
  buttonPopper = true,
  title = "",
  content = "",
  cancelText = null,
  approveText,
  cancelVariant,
  cancelColor,
  approveColor,
  approveVariant,
  onCancel,
  onApprove,
  close,
  open,
  children,
  renderActions,
  loading = false,
  showReasonDD = false,
  reasonDropdowmLabel = "",
  disableBooking = true,
  reasonValue = null,
  onReasonChanged,
  size = "sm",
  fullWidth = false,
}) => {
  const handleCancel = () => {
    close();
    if (onCancel) {
      onCancel();
    }
  };
  const handleApprove = () => {
    if (onApprove) {
      onApprove();
    }
    close();
  };
  return (
    <OwcModalDialog
      visible={open}
      data-testid={testid}
      size={size}
      className="owcmodalZIndex"
      disableBackdropClick={true}
    >
      <div slot="header" data-testid="confirm-dialog-title">
        {title}
      </div>
      {buttonPopper && (
        <div data-testid="confirm-dialog-content">
          {children ? (
            children
          ) : (
            <div data-testid="confirm-dialog-content-text">
              {content && reasonDropdowmLabel ? (
                <>
                  <div>{content}</div>
                  <div>{reasonDropdowmLabel}</div>
                </>
              ) : (
                content
              )}
            </div>
          )}
        </div>
      )}

      {showReasonDD && (
        <IdentificationForm>
          <InputSelectType
            value={reasonValue}
            onHandleChange={onReasonChanged}
          />
        </IdentificationForm>
      )}

      <div slot="actions" data-testid="confirm-dialog-actions">
        {typeof renderActions === "function" ? (
          renderActions({
            cancelColor,
            cancelVariant,
            approveColor,
            approveVariant,
            cancelText,
            approveText,
            close,
          })
        ) : (
          <ActionButtonsRow
            handleCancel={handleCancel}
            cancelColor={cancelColor}
            cancelVariant={cancelVariant}
            cancelText={cancelText}
            handleApprove={handleApprove}
            approveColor={approveColor}
            approveVariant={approveVariant}
            loading={loading}
            approveText={approveText}
            disableBooking={disableBooking}
          />
        )}
      </div>
    </OwcModalDialog>
  );
};

export default ConfirmDialog;
