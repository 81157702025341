import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import Amplify from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';

process.env.NODE_ENV === 'development' && (Amplify.Logger.LOG_LEVEL = 'DEBUG');

export const AVAILABLE_MODES = Object.freeze({
  TV: 'tv',
  widget: 'widget',
  normal: 'normal',
});

export const getAWSAppSyncClinet = ({ mode = '', getToken = () => null }) => {
  switch (mode) {
    case 'widget': {
      return new AWSAppSyncClient({
        url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        disableOffline: true,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: getToken,
        },
      });
    }
    default: {
      return new AWSAppSyncClient({
        url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        disableOffline: true,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () =>
            (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
      });
    }
  }
};
