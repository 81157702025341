import styled from "styled-components";
import { getLocationText } from "../../../utils/helpers/text";
import InstrumentPicture from "../../booking-instruments/booking-instruments-cards-view/elements/InstrumentPicture";
import DATA_LABEL_TABLE from "../../../utils/constants/dataLabelTable";

export const InstrumentMainInformation = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;
const InfoAboutInstrumentRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const InfoInstrumentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;

  gap: 8px 16px;
`;
export const SerialNumberRow = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-left: 20px;
`;
export const NormalInfoRow = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  align-self: left;
`;
export const TitleInfoRow = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
`;
const InfoAboutInstrumentRow = ({ instrument }) => {
  return (
    <InfoAboutInstrumentRowStyled data-testid="booking-calendar-info-about-instrument-box">
      <InstrumentMainInformation data-testid="booking-calendar-info-about-instrument-box-main-row">
        <InstrumentPicture
          equipmentModel={instrument?.equipmentModel}
          displayImage={instrument?.displayImage}
        />
        <SerialNumberRow data-testid="booking-calendar-info-about-instrument-box-serial-number-row">
          {instrument?.shortIdentifier}
        </SerialNumberRow>
      </InstrumentMainInformation>
      <InfoInstrumentRow>
        <TitleInfoRow data-testid="booking-calendar-info-about-instrument-box-configuration-title-row">
          {DATA_LABEL_TABLE.POPUP_BOOKING_PAGE.configuration}
        </TitleInfoRow>
        <NormalInfoRow data-testid="booking-calendar-info-about-instrument-box-configuration-info-row">
          {instrument?.configurationBaseline || "-"}
        </NormalInfoRow>
        <TitleInfoRow data-testid="booking-calendar-info-about-instrument-box-sw-title-row">
          {DATA_LABEL_TABLE.POPUP_BOOKING_PAGE.software_version}
        </TitleInfoRow>
        <NormalInfoRow data-testid="booking-calendar-info-about-instrument-box-sw-info-row">
          {instrument?.softwareVersion && instrument?.softwareVersion !== "null"
            ? instrument.softwareVersion
            : "-"}
        </NormalInfoRow>
        <TitleInfoRow data-testid="booking-calendar-info-about-instrument-box-location-title-row">
          {DATA_LABEL_TABLE.POPUP_BOOKING_PAGE.location}
        </TitleInfoRow>
        <NormalInfoRow data-testid="booking-calendar-info-about-instrument-location-sw-info-row">
          {getLocationText(instrument)}
        </NormalInfoRow>
      </InfoInstrumentRow>
    </InfoAboutInstrumentRowStyled>
  );
};

export default InfoAboutInstrumentRow;

export const InfoAboutInstrumentRowSummary = ({ instrument }) => {
  return (
    <InfoAboutInstrumentRowStyled data-testid="booking-calendar-info-about-instrument-box">
      <InstrumentPicture
        equipmentModel={instrument?.equipmentModel}
        displayImage={instrument?.displayImage}
        data-testid="booking-calendar-info-about-instrument-box-main-picture"
      />
      <div className="information">
        <div className="serialNumber">
          <SerialNumberRow data-testid="booking-calendar-info-about-instrument-box-serial-number-row">
            {instrument?.shortIdentifier}
          </SerialNumberRow>
        </div>
        <InfoInstrumentRow>
          <TitleInfoRow data-testid="booking-calendar-info-about-instrument-box-configuration-title-row">
            {DATA_LABEL_TABLE.POPUP_BOOKING_PAGE.configuration}
          </TitleInfoRow>
          <NormalInfoRow data-testid="booking-calendar-info-about-instrument-box-configuration-info-row">
            {instrument?.configurationBaseline || "-"}
          </NormalInfoRow>
          <TitleInfoRow data-testid="booking-calendar-info-about-instrument-box-sw-title-row">
            {DATA_LABEL_TABLE.POPUP_BOOKING_PAGE.software_version}
          </TitleInfoRow>
          <NormalInfoRow data-testid="booking-calendar-info-about-instrument-box-sw-info-row">
            {instrument?.softwareVersion &&
            instrument?.softwareVersion !== "null"
              ? instrument.softwareVersion
              : "-"}
          </NormalInfoRow>
          <TitleInfoRow data-testid="booking-calendar-info-about-instrument-box-location-title-row">
            {DATA_LABEL_TABLE.POPUP_BOOKING_PAGE.location}
          </TitleInfoRow>
          <NormalInfoRow data-testid="booking-calendar-info-about-instrument-location-sw-info-row">
            {getLocationText(instrument)}
          </NormalInfoRow>
        </InfoInstrumentRow>
      </div>
    </InfoAboutInstrumentRowStyled>
  );
};
