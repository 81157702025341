import { OwcChip, OwcGrid, OwcTooltip, OwcTypography } from "@one/react";
import React from "react";
import { formatUserList, valueOrEmpty } from "../../../utils/helpers/text";
import { useSelector } from "react-redux";
import moment from "moment";

const PopoverBookingStatusDetail = ({
  data,
  title = "Booking details",
  dateRange = false,
}) => {
  const eventData = data?.eventData;
  const usersList = useSelector((state) => state.users.users);
  const user = useSelector((store) => store.user);
  let list = usersList.map((item) => ({
    ...item,
    key: item.email,
    value: formatUserList(item),
  }));

  // Booked for
  const bookedFor = eventData?.reservedForEmails ?? [];
  let bookedForName = bookedFor?.map((email) => {
    const user = list.find((user) => user.email === email);
    return user;
  });
  bookedForName = bookedForName.filter((k) => k !== undefined);

  const dateFromFormatter = moment(eventData?.start)
    .tz(user?.timezone)
    .format("DD-MMM-YYYY");

  const dateToFormatter = moment(eventData?.end)
    .tz(user?.timezone)
    .format("DD-MMM-YYYY");

  const timeFrom = moment(eventData?.start)
    .tz(user?.timezone)
    .format("hh:mm A");
  const timeTo = moment(eventData?.end).tz(user?.timezone).format("hh:mm A");

  return (
    <div>
      <OwcTypography variant="button" style={{ margin: "16px 0" }}>
        {title}
      </OwcTypography>
      <div style={{ padding: "0 30px" }}>
        <OwcGrid container columns="3" vGutter={{ m: 10, l: 15, xl: 20 }}>
          <>
            {!dateRange && (
              <>
                <OwcGrid
                  item
                  l={1}
                  m={1}
                  xs={1}
                  s={1}
                  style={{ marginBottom: 16 }}
                >
                  <OwcTypography variant="caption">Date range</OwcTypography>

                  <OwcTypography
                    variant="button"
                    id={{ dateFromFormatter } - { dateToFormatter }}
                  >
                    {dateFromFormatter} - {dateToFormatter}
                  </OwcTypography>
                  <OwcTooltip
                    anchor={{ dateFromFormatter } - { dateToFormatter }}
                    offset={[0, 0]}
                  >
                    {dateFromFormatter} - {dateToFormatter}
                  </OwcTooltip>
                </OwcGrid>
                <OwcGrid
                  item
                  l={1}
                  m={1}
                  xs={1}
                  s={1}
                  style={{ marginBottom: 16 }}
                >
                  <OwcTypography variant="caption">Time</OwcTypography>

                  <OwcTypography
                    variant="button"
                    id={`timerange_${timeFrom} - ${timeTo}`}
                  >
                    {timeFrom} - {timeTo}
                  </OwcTypography>
                  <OwcTooltip
                    anchor={`timerange_${timeFrom} - ${timeTo}`}
                    offset={[0, 0]}
                  >
                    {timeFrom} - {timeTo}
                  </OwcTooltip>
                </OwcGrid>
              </>
            )}
            {dateRange && (
              <>
                <OwcGrid
                  item
                  l={2}
                  m={2}
                  xs={2}
                  s={2}
                  style={{ marginBottom: 16 }}
                >
                  <OwcTypography variant="caption">
                    Date and time range
                  </OwcTypography>

                  <OwcTypography
                    variant="button"
                    id={`${dateFromFormatter}, ${timeFrom} - ${dateToFormatter}, ${timeTo}`}
                  >
                    {dateFromFormatter}, {timeFrom} - {dateToFormatter},{" "}
                    {timeTo}
                  </OwcTypography>
                  <OwcTooltip
                    anchor={`${dateFromFormatter}, ${timeFrom} - ${dateToFormatter}, ${timeTo}`}
                    offset={[0, 0]}
                  >
                    {dateFromFormatter}, {timeFrom} - {dateToFormatter},{" "}
                    {timeTo}
                  </OwcTooltip>
                </OwcGrid>
              </>
            )}

            <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 16 }}>
              <OwcTypography variant="caption">Project</OwcTypography>

              <OwcTypography variant="button" id={`attr_${eventData?.project}`}>
                {valueOrEmpty(eventData?.project, false, "-")}
              </OwcTypography>
              <OwcTooltip anchor={`attr_${eventData?.project}`}>
                {valueOrEmpty(eventData?.project, false, "-")}
              </OwcTooltip>
            </OwcGrid>
          </>
        </OwcGrid>
        <OwcGrid item m={1} l={1} style={{ marginBottom: 16 }}>
          <OwcTypography variant="caption">Description</OwcTypography>

          <OwcTypography
            variant="button"
            id={`attr_des${eventData?.description}`}
          >
            {valueOrEmpty(eventData?.description, false, "-")}
          </OwcTypography>
          <OwcTooltip anchor={`attr_des${eventData?.description}`}>
            {valueOrEmpty(eventData?.description, false, "-")}
          </OwcTooltip>
        </OwcGrid>
      </div>

      {bookedForName?.length > 0 && (
        <div style={{ padding: "0 30px", marginBottom: 16 }}>
          <OwcTypography variant="caption" style={{ padding: "3px 0" }}>
            Booking for
          </OwcTypography>
          <div style={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
            {bookedForName?.map((item) => (
              <OwcChip outlined round icon="user" iconType="outlined">
                {item?.value}
              </OwcChip>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopoverBookingStatusDetail;
