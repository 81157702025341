import React from "react";
import clsx from "clsx";
import styled, { css } from "styled-components";
import { ArrowDropDown } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { entryType } from "../../constants";
import { OwcProgressSpinner } from "@one/react";
import { Tooltip } from "@mui/material";

const TableStyled = styled.div`
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #d3d3d3;
  margin: 1rem 0;
  & .body {
    background-color: #ffffff;
  }
  & .head {
    background-color: #ffffff;
  }
  & .head {
    background: #efefef;
    height: 50px;
    padding: 10px 18px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  & .row {
    padding: 10px 18px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 50px;
    background: #ffffff;
  }
  & .row.selected {
    background: #e9f4ff;
  }
  & .row:hover {
    background: #fafafa;

    &.selected {
      background: #d2e9ff;
    }
  }
  & .body .cell,
  & .head .cell {
    margin: 0;
    line-height: 18px;
    font-size: 14px;
  }
  & .head .cell {
    font-weight: 400;
    overflow: hidden;
  }
  & .body .cell {
    font-weight: 500;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
  }
  & .body .loading {
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  & .cell {
    flex: 1 1 auto;

    &.sortable {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  & .empty {
    text-align: center;
    padding: 10px 16px;
  }
  & .empty,
  & .head,
  & .row {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #d3d3d3;
  }
  & .cluster-row,
  cluster:hover {
    background: #efefef;
  }
`;

const stepIconActive = css`
  max-height: 45vh;
`;

const BodyTable = styled.div`
  overflow: auto;
  ${(props) => !props.isBookingTable && stepIconActive}
`;
const isObject = (item) => typeof item === "object" && item !== null;

export const ItemRow = ({
  item,
  fields,
  id,
  selected,
  onRowClick,
  onCellClick,
}) => {
  return (
    <div
      onClick={(event) => onRowClick && onRowClick(event, item)}
      className={clsx(
        "row",
        {
          selected,
        },
        `${item.entryType === entryType?.cluster ? "cluster-row " : ""}`
      )}
      data-testid={`${id}-row`}
    >
      {Object.keys(fields).map((field) => {
        const Component = fields?.[field]?.component
          ? fields?.[field].component
          : null;

        const itemRowValue =
          field === "qualificationStatus"
            ? item?.displayQualificationStatus || "-"
            : item[field] && item[field] !== "null"
            ? item[field]
            : "-";

        if (!Component)
          return (
            <Tooltip title={itemRowValue} placement="bottom-start">
              <div
                keyName={`item-row-${id}-${field}`}
                data-testid={`item-row-${id}-${field}`}
                className="cell"
                onClick={(event) =>
                  onCellClick && onCellClick(event, item, field)
                }
                {...(fields?.[field]?.cellProps ?? {})}
              >
                {itemRowValue}
              </div>
            </Tooltip>
          );
        return (
          <div
            className="cell"
            keyName={`item-row-${id}-${field}`}
            data-testid={`item-row-${id}-${field}`}
            {...(fields?.[field]?.cellProps ?? {})}
          >
            <Component item={item} {...(fields?.[field]?.cellProps ?? {})} />
          </div>
        );
      })}
    </div>
  );
};

const ReturnBodyTable = ({
  loading,
  data,
  meta,
  isSelected,
  onRowClick,
  onCellClick,
}) => {
  if (loading) {
    return (
      <div className="loading">
        <OwcProgressSpinner diameter={30} />
      </div>
    );
  } else if (data.length === 0) {
    return (
      <div className="empty" data-testid="custom-list-no-data">
        No data
      </div>
    );
  } else {
    return data.map((item, index) => {
      const id =
        item?.[meta?.rowId] ||
        item?.id ||
        (typeof meta?.getId === "function" ? meta?.getId(item) : index);
      return (
        <ItemRow
          item={item}
          keyName={id}
          id={id}
          fields={meta?.fields}
          selected={isSelected(item)}
          onRowClick={onRowClick}
          onCellClick={onCellClick}
        />
      );
    });
  }
};

const ChooseDropDown = ({ isReverseOrder, keyName, orderBy }) => {
  if (keyName !== orderBy) {
    return <ArrowDropDown></ArrowDropDown>;
  } else if (isReverseOrder) {
    return (
      <ArrowDropUpIcon
        data-testid="arrow-drop-down-up-icon"
        color="primary"
      ></ArrowDropUpIcon>
    );
  } else {
    return (
      <ArrowDropDown
        data-testid="arrow-drop-down-down-icon"
        color="primary"
      ></ArrowDropDown>
    );
  }
};
const SortableHeaderCell = ({
  meta,
  keyName,
  orderBy,
  onRequestSort,
  isReverseOrder,
}) => {
  return (
    <div
      className={clsx({
        cell: true,
        sortable: meta.fields[keyName]?.sortable,
      })}
      data-testid={`list-head-${keyName}`}
      keyName={keyName}
      {...(meta?.fields?.[keyName]?.headProps ?? {})}
      onClick={() => {
        if (keyName === orderBy) {
          onRequestSort(keyName, !isReverseOrder);
        } else {
          onRequestSort(keyName, false);
        }
      }}
    >
      {meta?.fields?.[keyName]?.text}
      <ChooseDropDown
        isReverseOrder={isReverseOrder}
        keyName={keyName}
        orderBy={orderBy}
      />
    </div>
  );
};

const HeaderCell = ({ keyName, meta }) => {
  return (
    <div
      className="cell"
      data-testid={`list-head-${keyName}`}
      keyName={keyName}
      {...(meta?.fields?.[keyName]?.headProps ?? {})}
    >
      {meta?.fields?.[keyName]?.text}
    </div>
  );
};

export const Header = ({ meta, onRequestSort, isReverseOrder, orderBy }) => {
  return Object.keys(meta.fields).map((key) => {
    const Component = meta?.fields?.[key]?.headerComponent
      ? meta?.fields?.[key]?.headerComponent
      : null;
    if (!Component)
      return meta.fields[key]?.sortable ? (
        <SortableHeaderCell
          meta={meta}
          keyName={key}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          isReverseOrder={isReverseOrder}
        />
      ) : (
        <HeaderCell keyName={key} meta={meta} />
      );
    return (
      <div
        className={clsx({
          cell: true,
          sortable: meta.fields[key]?.sortable,
        })}
        data-testid={`list-head-${key}`}
        keyName={key}
        {...(meta?.fields?.[key]?.headProps ?? {})}
      >
        <Component {...(meta?.fields?.[key]?.headProps ?? {})} />
      </div>
    );
  });
};

const CustomList = ({
  meta,
  data = [],
  onRequestSort,
  isReverseOrder,
  orderBy,
  onRowClick,
  onCellClick,
  loading,
  isSelected = () => false,
  isBookingTable = false,
  Component = null,
}) => {
  if (!isObject(meta)) return null;
  if (!isObject(meta?.fields)) return null;
  if (!Array.isArray(data)) return null;
  return (
    <TableStyled data-testid="custom-list">
      <div className="head" data-testid="custom-list-head">
        <Header
          meta={meta}
          onRequestSort={onRequestSort}
          isReverseOrder={isReverseOrder}
          orderBy={orderBy}
        />
      </div>
      <BodyTable className="body" isBookingTable={isBookingTable}>
        <ReturnBodyTable
          loading={loading}
          data={data}
          meta={meta}
          isSelected={isSelected}
          onRowClick={onRowClick}
          onCellClick={onCellClick}
        />
      </BodyTable>
      {Component}
    </TableStyled>
  );
};

export default CustomList;
