import DATA_MODEL_TABLE from "./utils/constants/dataModelTable";
import { AdminPanelSettingsOutlined } from "@mui/icons-material";
import MyBookingsIcon from "./icons/MyBookingsIcon";
import BookEquipmentIcon from "./icons/BookEquipmentIcon";
export const BOOKING_ADMIN = "BookingAdmin";
export const BOOKING_USER = "BookingUsers";
export const DEFAULT_IMAGE = "DefaultInstrument.png";
export const BACKDROP_ZINDEX = 99999;

export const MAX_BOOKINGS_COUNT = 500;

export const DEFAULT_LIMIT = 5;

export const GROUPS_TOKEN_PATH =
  "signInUserSession.accessToken.payload.cognito:groups";
export const ACCESS_TOKEN_PATH = "signInUserSession.idToken.payload";
export const SYNC_PROFILE_FIELDS = {
  givenName: "custom:firstName",
  familyName: "custom:lastName",
  name: "preferred_username",
  phone: "custom:phoneNumber",
  site: "custom:site",
};

export const CALENDAR_DAY_VIEW_START_HOUR = 8;
export const CALENDAR_DAY_VIEW_END_HOUR = 19;
export const CALENDAR_DAY_VIEW_AM_PM = true;
export const CALENDAR_MORNING_START_HOUR = 8;
export const CALENDAR_MORNING_END_HOUR = 12;
export const CALENDAR_AFTERNOON_START_HOUR = 12;
export const CALENDAR_AFTERNOON_END_HOUR = 19;
export const CALENDAR_WEEK_INSTRUMENTS_COUNT_IN_DAY_SEGMENT = 7;

export const CLOUD_RESOURCE_ICONS = `/public/resources/deviceIcons/`;
export const DEFAULT_SITE = "Rotkreuz";

export const FIND_INSTRUMENTS_MAX_RANGE_IN_MONTHS = 6;

export const CALENDAR_WIDGET = "calendarWidget";

export const EquipmentSearchMeta = [
  {
    title: DATA_MODEL_TABLE.equipmentNickName.value,
    prop: DATA_MODEL_TABLE.equipmentNickName.key,
    dataTestId: "equipment-nick-name",
  },
  {
    title: DATA_MODEL_TABLE.equipmentId.value,
    prop: DATA_MODEL_TABLE.equipmentId.key,
    dataTestId: "equipment-id",
  },
  {
    title: DATA_MODEL_TABLE.serialNumber.value,
    prop: DATA_MODEL_TABLE.serialNumber.key,
    dataTestId: "serial-number",
  },
];

export const DEBOUNCE_DELAY = 1000;
export const DEFAULT_RECORDS_FETCHED = 900;
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const EDIT_BOOKING_URL = "editBookingUrl";
export const INVALID_BARCODE_MESSAGE =
  "Invalid Code. Try a new one or try again";
export const CAMERA_TIMEOUT_MESSAGE = "Camera timed out. Please try again";
export const DEFAULT_SEARCH_FILTER = "Serial number";
export const SEARCH_DROP_DOWN_FILTERS_LIST = [
  {
    title: DATA_MODEL_TABLE.equipmentId.value,
    coloumn: "equipmentId",
    checked: true,
  },
  {
    title: DATA_MODEL_TABLE.equipmentModel.value,
    coloumn: "equipmentModel",
    checked: true,
  },
  {
    title: DATA_MODEL_TABLE.equipmentNickName.value,
    coloumn: "equipmentNickName",
    checked: true,
  },
  {
    title: DATA_MODEL_TABLE.serialNumber.value,
    coloumn: "serialNumber",
    checked: true,
  },
];

export const DEFAULT_DESCRIPTION_CONSTANT = {
  moduleVersion: "MODULE VERSION",
  moduleName: "APP NAME",
  ciJobId: "CI ID JOB",
  commitHash: "COMMIT HASH",
  updatedAt: "UPDATED AT",
};

export const ADD_INFO = [
  "bookableForUsers",
  "bookedBy",
  "bookedByEmail",
  DATA_MODEL_TABLE.instrumentAvailability.key,
  DATA_MODEL_TABLE.instrumentStatus.key,
];

export const GRID_VIEW = "GRID";
export const LIST_VIEW = "LIST";

export const ADMIN_FILTER_STATUS = [
  { label: "Active", value: true },
  { label: "Cancelled", value: false },
];

export const LOCALHOST = "localhost";

export const POPOVER_DETAILS_EMPTY = {
  equipmentNickName: {
    key: "Nickname",
  },
  manufacturer: {
    key: "Manufacturer",
  },
  modelName: {
    key: "Model",
  },
  variant: {
    key: "Variant",
  },
  serialNumber: {
    key: "Serial number",
  },
  materialNumber: {
    key: "Material number",
  },
  equipmentId: {
    key: "Equipment ID",
  },
  responsiblePerson: {
    key: "Responsible person",
  },
  systemStatus: {
    key: "System status",
  },
  configurationBaseline: {
    key: "Configuration",
  },
  softwareVersion: {
    key: "Software version",
  },
  belongingToGroup: {
    key: "Group",
  },
};

export const POPOVER_PROJECTDETAILS_EMPTY = {
  project: {
    key: "Project ID",
  },
  description: {
    key: "Description",
  },
};

export const POPOVER_WIDGET_PROJECTDETAILS_EMPTY = {
  project: {
    key: "Project ID",
  },
  description: {
    key: "Description",
  },
};

export const POPOVER_WIDGET_DETAILS_EMPTY = {
  equipmentNickName: {
    key: "Nickname",
  },
  modelName: {
    key: "Model",
  },
  responsiblePerson: {
    key: "Responsible person",
  },
  systemStatus: {
    key: "System status",
  },
  configurationBaseline: {
    key: "Configuration",
  },
  softwareVersion: {
    key: "Software version",
  },
  belongingToGroup: {
    key: "Group",
  },
};

export const POPOVER_CLUSTER_DETAILS_EMPTY = {
  siteName: {
    key: "Site",
  },
  location: {
    key: "Location",
  },
  equipmentCategory: {
    key: "Category",
  },
  equipmentModel: {
    key: "Model",
  },
  variant: {
    key: "Variant",
  },
  equipmentNickName: {
    key: "Nickname",
  },
  responsiblePerson: {
    key: "Responsible person",
  },
  qualificationStatus: {
    key: "Qualification status",
  },
  softwareVersion: {
    key: "Software version",
  },
  systemStatus: {
    key: "System status",
  },
};

export const entryType = {
  cluster: "CLUSTER",
  standaloneEquipment: "STANDALONE-EQUIPMENT",
  clusterSubequipment: "CLUSTER-SUBEQUIPMENT",
};
export const DEFAULT_CLUSTER_POPOVER_SKELETON = () => {
  return {
    equipmentDetail: {
      id: "",
      status: "",
      headerHeading: "",
      headerDetails: null,
      projectHeading: "",
      projectDetails: null,
      clusterSubEquipmentsHeading: "",
      clusterSubEquipments: [],
      detailHeading: "",
      detail: null,
      emailHREF: "",
      options: {
        hideFooter: false,
        hideHeader: false,
        hideBookingDetails: false,
      },
    },
  };
};

export const CLUSTER_SUB_EQUIPMENT_OBJ = {
  index: "",
  equipmentModel: "Model",
  variant: "Variant",
  equipmentNickName: "Nickname",
  qualificationStatus: "Qualification status",
  softwareVersion: "Software version",
};

export const MEGA_CLUSTER_SUB_EQUIPMENT_OBJ = {
  index: "",
  entryType: "",
  equipmentModel: "Model",
  variant: "Variant",
  equipmentNickName: "Nickname",
  qualificationStatus: "Qualification status",
  softwareVersion: "Software version",
};

export const POPOVER_CONTROL = ["index", "entryType"];

export const GXPemptyInstrumentsOptions = {
  qualificationStatus: [
    { key: "NA", value: "NA" },
    { key: "NOT_QUALIFIED", value: "Not qualified" },
    { key: "QUALIFIED", value: "Qualified" },
  ],
};

export const instrumentAvailabilityOptions = {
  instrumentAvailability: [
    { key: "Booking", value: "Booking" },
    { key: "Available", value: "Available" },
    { key: "Reservation", value: "Reservation" },
    { key: "Maintenance", value: "Maintenance" },
  ],
};

export const equipmentStatus = {
  active: { key: "ACTIVE", value: "Active" },
  pending: { key: "PENDING", value: "Pending" },
  deleted: { key: "DELETED", value: "Deleted" },
};
export const equipmentStatusOptions = [
  equipmentStatus?.active?.value,
  equipmentStatus?.deleted?.value,
  equipmentStatus?.pending?.value,
];

export const statusChip = {
  ACTIVE: equipmentStatus?.active?.value,
  PENDING: equipmentStatus?.pending?.value,
  DELETED: equipmentStatus?.deleted?.value,
};

export const CalibrationStatusOptions = {
  controlledEquipmentStatus: [
    { key: "NA", value: "NA" },
    { key: "NOT_CALIBRATED", value: "Not calibrated" },
    { key: "CALIBRATED", value: "Calibrated" },
  ],
};

export const HOUR_PREFERENCE = [
  {
    key: "MORNING",
    value: "Morning",
  },
  {
    key: "AFTERNOON",
    value: "Afternoon",
  },
  {
    key: "FULLDAY",
    value: "Full day",
  },
];

export const GXP_DROP_CHECK = [DATA_MODEL_TABLE.qualificationStatus.key];

export const HOME_SUB_MENU = [
  {
    icon: <BookEquipmentIcon />,
    text: "Book equipment",
    url: "/",
    isActive: true,
    disable: false,
  },
  {
    icon: <MyBookingsIcon />,
    text: "My bookings",
    url: "/bookings",
    isActive: false,
    disable: false,
  },
  {
    icon: <AdminPanelSettingsOutlined />,
    text: "Admin panel",
    url: "/admin",
    isActive: false,
    disable: false,
  },
];

export const emptyBookingDetails = {
  bookingType: "Booking",
  bookingFor: [],
  project: "",
  description: "",
};
export const WHOM_TAB_BOOKED_TYPE_LIST = [
  "Booking",
  "Maintenance",
  "Reservation",
];

export const WHOM_TAB_LABEL = {
  BOOKED_TYPE: "Booking type",
  BOOKING_FOR: "Booking for",
  PROJECT: "Project",
  DESCRIPTION: "Description *",
};

export const DURATION = 5000;
export const DEFAULT_COLUMNS = {
  displayName: "",
  displayDefinition: {
    showColumns: [
      {
        key: DATA_MODEL_TABLE.serialNumber.key,
        val: DATA_MODEL_TABLE.serialNumber.value,
        order: 1,
        show: true,
        sortStatus: true,
      },
      {
        key: DATA_MODEL_TABLE.materialNumber.key,
        val: DATA_MODEL_TABLE.materialNumber.value,
        order: 2,
        show: true,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.equipmentModel.key,
        val: DATA_MODEL_TABLE.equipmentModel.value,
        order: 3,
        show: true,
        sortStatus: true,
      },
      {
        key: DATA_MODEL_TABLE.siteName.key,
        val: DATA_MODEL_TABLE.siteName.value,
        order: 4,
        show: true,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.manufacturer.key,
        val: DATA_MODEL_TABLE.manufacturer.value,
        order: 5,
        show: true,
        sortStatus: false,
      },
    ],
    hideColumns: [
      {
        key: DATA_MODEL_TABLE.instrumentGTIN.key,
        val: "GTIN number",
        order: 6,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.equipmentId.key,
        val: DATA_MODEL_TABLE.equipmentId.value,
        order: 7,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.location.key,
        val: DATA_MODEL_TABLE.location.value,
        order: 8,
        show: false,
        sortStatus: true,
      },
      {
        key: DATA_MODEL_TABLE.responsiblePerson.key,
        val: DATA_MODEL_TABLE.responsiblePerson.value,
        order: 9,
        show: false,
        sortStatus: true,
      },
      {
        key: DATA_MODEL_TABLE.isBookable.key,
        val: DATA_MODEL_TABLE.isBookable.value,
        order: 10,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.instrumentRUDI.key,
        val: "RUDI number",
        order: 11,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.equipmentCategory.key,
        val: DATA_MODEL_TABLE.equipmentCategory.value,
        order: 12,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.comment.key,
        val: DATA_MODEL_TABLE.comment.value,
        order: 13,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.belongingToGroup.key,
        val: DATA_MODEL_TABLE.belongingToGroup.value,
        order: 14,
        show: false,
        sortStatus: true,
      },
      {
        key: DATA_MODEL_TABLE.room.key,
        val: DATA_MODEL_TABLE.room.value,
        order: 15,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.systemOwner.key,
        val: DATA_MODEL_TABLE.systemOwner.value,
        order: 16,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.responsibleProxy.key,
        val: DATA_MODEL_TABLE.responsibleProxy.value,
        order: 17,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.sop.key,
        val: DATA_MODEL_TABLE.sop.value,
        order: 18,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.csv.key,
        val: DATA_MODEL_TABLE.csv.value,
        order: 19,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.electronicRecord.key,
        val: DATA_MODEL_TABLE.electronicRecord.value,
        order: 20,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.electronicSignatures.key,
        val: DATA_MODEL_TABLE.electronicSignatures.value,
        order: 21,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
        val: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
        order: 22,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.maintenancePlan.key,
        val: DATA_MODEL_TABLE.maintenancePlan.value,
        order: 23,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.gxpRelevant.key,
        val: DATA_MODEL_TABLE.gxpRelevant.value,
        order: 24,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.qualificationStatus.key,
        val: DATA_MODEL_TABLE.qualificationStatus.value,
        order: 25,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
        val: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
        order: 26,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.softwareVersion.key,
        val: "Version",
        order: 27,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.configurationBaseline.key,
        val: DATA_MODEL_TABLE.configurationBaseline.value,
        order: 28,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.installedTests.key,
        val: DATA_MODEL_TABLE.installedTests.value,
        order: 29,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.qualificationDocuments.key,
        val: DATA_MODEL_TABLE.qualificationDocuments.value,
        order: 30,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.isVisualized.key,
        val: DATA_MODEL_TABLE.isVisualized.value,
        order: 31,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
        val: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
        order: 32,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.buildingLocation.key,
        val: DATA_MODEL_TABLE.buildingLocation.value,
        order: 33,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.floor.key,
        val: DATA_MODEL_TABLE.floor.value,
        order: 34,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.cluster.key,
        val: DATA_MODEL_TABLE.cluster.value,
        order: 35,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.equipmentAdministrator.key,
        val: DATA_MODEL_TABLE.equipmentAdministrator.value,
        order: 36,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.equipmentNickName.key,
        val: DATA_MODEL_TABLE.equipmentNickName.value,
        order: 37,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.functionalLocation.key,
        val: DATA_MODEL_TABLE.functionalLocation.value,
        order: 38,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.costCenter.key,
        val: DATA_MODEL_TABLE.costCenter.value,
        order: 39,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.inventoryNumber.key,
        val: DATA_MODEL_TABLE.inventoryNumber.value,
        order: 40,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.variant.key,
        val: DATA_MODEL_TABLE.variant.value,
        order: 41,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
        val: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
        order: 42,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
        val: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
        order: 43,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
        val: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
        order: 44,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.weightInkg.key,
        val: DATA_MODEL_TABLE.weightInkg.value,
        order: 45,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.heightInmm.key,
        val: DATA_MODEL_TABLE.heightInmm.value,
        order: 46,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.lengthInmm.key,
        val: DATA_MODEL_TABLE.lengthInmm.value,
        order: 47,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.depthInmm.key,
        val: DATA_MODEL_TABLE.depthInmm.value,
        order: 48,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
        val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
        order: 49,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.clearanceRightInmm.key,
        val: DATA_MODEL_TABLE.clearanceRightInmm.value,
        order: 50,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
        val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
        order: 51,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
        val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
        order: 52,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
        val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
        order: 53,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.tags.key,
        val: DATA_MODEL_TABLE.tags.value,
        order: 54,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.linkedInstance.key,
        val: DATA_MODEL_TABLE.linkedInstance.value,
        order: 55,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.systemStatus.key,
        val: DATA_MODEL_TABLE.systemStatus.value,
        order: 56,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.displayQualificationStatus.key,
        val: DATA_MODEL_TABLE.displayQualificationStatus.value,
        order: 57,
        show: false,
        sortStatus: false,
      },
      {
        key: DATA_MODEL_TABLE.status.key,
        val: DATA_MODEL_TABLE.status.value,
        order: 58,
        show: false,
        sortStatus: true,
      },
    ],
  },
};
export const listOfFieldsIgnoredInTable = [
  DATA_MODEL_TABLE?.trackPositionInCluster?.key,
];
export const defaultFilterObject = () => {
  let obj = {};
  Object.keys(DATA_MODEL_TABLE)?.forEach((dataModel) => {
    if (!listOfFieldsIgnoredInTable.includes(dataModel)) {
      if (dataModel === DATA_MODEL_TABLE?.belongingToGroup?.key) {
        obj["group"] = null;
      }
      if (dataModel === DATA_MODEL_TABLE?.siteName?.key) {
        obj["site"] = null;
      }
      if (dataModel === DATA_MODEL_TABLE?.equipmentCategory?.key) {
        obj["category"] = null;
      }
      obj[dataModel] = null;
    }
  });

  return obj;
};
export const DEFAULT_FILTER = {
  filterName: "",
  filterDefinition: {
    ...defaultFilterObject(),
    dateFrom: null,
    dateTo: null,
    inventoryIds: null,
  },
};
export const DEFAULT_PAGE_SIZE = 10;
export const MY_LAB = "My lab";
export const SOP_RT = "SOP RT0020";
export const YES = "Yes";
export const DEFAULT_MYLAB_FILTER = {
  filterName: MY_LAB,
  filterDefinition: {
    ...defaultFilterObject(),
    dateFrom: null,
    dateTo: null,
    inventoryIds: null,
  },
};
export const DUMMY_ROW = "dummyRow";
export const BOOKING_CONFIG = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
};

export const LP_CONFIG = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_LP_AWS_GRAPHQL_ENDPOINT,
};
export const REPO_CONFIG = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_REPO_AWS_GRAPHQL_ENDPOINT,
};
export const allTableColumn = () => [
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    val: DATA_MODEL_TABLE.serialNumber.value,
    order: 1,
    show: true,
    sortStatus: true,
  },
  {
    key: DATA_MODEL_TABLE.materialNumber.key,
    val: DATA_MODEL_TABLE.materialNumber.value,
    order: 2,
    show: true,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    val: DATA_MODEL_TABLE.equipmentModel.value,
    order: 3,
    show: false,
    sortStatus: true,
  },
  {
    key: DATA_MODEL_TABLE.siteName.key,
    val: DATA_MODEL_TABLE.siteName.value,
    order: 4,
    show: true,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    val: DATA_MODEL_TABLE.manufacturer.value,
    order: 5,
    show: true,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.instrumentGTIN.key,
    val: "GTIN number",
    order: 6,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    val: DATA_MODEL_TABLE.equipmentId.value,
    order: 7,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.location.key,
    val: DATA_MODEL_TABLE.location.value,
    order: 8,
    show: false,
    sortStatus: true,
  },
  {
    key: DATA_MODEL_TABLE.responsiblePerson.key,
    val: DATA_MODEL_TABLE.responsiblePerson.value,
    order: 9,
    show: false,
    sortStatus: true,
  },
  {
    key: DATA_MODEL_TABLE.isBookable.key,
    val: DATA_MODEL_TABLE.isBookable.value,
    order: 10,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.instrumentRUDI.key,
    val: "RUDI number",
    order: 11,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    val: DATA_MODEL_TABLE.equipmentCategory.value,
    order: 12,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.comment.key,
    val: DATA_MODEL_TABLE.comment.value,
    order: 13,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.belongingToGroup.key,
    val: DATA_MODEL_TABLE.belongingToGroup.value,
    order: 14,
    show: false,
    sortStatus: true,
  },
  {
    key: DATA_MODEL_TABLE.room.key,
    val: DATA_MODEL_TABLE.room.value,
    order: 15,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.systemOwner.key,
    val: DATA_MODEL_TABLE.systemOwner.value,
    order: 16,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.responsibleProxy.key,
    val: DATA_MODEL_TABLE.responsibleProxy.value,
    order: 17,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.sop.key,
    val: DATA_MODEL_TABLE.sop.value,
    order: 18,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.csv.key,
    val: DATA_MODEL_TABLE.csv.value,
    order: 19,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.electronicRecord.key,
    val: DATA_MODEL_TABLE.electronicRecord.value,
    order: 20,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.electronicSignatures.key,
    val: DATA_MODEL_TABLE.electronicSignatures.value,
    order: 21,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
    val: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
    order: 22,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.maintenancePlan.key,
    val: DATA_MODEL_TABLE.maintenancePlan.value,
    order: 23,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.gxpRelevant.key,
    val: DATA_MODEL_TABLE.gxpRelevant.value,
    order: 24,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.qualificationStatus.key,
    val: DATA_MODEL_TABLE.qualificationStatus.value,
    order: 25,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
    order: 26,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.softwareVersion.key,
    val: "Version",
    order: 27,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.configurationBaseline.key,
    val: DATA_MODEL_TABLE.configurationBaseline.value,
    order: 28,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.installedTests.key,
    val: DATA_MODEL_TABLE.installedTests.value,
    order: 29,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.qualificationDocuments.key,
    val: DATA_MODEL_TABLE.qualificationDocuments.value,
    order: 30,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.isVisualized.key,
    val: DATA_MODEL_TABLE.isVisualized.value,
    order: 31,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
    order: 32,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.buildingLocation.key,
    val: DATA_MODEL_TABLE.buildingLocation.value,
    order: 33,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.floor.key,
    val: DATA_MODEL_TABLE.floor.value,
    order: 34,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.cluster.key,
    val: DATA_MODEL_TABLE.cluster.value,
    order: 35,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.equipmentAdministrator.key,
    val: DATA_MODEL_TABLE.equipmentAdministrator.value,
    order: 36,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    val: DATA_MODEL_TABLE.equipmentNickName.value,
    order: 37,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.functionalLocation.key,
    val: DATA_MODEL_TABLE.functionalLocation.value,
    order: 38,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.costCenter.key,
    val: DATA_MODEL_TABLE.costCenter.value,
    order: 39,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.inventoryNumber.key,
    val: DATA_MODEL_TABLE.inventoryNumber.value,
    order: 40,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.variant.key,
    val: DATA_MODEL_TABLE.variant.value,
    order: 41,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
    val: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
    order: 42,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
    val: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
    order: 43,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
    val: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
    order: 44,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.weightInkg.key,
    val: DATA_MODEL_TABLE.weightInkg.value,
    order: 45,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.heightInmm.key,
    val: DATA_MODEL_TABLE.heightInmm.value,
    order: 46,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.lengthInmm.key,
    val: DATA_MODEL_TABLE.lengthInmm.value,
    order: 47,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.depthInmm.key,
    val: DATA_MODEL_TABLE.depthInmm.value,
    order: 48,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
    val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
    order: 49,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.clearanceRightInmm.key,
    val: DATA_MODEL_TABLE.clearanceRightInmm.value,
    order: 50,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
    val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
    order: 51,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
    val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
    order: 52,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
    val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
    order: 53,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.tags.key,
    val: DATA_MODEL_TABLE.tags.value,
    order: 54,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.linkedInstance.key,
    val: DATA_MODEL_TABLE.linkedInstance.value,
    order: 55,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.systemStatus.key,
    val: DATA_MODEL_TABLE.systemStatus.value,
    order: 56,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.instrumentAvailability.key,
    val: DATA_MODEL_TABLE.instrumentAvailability.value,
    order: 57,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.instrumentStatus.key,
    val: DATA_MODEL_TABLE.instrumentStatus.value,
    order: 58,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.displayQualificationStatus.key,
    val: DATA_MODEL_TABLE.displayQualificationStatus.value,
    order: 59,
    show: false,
    sortStatus: false,
  },
  {
    key: DATA_MODEL_TABLE.status.key,
    val: DATA_MODEL_TABLE.status.value,
    order: 60,
    show: false,
    sortStatus: true,
  },
];

export const listOfBooleanRequired = [
  DATA_MODEL_TABLE?.isBookable?.key,
  DATA_MODEL_TABLE?.isVisualized?.key,
];
export const listOfCheckBoxFilters = [
  DATA_MODEL_TABLE?.belongingToGroup?.key,
  DATA_MODEL_TABLE?.manufacturer?.key,
  DATA_MODEL_TABLE?.equipmentCategory?.key,
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.tags?.key,
  DATA_MODEL_TABLE?.linkedInstance?.key,
  DATA_MODEL_TABLE?.equipmentModel?.key,
  DATA_MODEL_TABLE?.variant?.key,
  DATA_MODEL_TABLE?.csv?.key,
  DATA_MODEL_TABLE?.electronicRecord?.key,
  DATA_MODEL_TABLE?.electronicSignatures?.key,
  DATA_MODEL_TABLE?.qualificationStatus?.key,
  DATA_MODEL_TABLE?.displayQualificationStatus?.key,
  DATA_MODEL_TABLE?.controlledEquipmentStatus?.key,
  DATA_MODEL_TABLE?.sop?.key,
  DATA_MODEL_TABLE?.gxpRelevant?.key,
  DATA_MODEL_TABLE?.systemStatus?.key,
  DATA_MODEL_TABLE?.instrumentAvailability?.key,
  DATA_MODEL_TABLE?.status?.key,
];
export const listOfDateRequired = [
  DATA_MODEL_TABLE?.dateOfLastMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key,
];
export const listOfFieldsIgnoredForSearch = [
  DATA_MODEL_TABLE?.dateOfLastMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key,
  DATA_MODEL_TABLE?.inventoryNumber?.key,
];
export const listOfFieldsNoFilter = [DATA_MODEL_TABLE?.siteName?.key];
export const listOfFieldsNotSortable = [
  DATA_MODEL_TABLE?.tags?.key,
  DATA_MODEL_TABLE?.sop?.key,
];

export const listOfFieldsSortable = [
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.location?.key,
  DATA_MODEL_TABLE?.belongingToGroup?.key,
  DATA_MODEL_TABLE?.serialNumber?.key,
  DATA_MODEL_TABLE?.equipmentModel?.key,
];

export const listOfFilterBlankRequired = [
  DATA_MODEL_TABLE?.serialNumber?.key,
  DATA_MODEL_TABLE?.materialNumber?.key,
  DATA_MODEL_TABLE?.equipmentId?.key,
];
export const listOfNumberFilter = [
  DATA_MODEL_TABLE?.weightInkg?.key,
  DATA_MODEL_TABLE?.heightInmm?.key,
  DATA_MODEL_TABLE?.lengthInmm?.key,
  DATA_MODEL_TABLE?.depthInmm?.key,
  DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
  DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
  DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
  DATA_MODEL_TABLE?.clearanceRightInmm?.key,
  DATA_MODEL_TABLE?.clearanceToFrontInmm?.key,
  DATA_MODEL_TABLE?.maintenanceIntervalInDays?.key,
  DATA_MODEL_TABLE?.inventoryNumber?.key,
  DATA_MODEL_TABLE?.qualificationDocuments?.key,
  DATA_MODEL_TABLE?.installedTests?.key,
];
export const systemStatusOptions = ["In service", "Out of service"];
export const qualificationCalibrationStatus = {
  qualified: "Qualified",
  not_qualified: "Not qualified",
  calibrated: "Calibrated",
  not_calibrated: "Not calibrated",
  NA: "NA",
};

export const MAX_COUNT_CAPACITY = 50;
export const equipmentTabGroup = [
  {
    id: 1,
    heading: "What equipment?",
    content: "",
    isDisable: false,
  },
  {
    id: 2,
    heading: "Date and Time?",
    content: "Define experiment range",
    isDisable: true,
  },
  {
    id: 3,
    heading: "For what/whom?",
    content: "",
    isDisable: true,
  },
];
export const IconStatus = [
  {
    icon: "confirm",
    text: "Available",
    backgroundColor: "#D7E0F7",
    color: "#0B41CD",
  },
  {
    icon: "schedule_on",
    text: "Booking",
    backgroundColor: "var(--one-color-cobas-accent-purple-200)",
    color: "var(--one-color-cobas-accent-purple-800)",
    textColor: "var(--one-color-accent-quaternary-4)",
  },
  {
    icon: "order_approval",
    text: "Reservation",
    backgroundColor: "var(--one-color-cyan-100)",
    color: "var(--one-color-accent-cyan-800)",
    textColor: "var(--one-color-cyan-900)",
  },
  {
    icon: "test_result",
    text: "Maintenance",
    backgroundColor: "var(--one-color-cobas-yellow-200)",
    color: "var(--one-color-legacy-yellow-800)",
    textColor: "var(--one-color-legacy-yellow-800)",
  },
];

export const BookingOptions = [
  { key: "booked", val: "Book" },
  { key: "maintenance", val: "Maintain" },
  { key: "reserved", val: "Reserve" },
];
export const agGridAutoGroupfieldName = "ag-Grid-AutoColumn";

export const MOBISCROLL_CALENDAR_VIEW_END_HOUR = 24;
export const BOOKING_TYPE = {
  BOOKED: "Booking",
  RESERVED: "Reservation",
  MAINTENANCE: "Maintenance",
  AVAILABLE: "Available",
};
export const TIMELINE_TYPE = {
  DAY: "day",
  WEEK: "week",
  WORKWEEK: "workweek",
};

export const singleEventSkeleton = {
  active: false,
  allDay: false,
  bookingId: null,
  color: "#D7E0F7",
  start: null,
  end: null,
  fcolor: "#0B41CD",
  id: null,
  resource: null,
  type: BOOKING_TYPE.AVAILABLE,
  overlap: false,
  title: "Available",
};

export const removeField = [
  "editReason",
  "updatedBy",
  "editComment",
  "location",
];

export const MyBookingMeta = {
  fields: {
    detailExpander: {
      text: "",
      width: 40,
      minWidth: 40,
    },
    dateFrom: {
      text: "Experiment start date",
      width: 200,
      minWidth: 200,
    },
    dateTo: {
      text: "Experiment end date",
      width: 200,
      minWidth: 200,
    },
    type: {
      text: "Booking type",
      width: 200,
      minWidth: 200,
    },
    equipment: {
      text: "Equipment",
      width: 200,
      minWidth: 200,
    },
    reservedForUsers: {
      text: "Booking for",
      width: 200,
      minWidth: 200,
    },
    project: {
      text: "Project",
      width: 200,
      minWidth: 200,
    },
    description: {
      text: "Description",
      width: 200,
      minWidth: 200,
    },
    actionButton: {
      text: "",
      width: 80,
      // minWidth: 80,
    },
  },
};

export const DropBookingMeta = {
  fields: {
    detailExpander: {
      text: "",
      width: 40,
      minWidth: 40,
    },
    dateFrom: {
      text: "Experiment start date",
      width: 200,
      minWidth: 200,
    },
    dateTo: {
      text: "Experiment end date",
      width: 200,
      minWidth: 200,
    },
    type: {
      text: "Booking type",
      width: 200,
      minWidth: 200,
    },
    equipment: {
      text: "Equipment",
      width: 200,
      minWidth: 200,
    },
    reservedForUsers: {
      text: "Booking for",
      width: 200,
      minWidth: 200,
    },
    remark: {
      text: "Reason",
      width: 200,
      minWidth: 200,
    },
    active: {
      text: "Booking status",
      width: 200,
      minWidth: 200,
    },
    actionButton: {
      text: "",
      width: 200,
      minWidth: 200,
    },
  },
};
export const DropBookingParentMeta = {
  fields: {
    detailExpander: {
      text: "",
    },
    dateFrom: {
      text: "Experiment start date",
    },
    dateTo: {
      text: "Experiment end date",
    },
    type: {
      text: "Booking type",
    },
    equipment: {
      text: "Equipment",
    },
    reservedForUsers: {
      text: "Booking for",
    },
    remark: {
      text: "Reason",
    },
    active: {
      text: "Booking status",
    },
  },
};
export const DropBookingChildMeta = {
  fields: {
    detailExpander: {
      text: "",
    },
    dateFrom: {
      text: "Experiment start date",
    },
    dateTo: {
      text: "Experiment end date",
    },
    type: {
      text: "Booking type",
    },
    equipment: {
      text: "Equipment",
    },
    reservedForUsers: {
      text: "Booking for",
    },
    remark: {
      text: "Reason",
    },
    active: {
      text: "Booking status",
    },
  },
};
export const CLUSTER_DETAILS_ATTRIBUTE_META = {
  siteName: {
    key: "siteName",
    value: "Site",
  },
  location: {
    key: "location",
    value: "Location",
  },
  equipmentCategory: {
    key: "equipmentCategory",
    value: "Category",
  },
  equipmentModel: {
    key: "modelName",
    value: "Model",
  },
  variant: {
    key: "variant",
    value: "Variant",
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname",
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person",
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "Software version",
  },
  qualificationStatus: {
    key: "qualificationStatus",
    value: "Qualification status",
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status",
  },
};

export const STANDALONE_ATTRIBUTE_META = {
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname",
  },
  manufacturer: {
    key: "manufacturer",
    value: "Manufacturer",
  },
  equipmentModel: {
    key: "modelName",
    value: "Model",
  },
  variant: {
    key: "variant",
    value: "Variant",
  },
  serialNumber: {
    key: "serialNumber",
    value: "Serial number",
  },
  materialNumber: {
    key: "materialNumber",
    value: "Material number",
  },
  equipmentId: {
    key: "equipmentId",
    value: "Equipment ID",
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person",
  },
  configurationBaseline: {
    key: "configurationBaseline",
    value: "Configuration",
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "Software version",
  },
  belongingToGroup: {
    key: "belongingToGroup",
    value: "Group",
  },
  location: {
    key: "location",
    value: "Location",
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status",
  },
};
export const SHOW_ADVANCE_FILTER_TOOLTIP = "Show search and filters options";
export const RESETALL_TOOLTIP =
  "This will clear all filters and columns and reset to default";
export const CHANGE_EXPIREMENT_TEXT = "Change experiment range";
export const DEFINE_EXPIREMENT_TEXT = "Define experiment range";
export const ENTRY_TYPE = {
  cluster: "CLUSTER",
  standaloneEquipment: "STANDALONE-EQUIPMENT",
  clusterSubequipment: "CLUSTER-SUBEQUIPMENT",
};

export const POSITION_IN_CLUSTER = "position_in_cluster";
export const CLUSTER_DETAILS_HEADING = "Cluster details";
export const EQUIPMENT_DETAILS_HEADING = "Equipment details";
export const ATTRIBUTES_HEADING = "Attributes";
export const EVENT = "EVENT";
export const RESOURCE = "RESOURCE";

export const PopOverTableMeta = {
  fields: {
    variant: {
      text: "Variant",
    },
    equipmentNickName: {
      text: "Nickname",
    },
    qualificationStatus: {
      text: "Qualification status",
    },
    softwareVersion: {
      text: "Software version",
    },
  },
};
export const WORK_WEEK_CONFIG = {
  timeline: {
    type: "week",
    startDay: 1,
    endDay: 5,
  },
};
export const WEEK_CONFIG = {
  timeline: {
    type: TIMELINE_TYPE.WEEK,
    startDay: 1,
    endDay: 0,
    timeCellStep: 720,
    timeLabelStep: 720,
    weekNumbers: false,
    currentTimeIndicator: true,
  },
};
export const DAY_CONFIG = {
  timeline: {
    type: TIMELINE_TYPE.DAY,
    timeCellStep: 60,
    timeLabelStep: 60,
    weekNumbers: false,
    currentTimeIndicator: true,
  },
};

export const BOOKING_WRAPPER_INITALIZE = {
  isEditMode: false,
  isRedirectToAdmin: false,
  isOngoing: false,
  editBookingData: null,
};

export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const BOOKING_REQUEST = "BOOKING_REQUEST";

export const BOOKING_POP_UP_WRAPPER_INITALIZE = {
  title: "",
  content: "",
  open: false,
  onApprove: "",
};
